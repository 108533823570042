import React, { InputHTMLAttributes } from 'react';
import { saveAs } from 'file-saver';
import { HtmlInput } from './HtmlInput';
import { AttachedFile } from '../../AttachedFile/AttachedFile';

// TODO: check components folder, I copy pasted this component there also

interface IInputFileLocal extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value: File[];
  onChange: (newFiles: File[]) => void;
}

export const InputFileLocal = ({ value, onChange, disabled, placeholder, ...props }: IInputFileLocal) => {
  return (
    <>
      <HtmlInput value={value} single={false} onChange={onChange} placeholder={placeholder} {...props} />
      {value &&
        value.map((file, index) => {
          return (
            <AttachedFile
              file={file}
              downloadUploadedFile={(event: any) => {
                event.preventDefault();
                saveAs(file, file.name);
              }}
              removeHandler={async () => {
                if (disabled) {
                  return;
                }

                const newFiles = value.filter((f, i) => i !== index);
                onChange(newFiles);
              }}
              key={index}
            />
          );
        })}
    </>
  );
};
