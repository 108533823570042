import React from 'react';
import { getPatchBody } from '../utils';
import { STextarea } from '../../../components/components/STextarea';
import { useInputWithInnerState } from '../../../hooks/useInputWithInnerState';
import { patchProposalReporting } from '../../../api';
import {
  TApplicationId,
  TChangeGlobalStateCb,
  TMilestoneDeliverable,
  TMilestoneOutline,
  TMilestonePart,
} from '../../../types';

type TProps = {
  value: string;
  changeGlobalState: TChangeGlobalStateCb;
  payload: {
    id: number;
    part: TMilestonePart;
    field: keyof TMilestoneDeliverable | keyof TMilestoneOutline;
  };
  disabled: boolean;
  applicationId: TApplicationId;
};

export const MilestonesAndDeliverableTextarea: React.FC<TProps> = ({
  value,
  changeGlobalState,
  payload,
  disabled,
  applicationId,
}) => {
  const { onBlur, onChangeInner, innerValue, loading, error, onFocus } = useInputWithInnerState(
    value,
    changeGlobalState,
    payload,
    getPatchBody,
    patchProposalReporting,
    'string',
    applicationId,
  );

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <STextarea onFocus={onFocus} value={innerValue} onChange={onChangeInner} onBlur={onBlur} disabled={disabled} />
      {error && <div>{error}</div>}
    </>
  );
};
