import React from 'react';
import { SHeader1, SLabel, SText, SSection } from '../../styles';

export const ProposalTitle = ({ title, state }) => {
  return (
    <SSection>
      <SHeader1>{title}</SHeader1>
      <div>
        <SLabel htmlFor="proposalTitle">Proposal Title</SLabel>
        <SText>{state.proposalTitle}</SText>
      </div>
      <div>
        <SLabel htmlFor="">Institution</SLabel>
        <SText>{state.institution}</SText>
      </div>
    </SSection>
  );
};
