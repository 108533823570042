import React from 'react';
import { SubmittedPersonItem } from '../components/SubmittedPersonItem';
import { SHeader1, SSection } from '../../styles';

export const InstitutionAdministrativeOfficial = ({ title, state }) => {
  return (
    <SSection>
      <SHeader1>{title}</SHeader1>
      <SubmittedPersonItem state={state} />
    </SSection>
  );
};
