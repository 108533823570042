import { Button } from '../../../../components/Button/Button';
import { isAnyErrorsInWholeForm } from '../../utils/validation';
import { STextError } from '../../../styles';
import React from 'react';
import styled from 'styled-components';
import { Preloader } from '../../components/Preloader';
import { useDictContext } from '../../../../contexts/dictContext';

export const MainContent = ({
  setState,
  isSaving,
  ComponentSection,
  submit,
  save,
  getSaveButtonText,
  getSaveButtonStatus,
  getSubmitButtonText,
  getSubmitButtonStatus,
  stepId,
  isSubmitTouched,
  errors,
  stepTitle,
  stateSlice,
  validator,
  errorsSlice,
  isSubmitError,
  isSubmitting,
  serverMessage,
}) => {
  const [dict] = useDictContext();

  if (dict.user.id === undefined) {
    return <Preloader stepTitle={stepTitle} />;
  }

  return (
    <form onSubmit={submit}>
      <ComponentSection
        title={stepTitle}
        setState={(newState) => {
          setState({
            [stepId]: {
              ...stateSlice,
              ...newState,
            },
          });
        }}
        validator={validator}
        state={stateSlice}
        errors={isSubmitTouched ? errorsSlice : {}}
      />

      <SFormControls className="row">
        <div className="col-6">
          <Button
            value={getSaveButtonText()}
            type="button"
            onClick={save}
            mode="full"
            status={getSaveButtonStatus()}
            disabled={isSaving}
          />
        </div>
        <div className="col-6">
          <Button
            value={getSubmitButtonText()}
            status={getSubmitButtonStatus()}
            type="submit"
            mode="full"
            disabled={isSubmitError || isSubmitting}
          />

          {isSubmitTouched && isAnyErrorsInWholeForm(errors) && (
            <STextError>
              Please fix errors before submitting. Either you can save your
              proposal and submit it later.
            </STextError>
          )}

          {serverMessage && <STextError>{serverMessage}</STextError>}
        </div>
      </SFormControls>
    </form>
  );
};

const SFormControls = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;
