import React, { useState } from 'react';
import axios from 'axios';
import { useApplicationContext } from '../../../contexts/applicationContext';
import { SPanelHeader } from '../styles';

export const BoardMemberSelector = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [applicationData, setApplicationData] = useApplicationContext();
  const setMember = async (e) => {
    setIsLoading(true);
    const userId = e.target.value;
    await axios.post(
      `/api/applications/${applicationData.id}/assign-scientific-board`,
      {
        userId,
      },
    );
    setApplicationData({
      ...applicationData,
      scientificMember: userId,
    });
    setIsLoading(false);
  };

  return (
    <>
      <div style={{ margin: '32px 0 10px' }}>
        <SPanelHeader>Assign Reviewer</SPanelHeader>
      </div>
      <select
        value={applicationData.scientificMember || ''}
        onChange={setMember}
        style={{ width: '100%' }}
        disabled={isLoading}
      >
        <option />
        {applicationData.scientificMembers.map((member) => (
          <option key={member.id} value={member.id}>
            {member.name}
          </option>
        ))}
      </select>
    </>
  );
};
