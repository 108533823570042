import range from 'lodash/range';
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCoffee,
} from '@fortawesome/free-solid-svg-icons';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function CustomHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  onHoverDate,
  selectingDate,
}) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const years = range(year - 10, year + 10);

  onHoverDate(selectingDate);

  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <SPrevNextMonthButton
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </SPrevNextMonthButton>

      <select
        value={months[month]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={year}
        onChange={({ target: { value } }) => {
          changeYear(Number(value));
        }}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <SPrevNextMonthButton
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </SPrevNextMonthButton>
    </div>
  );
}

const SPrevNextMonthButton = styled.span`
  border: none;
  cursor: pointer;
  color: #969ca4;
  padding: 0 10px;

  &:hover {
    color: #3c3e42;
  }
`;

export function withCustomHeader(props) {
  return function (internalReactDatepickerProps) {
    return <CustomHeader {...internalReactDatepickerProps} {...props} />;
  };
}
