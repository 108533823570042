import getWeek from 'date-fns/getWeek';
import { useState } from 'react';

export function useWeeksSelection(selectWeeks, locale, selectedDate) {
  const getWeekConsideringLocale = (hoveredDate) => {
    return getWeek(hoveredDate, {
      weekStartsOn: locale === 'gb' ? 1 : 0,
    });
  };

  const [hoveredWeek, setHoveredWeek] = useState(
    getWeekConsideringLocale(selectedDate),
  );

  if (!selectWeeks) {
    return {
      onHoverDate: () => {},
      getDayClassName: () => '',
    };
  }

  const onHoverDate = (date) => {
    const week = getWeekConsideringLocale(date);
    setHoveredWeek(week);
  };

  const getDayClassName = (date) => {
    const dateWeekNumber = getWeekConsideringLocale(date);
    const selectedDateWeek = getWeekConsideringLocale(selectedDate);
    return dateWeekNumber === hoveredWeek || dateWeekNumber === selectedDateWeek
      ? 'entire-week-date'
      : '';
  };

  return {
    onHoverDate,
    getDayClassName,
  };
}
