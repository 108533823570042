export const SCORES_TEXT = {
  1.0: {
    text: 'Least enthusiastic,\nnot to fund',
    score: 1,
  },
  1.5: {
    score: '2/1',
    text: 'Less enthusiastic,\nprobably not fund',
  },
  2.0: {
    score: 2,
    text: 'Enthusiastic,\nwould like to fund if resources available',
  },
  2.5: {
    score: '3/2',
    text: 'Very enthusiastic,\nshould fund',
  },
  3.0: {
    score: 3,
    text: 'Highly enthusiastic,\nmust fund',
  },
};
