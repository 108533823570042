import React from 'react';
import { SDescription } from '../../styles';
import { SHeader1 } from '../../../styles';
import { GrantsRequestCheckbox } from '../../components/GrantsRequestCheckbox';
import styled from 'styled-components';

export const SubjectsResearchSection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        If vertebrate animals and/or human subjects will be used for the
        proposed research project, it is assumed that the applicant has or will
        obtain appropriate approval from the appropriate institutional committee
        prior to the start of the proposed project; specific documentation is
        not required at the time of submission of the proposal.
      </SDescription>
      <div>
        <SCheckboxListItem>
          <GrantsRequestCheckbox
            label="This proposal includes animal research"
            id="form-checkbox-subjects-1"
            fieldName={'animalResearch'}
            {...props}
          />
        </SCheckboxListItem>

        <SCheckboxListItem>
          <GrantsRequestCheckbox
            label="The proposed animal research is currently approved by the appropriate Institutional Animal Use and Care Committees"
            id="form-checkbox-subjects-2"
            fieldName={'animalUseCareCommittees'}
            {...props}
          />
        </SCheckboxListItem>

        <SCheckboxListItem>
          <GrantsRequestCheckbox
            label="This proposal includes human subjects or human tissue/cells"
            id="form-checkbox-subjects-3"
            fieldName={'humanSubjects'}
            {...props}
          />
        </SCheckboxListItem>

        <SCheckboxListItem>
          <GrantsRequestCheckbox
            label="The proposed human or human tissue research is currently approved by the appropriate Institutional Review Board"
            id="form-checkbox-subjects-4"
            fieldName={'approvedByInstitutionalReview'}
            {...props}
          />
        </SCheckboxListItem>

        <SCheckboxListItem>
          <GrantsRequestCheckbox
            label="This proposal uses established cell lines"
            id="form-checkbox-subjects-5"
            fieldName={'usesEstablishedCellLines'}
            {...props}
          />
        </SCheckboxListItem>

        <SCheckboxListItem>
          <GrantsRequestCheckbox
            label="The proposed cell lines have been validated/verified"
            id="form-checkbox-subjects-6"
            fieldName={'verified'}
            {...props}
          />
        </SCheckboxListItem>
      </div>
    </section>
  );
};

const SCheckboxListItem = styled.div`
  margin-bottom: 11px;
`;
