import { useSearchParams } from 'react-router-dom';
import { TProposalReportingVersion } from '../pages/ProposalReporting/types';

export const useReportingVersion = (): {
  version: TProposalReportingVersion;
  setVersion: (version: TProposalReportingVersion) => void;
} => {
  let [searchParams, setSearchParams] = useSearchParams();
  let version = searchParams.get('version') as TProposalReportingVersion;

  const setVersion = (version) => {
    setSearchParams({ version });
  };

  return {
    version,
    setVersion,
  };
};
