import styled from 'styled-components';

export const SInputIcon = styled.span`
  color: hsl(0, 0%, 80%);
  display: flex;
  padding: 3px 1px;
  transition: color 150ms;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;
