export enum STAGES {
  'reportSubmittedByPI' = 'reportSubmittedByPI',
  'adminReviewCompleted' = 'adminReviewCompleted',
  'scientificReviewCompleted' = 'scientificReviewCompleted',
  'financialReviewCompleted' = 'financialReviewCompleted',
}

export const STAGES_TEXTS = {
  [STAGES.reportSubmittedByPI]: 'PI Submitted',
  [STAGES.adminReviewCompleted]: 'Administrative Review',
  [STAGES.scientificReviewCompleted]: 'Scientific Review',
  [STAGES.financialReviewCompleted]: 'Financial Review',
};

export const STAGES_BY_ORDER = [
  STAGES.reportSubmittedByPI,
  STAGES.adminReviewCompleted,
  STAGES.scientificReviewCompleted,
  STAGES.financialReviewCompleted,
];

const GREEN = '#dffbe0';
const YELLOW = '#fff2c6';

export enum CELL_TYPES {
  'PENDING' = 'PENDING',
  'FILLED' = 'FILLED',
  'EMPTY' = 'EMPTY',
}

export const CELL_COLORS = {
  [CELL_TYPES.PENDING]: YELLOW,
  [CELL_TYPES.FILLED]: GREEN,
  [CELL_TYPES.EMPTY]: 'transparent',
};
