import React, { useState } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { STextError } from '../../styles';

export const GrantsRequestInputFile = ({
  state,
  setState,
  errors,
  fieldName,
  validator = () => {},
  ...props
}) => {
  const isFileJustUploaded = state.file instanceof File;
  const isFileExists = !!state.file;
  const [errorText, setErrorText] = useState(null);

  const downloadUploadedFile = (e) => {
    if (isFileJustUploaded) {
      e.preventDefault();
      saveAs(state.file, state.file.name);
    }
  };

  const removeHandler = async () => {
    if (!isFileExists) {
      return;
    }

    if (isFileJustUploaded) {
      setState({ file: null });
    } else {
      try {
        setErrorText(null);
        const { id } = state.file;
        const removeDescriptor = await axios.get(
          `/api/grants-application/delete-file/${id}`,
        );

        if (removeDescriptor.status === 200) {
          setState({ file: null });
        }
        setErrorText(null);
      } catch (e) {
        setErrorText(e.message);
      }
    }
  };

  return (
    <>
      <SLabel isValid={!errors.file}>
        <input
          {...props}
          type="file"
          onChange={(event) => {
            setState({ file: event.target.files[0] });
            // TODO: find out why there is a race condition and when we uncomment this, we check old state...
            // setTimeout(() => {
            //   validator();
            // }, 1000);
          }}
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            cursor: 'pointer',
            padding: 30,
            top: 0,
            left: 0,
            opacity: 0,
          }}
          // onBlur={validator}
        />
        Click to Upload
      </SLabel>
      {state?.file && (
        <div style={{ margin: '8px 8px 0' }}>
          <SFileLink
            onClick={downloadUploadedFile}
            target={'_blank'}
            href={
              // TODO: !!!!! pass proper URL to file here !!!!!
              isFileJustUploaded
                ? ''
                : `/api/grants-application/download-file/${state.file.id}`
            }
          >
            {state.file.name || state.file.fNameOriginal}
          </SFileLink>
          <SIconRemove onClick={removeHandler} />
        </div>
      )}
      {errorText && <STextError>{errorText}</STextError>}
    </>
  );
};

const SLabel = styled.label`
  position: relative;
  width: 100%;
  height: 80px;
  padding-top: 24px;
  background-color: #fafafa;
  border-radius: 2px;
  text-align: center;
  border: ${({ isValid }) =>
    isValid ? '1px dashed #d9d9d9' : '1px dashed #F36242'};
`;

const SIconRemove = styled.i`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  left: 6px;
  top: 3px;
  background: url('/i/remove.svg') 50% 50% no-repeat;
  cursor: pointer;
`;

const SFileLink = styled.a``;
