import { STEPS } from '../../../../constants/steps';
import React from 'react';
import { SNavigation, SNavNext, SNavPrev } from './styles';

export const Nav = ({ currentStep, setCurrentStep }) => {
  return (
    <SNavigation className="row">
      <div className="col-6">
        {currentStep > 0 && (
          <SNavPrev
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            prev
          </SNavPrev>
        )}
      </div>
      <div className="col-6">
        {currentStep < STEPS.length - 1 && (
          <SNavNext
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          >
            next
          </SNavNext>
        )}
      </div>
    </SNavigation>
  );
};
