import { ChangeEvent, useRef, useState } from 'react';
import {
  TApplicationId,
  TChangeGlobalStateCb,
  TGetPatchBodyHandler,
  TPatch,
  TProposalReportingVersion,
} from '../types';
import { useReportingVersion } from '../../../hooks/useReportingVersion';

export const useInputWithInnerState = (
  initialValue: string | number,
  changeGlobalState: TChangeGlobalStateCb,
  payload: any,
  getPatchBody: TGetPatchBodyHandler,
  ajaxFunc: (applicationId: number | string, patch: TPatch<any>, version: TProposalReportingVersion) => Promise<void>,
  type: 'number' | 'string' | undefined = 'number',
  applicationId: TApplicationId,
) => {
  const [innerValue, setInnerValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prev = useRef<string | number>('');
  const { version } = useReportingVersion();

  const onChangeInner = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setInnerValue(type === 'number' ? Number(val) : val);
  };

  // TODO: make universal hook
  const onBlur = () => {
    trySendData();
  };

  const retry = () => {
    trySendData();
  };

  const onFocus = () => {
    prev.current = innerValue;
  };

  const isInnerValueSame = () => {
    return prev.current === innerValue;
  };

  const trySendData = async () => {
    if (isInnerValueSame()) {
      return;
    }

    try {
      setError(null);
      const patchBody = getPatchBody({ value: innerValue, ...payload });
      setLoading(true);
      await ajaxFunc(applicationId, patchBody, version);
      setLoading(false);
      changeGlobalState(patchBody);
    } catch (e) {
      setLoading(false);
      setError(e.message);
      console.error({ e });
    }
  };

  return {
    onBlur,
    onChangeInner,
    innerValue,
    loading,
    error,
    retry,
    onFocus,
  };
};
