import React from 'react';
import { Comment } from './components/Comment';
import { CommentForm } from './components/CommentForm';
import { SHeader1, SSection } from '../../../styles';
import styled from 'styled-components';
import { useApplicationContext } from '../../../../contexts/applicationContext';
import { useDictContext } from '../../../../contexts/dictContext';
import { canWriteComments } from '../../../../utils/accesses';

export const Comments = () => {
  const [{ user, stage }] = useDictContext();
  const [applicationData, setApplicationData] = useApplicationContext();

  const onAddComment = (comment) => {
    setApplicationData({
      ...applicationData,
      comments: [...applicationData.comments, comment],
    });
  };

  return (
    <SSection>
      <SDiscussDelimiter />
      <SHeader1>
        Comments
        <span className="hint">{applicationData.comments.length}</span>
      </SHeader1>
      {applicationData.comments.map((comment) => {
        return <Comment comment={comment} key={comment.id} />;
      })}
      <br />
      {canWriteComments(
        user.userType,
        applicationData.proposal?.assigned,
        stage,
      ) && <CommentForm onAddComment={onAddComment} />}
    </SSection>
  );
};

const SDiscussDelimiter = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 85px;
`;
