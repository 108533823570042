import React from 'react';
import { ReportFile } from '../../components/components/ReportFile/ReportFile';
import { TApplicationId, TChangeGlobalStateCb, TProposalReportingVersion, TReportFile } from '../../types';

type TProps = {
  reportFile: TReportFile;
  changeGlobalState: TChangeGlobalStateCb;
  disabled: boolean;
  applicationId: TApplicationId;
  isSubmitted: boolean;
};
export const FutureResearch = ({
  reportFile,
  changeGlobalState,
  disabled = false,
  applicationId,
  isSubmitted,
}: TProps) => {
  return (
    <>
      <div style={{ marginBottom: 18 }}>
        <ReportFile
          reportFile={reportFile}
          changeGlobalState={changeGlobalState}
          section="futureResearch"
          placeholder="Click to upload PDF file. 2-page limit"
          disabled={disabled}
          applicationId={applicationId}
          isSubmitted={isSubmitted}
          required
        />
      </div>

      <ul className="hint-list">
        <li>2 pages</li>
        <li>How has MWRF funding benefited your research efforts?</li>
        <li>Has it changed the direction of your research or your ability to complete critical studies?</li>
        <li>What future studies are planned based on the results of your MWRF-supported project?</li>
      </ul>
    </>
  );
};
