import React from 'react';
import { SHeader1 } from '../../styles';
import { SubmittedPersonItem } from '../components/SubmittedPersonItem';

export const PrincipalInvestigator = ({ title, state }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SubmittedPersonItem state={state} />
    </section>
  );
};
