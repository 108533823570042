export enum STATUSES {
  ELIGIBLE = 'eligible',
  INELIGIBLE = 'ineligible',
  UNDECIDED = 'undecided',
  REVIEW = 'review',
  ELIGIBLE_FINAL_SCORING = 'eligibleFinalScoring',
  INELIGIBLE_FINAL_SCORING = 'ineligibleFinalScoring',
}

export enum STATUSES_TEXT {
  ELIGIBLE = 'Eligible',
  INELIGIBLE = 'Ineligible',
  UNDECIDED = 'Undecided',
  REVIEW = 'Review',
  ELIGIBLE_FINAL_SCORING = 'Final scoring',
  INELIGIBLE_FINAL_SCORING = 'Other',
}
