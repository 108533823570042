import React from 'react';
import ReactDatepicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/custom-styles.css';
import { CustomInput } from './components/CustomInput';
import { withCustomHeader } from './components/withCustomHeader';
import enGb from 'date-fns/locale/en-GB';
import enUs from 'date-fns/locale/en-US';
import { useWeeksSelection } from './hooks/useWeekSelection';
import { getDateForReactDatepicker } from './utils/getDateForReactDatepicker';
import styled from 'styled-components';
import classnames from 'classnames';

registerLocale('en-GB', enGb);
registerLocale('en-US', enUs);

const DatepickerLocales = {
  gb: enGb,
  us: enUs,
};

export function Datepicker({
  value,
  placeholder,
  focusedPlaceholder = 'month/day/year',
  disabled,
  locale = 'us',
  selectWeeks = false,
  onChange,
  time = '',
  light = false,
  isValid = true,
  ...props
}) {
  const selectedDate = getDateForReactDatepicker(value);

  const { onHoverDate, getDayClassName } = useWeeksSelection(
    selectWeeks,
    locale,
    selectedDate,
  );

  return (
    <SDateTimePicker>
      <SDatepickerWrapper>
        <ReactDatepicker
          calendarClassName={classnames('react-datepicker-dst', {
            'react-datepicker-dst__select-weeks': selectWeeks,
          })}
          locale={DatepickerLocales[locale]}
          selected={selectedDate}
          placeholderText={placeholder}
          renderCustomHeader={withCustomHeader({
            onHoverDate,
          })}
          customInput={
            <CustomInput
              focusedPlaceholder={focusedPlaceholder}
              disabled={disabled}
              onClean={() => {
                onChange(null, null);
              }}
              isValid={isValid}
              light={light}
            />
          }
          disabled={disabled}
          dayClassName={getDayClassName}
          onChange={onChange}
          {...props}
        />
      </SDatepickerWrapper>
    </SDateTimePicker>
  );
}

const SDateTimePicker = styled.div`
  display: flex;
`;

const SDatepickerWrapper = styled.div`
  margin-right: 2px;
`;
