import { PAGES } from '../../../constants/pages';
import { generatePath } from 'react-router-dom';
import { TProposalReportingMode, TProposalReportingVersion } from '../types';
import { stringifyQS } from '../../../utils/qs';

export const getReportingLink = (
  mode: TProposalReportingMode,
  version: TProposalReportingVersion,
  id: number | string,
  additionalParams = {},
) => {
  const baseUrl =
    mode === 'pi' ? PAGES.GRANTS_APPLICATION_REPORTING : generatePath(PAGES.PROPOSAL_REPORTING, { id: String(id) });

  let stringifiedParams = stringifyQS({ version, ...additionalParams });
  stringifiedParams = stringifiedParams ? '?' + stringifiedParams : '';

  return baseUrl + stringifiedParams;
};
