import styled from 'styled-components';

export const SHeaderProfile = styled.h1`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 31px;
  line-height: 35px;
  margin-bottom: 36px;
  padding: 0;
`;

export const SDescription = styled.div`
  padding-bottom: 40px;
`;

export const SPersonInfo = styled.div`
  padding-bottom: 25px;
`;

export const SInputTip = styled.p`
  padding: 0;
  margin: 0 0 10px;
  font-size: 14px;
  opacity: 0.5;
  position: relative;
  margin-top: -5px;
`;

export const SBudgetBlock = styled.div`
  margin-bottom: 30px;
`;

export const SBudgetYearValues = styled.div`
  border-bottom: 1px dashed #ccc;
`;

export const SBudgetYearValue = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
`;

export const SControlRemoveWrapper = styled.div`
  text-align: right;
`;

export const SControlRemove = styled.span`
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s linear;
  font-size: 16px;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;

    top: 50%;
    margin-top: -8px;
    left: 0;

    width: 16px;
    height: 16px;

    background: url(/i/remove.svg) 50% 50% no-repeat;
    background-size: contain;
  }
`;

export const SPrerenderLabel = styled.div`
  width: 100px;
  border-radius: 4px;
  background: #ccc;
  height: 20px;
  margin-bottom: 5px;

  background: linear-gradient(
    -90deg,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    white,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0
  );
  background-size: 500% 500%;

  animation: backgroundAnimation 2s ease-in-out infinite;
`;

export const SPrerenderInput = styled.div`
  background: #ccc;
  border-radius: 4px;
  height: 37px;
  margin-bottom: 15px;

  background: linear-gradient(
    -90deg,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    white,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0
  );
  background-size: 500% 500%;

  animation: backgroundAnimation 2s ease-in-out infinite;
`;

export const SPrerenderTextarea = styled.div`
  background: #ccc;
  border-radius: 4px;
  height: 100px;
  margin-bottom: 15px;

  background: linear-gradient(
    -90deg,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0,
    white,
    #f0f0f0,
    #f0f0f0,
    #f0f0f0
  );
  background-size: 500% 500%;

  animation: backgroundAnimation 2s ease-in-out infinite;
`;

export const SOrderedList = styled.ol`
  padding-left: 20px;
  margin-bottom: 40px;
`;

export const SOrderedListItem = styled.li`
  margin-bottom: 15px;
`;

export const SEmptyBlock = styled.div`
  height: ${({ height }) => (height ? height + 'px' : '0px')};
`;
