import styled from 'styled-components';
import React from 'react';

export const SErrorMessage = styled.div`
  position: absolute;
  top: calc(100% - 3px);
  width: 100%;
  border: 1px solid #edb54f;
  border-radius: 3px;
  z-index: 3;
  background-color: #edb54f;
  color: white;
`;

export const ErrorMessage = ({ children, retry }) => {
  return (
    <SErrorMessage>
      {children}{' '}
      <a
        href={'#'}
        onClick={(e) => {
          e.preventDefault();
          retry();
        }}
        style={{ color: '#fff' }}
      >
        Retry
      </a>{' '}
    </SErrorMessage>
  );
};
