import React, { useState } from 'react';
import { SPanelButton, SPanelButtonIcon, SPanelHeader } from '../styles';
import { SButton } from '../../../components/Button/styles';
import { STATUSES, STATUSES_TEXT } from '../constants/statuses';
import { STextError, STextSuccess } from '../../styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useApplicationContext } from '../../../contexts/applicationContext';

export const StatusSelector = () => {
  const [changeStateError, setChangeStateError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationData, setApplicationData] = useApplicationContext();
  const status = applicationData.proposalState;
  let { id } = useParams();

  const setStatus = async (status) => {
    setIsLoading(true);

    try {
      setSuccessMsg(null);
      setChangeStateError(null);
      const changeStatusResp = await axios.get(
        `/api/applications/${id}/change-state?state=${status}`,
      );
      if (!changeStatusResp.data.state) {
        throw new Error('Bad response from server, sorry');
      }
      setApplicationData({
        ...applicationData,
        proposalState: status,
      });
      setSuccessMsg(`Application status changed to ${status}`);
    } catch (e) {
      setChangeStateError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <SPanelHeader>Status</SPanelHeader>
      </div>
      <SPanelButton>
        <SButton
          mode="full"
          type="button"
          size="m"
          disabled={isLoading}
          onClick={() => {
            setStatus(STATUSES.ELIGIBLE);
          }}
        >
          {status === STATUSES.ELIGIBLE ? <SPanelButtonIcon /> : ''}
          {STATUSES_TEXT.ELIGIBLE}
        </SButton>
      </SPanelButton>
      <SPanelButton>
        <SButton
          mode="full"
          type="button"
          size="m"
          disabled={isLoading}
          onClick={() => {
            setStatus(STATUSES.INELIGIBLE);
          }}
        >
          {status === STATUSES.INELIGIBLE ? <SPanelButtonIcon /> : ''}
          {STATUSES_TEXT.INELIGIBLE}
        </SButton>
      </SPanelButton>
      <SPanelButton>
        <SButton
          mode="full"
          type="button"
          size="m"
          disabled={isLoading}
          onClick={() => {
            setStatus(STATUSES.UNDECIDED);
          }}
        >
          {status === STATUSES.UNDECIDED ? <SPanelButtonIcon /> : ''}
          {STATUSES_TEXT.UNDECIDED}
        </SButton>
      </SPanelButton>

      {changeStateError && (
        <STextError className="mb-0">{changeStateError}</STextError>
      )}
      {successMsg && <STextSuccess className="mb-0">{successMsg}</STextSuccess>}
    </>
  );
};
