import React from 'react';
import { SubmittedPersonItem } from '../components/SubmittedPersonItem';
import { SHeader1 } from '../../styles';
import styled from 'styled-components';

export const KeyPersonnelSubmitted = ({ title, state }) => {
  // state.keyPersonnel = [...state.keyPersonnel, ...state.keyPersonnel];

  return (
    <section>
      <SHeader1>{title}</SHeader1>
      {state.keyPersonnel.map((personnel, index) => {
        return (
          <React.Fragment key={index}>
            <SubmittedPersonItem state={personnel} index={index} />
            {index !== state.keyPersonnel.length - 1 && <SDelimiter />}
          </React.Fragment>
        );
      })}
    </section>
  );
};

const SDelimiter = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 85px;
`;
