import { httpClient } from '../../../../../utils/httpClient';
import { TProposalReportingVersion, TReportFile } from '../../../types';

export const postProposalReportFileRequest = async (
  applicationId: number | string,
  data: FormData,
  version: TProposalReportingVersion,
): Promise<TReportFile[]> => {
  return httpClient
    .post<{ files: TReportFile[] }>(`/api/applications/${applicationId}/proposal-reporting?version=${version}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((resp) => {
      return resp.data.files;
    });
};
