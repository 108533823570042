import styled from 'styled-components';
import { baseInputStyles } from '../Input/v0.3/styles';

export const STextarea = styled.textarea`
  ${baseInputStyles}
  height: 60px;
  width: 100%;
  vertical-align: top;
  padding: 4px 9px 4px 6px;
  border-radius: 8px;
  min-height: 100px;

  &:focus {
    border: 2px solid #647488;
    padding: 3px 8px 3px 5px;
  }
`;

export const STextareaAdvice = styled.span`
  position: absolute;
  bottom: 18px;
  right: 8px;
  color: #bbb;

  font-size: 14px;
  line-height: 16px;

  background-color: #f6f6f6;
  padding: 3px;
  border-radius: 5px;
  z-index: 1;
`;

export const STextareaWrapper = styled.div`
  position: relative;
`;
