import { IReportWithData, IReportWithDataAndFile, IReportWithFile, TPatch } from '../types';

export const isSliceWithData = (t: TPatch<IReportWithData>): t is IReportWithData => {
  return 'data' in t;
};

export const isSliceWithFile = (
  t: TPatch<IReportWithFile | IReportWithDataAndFile>,
): t is IReportWithFile | IReportWithDataAndFile => {
  return 'reportFile' in t;
};
