import { useState, useEffect } from 'react';
import { useSetState } from 'react-use';
import { getAllErrors, isAnyErrorsInWholeForm } from '../utils/validation';
import { useDictContext } from '../../../contexts/dictContext';
import { STEPS } from '../../../constants/steps';
import axios from 'axios';
import { jsonToFormData } from '../utils/jsonToFormData';
import { useDebouncedCallback } from 'use-debounce';

const getInitialState = () => {
  // TODO: temp dev solution not to enter same data again and again
  // const savedStateFromLocalStorage_TEMP = store2.get('form');
  // if (savedStateFromLocalStorage_TEMP) {
  //   return savedStateFromLocalStorage_TEMP;
  // }

  let state = {};
  STEPS.forEach((step) => {
    const copy = state[step.id] ? { ...state[step.id] } : {};
    state[step.id] = { ...step.fields, ...copy };
  });

  return state;
};

const initialState = getInitialState();

export const useGrantRequest = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [state, setState] = useSetState(initialState);
  const [errors, setErrors] = useSetState(initialState);
  const [isSubmitTouched, setIsSubmitTouched] = useState(false);
  const [serverMessage, setServerMessage] = useState(null);
  const [dict] = useDictContext();
  const [proposalSubmitted, setProposalSubmitted] = useState(false);

  const ComponentSection = STEPS[currentStep].component;
  const stepId = STEPS[currentStep].id;
  const stepTitle = STEPS[currentStep].name;
  const stateSlice = state[stepId];
  const errorsSlice = errors[stepId] || {};

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const validatorDebounced = useDebouncedCallback(
    // function
    () => {
      setErrors(getAllErrors(state));
    },
    // delay in ms
    500,
  );
  const validator = validatorDebounced.callback;

  useEffect(() => {
    validator();
  }, [state]);

  useEffect(() => {
    setProposalSubmitted(dict.proposalSubmitted);
    setState(dict.proposal);
  }, [dict]);

  const serverErrorHandler = (error) => {
    const status = error?.response?.status;

    if (status !== 401 && status !== 403) {
      // these are handled in another place
      const message = error?.response?.data?.message;
      setServerMessage(message);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    validator();
    setIsSubmitTouched(true);
    console.log({ errors });
    if (isAnyErrorsInWholeForm(errors)) {
      setIsSubmitError(true);

      setTimeout(() => {
        setIsSubmitError(false);
      }, 3000);

      return;
    }

    try {
      setIsSubmitting(true);
      const formData = jsonToFormData(state);
      await axios.post('api/grants-application/submit', formData);
      setIsSubmitted(true);
      setProposalSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
    } catch (e) {
      serverErrorHandler(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const save = async (e) => {
    e.preventDefault();
    // TODO: temp for dev purposes
    // store2.set('form', state);
    const formData = jsonToFormData(state);

    setIsSaving(true);

    try {
      await axios.post('api/grants-application/save', formData);
      setIsSaved(true);
      setTimeout(() => {
        setIsSaved(false);
      }, 3000);
      setServerMessage(null);
    } catch (e) {
      serverErrorHandler(e);
    } finally {
      setIsSaving(false);
    }
  };

  function getSaveButtonText() {
    if (isSaving) {
      return 'Saving...';
    }

    if (isSaved) {
      return 'Saved';
    }

    return 'Save';
  }

  function getSaveButtonStatus() {
    if (isSaving) {
      return 'process';
    }

    if (isSaved) {
      return 'success';
    }

    return 'default';
  }

  function getSubmitButtonText() {
    if (isSubmitting) {
      return 'Submitting...';
    }

    if (isSubmitted) {
      return 'Submitted';
    }

    if (isSubmitError) {
      return 'Error while submitting';
    }

    return 'Submit';
  }

  function getSubmitButtonStatus() {
    if (isSubmitting) {
      return 'process';
    }

    if (isSubmitted) {
      return 'success';
    }

    if (isSubmitError) {
      return 'error';
    }

    return 'default';
  }

  return {
    proposalSubmitted,
    setState,
    isSaving,
    ComponentSection,
    dict,
    submit,
    save,
    getSaveButtonText,
    getSaveButtonStatus,
    getSubmitButtonText,
    getSubmitButtonStatus,
    setCurrentStep,
    currentStep,
    stepId,
    isSubmitTouched,
    errors,
    stepTitle,
    stateSlice,
    validator,
    errorsSlice,
    isSubmitError,
    isSubmitting,
    serverMessage,
  };
};
