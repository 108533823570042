import { APPROVED_BUDGET_EDITABLE_COLOR, FINANCIAL_REPORT_COLORS } from '../../constants';
import { TCellType } from '../../types';

type TPatchBodyParams = { part: string; field: string; value: any };
type TPatchBody = (params: TPatchBodyParams) => Record<any, any>;

export const getPatchBody: TPatchBody = ({ part, field, value }) => {
  return {
    financialExpenditureReport: {
      data: {
        [part]: {
          [field]: value,
        },
      },
    },
  };
};

export const getFinancialReportCellBgColor = ({ cellType, disabled }: { cellType?: TCellType; disabled?: boolean }) => {
  if (disabled) {
    return FINANCIAL_REPORT_COLORS[cellType] || 'transparent';
  }

  if (cellType === 'editableByAdmin') {
    return APPROVED_BUDGET_EDITABLE_COLOR;
  }

  return '#fdf9ea';
};
