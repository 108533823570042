import React, { useState } from 'react';
import axios from 'axios';
import { SEmptyBlock } from '../GrantsRequest/styles';
import { SHeader1, STextError, STextSuccess } from '../styles';
import { Layout } from '../../components/Layout/Layout';
import { STAGES, STAGES_TEXT } from '../../constants/stages';
import { SPanelButton, SPanelButtonIcon } from '../Application/styles';
import { SButton } from '../../components/Button/styles';
import { useDictContext } from '../../contexts/dictContext';

export const ChangeStage = () => {
  const [changeStateError, setChangeStateError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const [dict, setDict] = useDictContext();
  const stage = dict.stage;

  const setStatus = async (stage) => {
    setIsChangingStatus(true);

    try {
      setSuccessMsg(null);
      setChangeStateError(null);
      const changeStatusResp = await axios.post(`/api/change-stage`, {
        stage,
      });
      setDict({ ...dict, stage });
      if (!changeStatusResp.data.state) {
        throw new Error('Bad response from server, sorry');
      }
      setSuccessMsg(`Application stage changed to ${STAGES_TEXT[stage]}`);
    } catch (e) {
      setChangeStateError(e.message);
    } finally {
      setIsChangingStatus(false);
    }
  };

  const renderButton = (text, value) => {
    return (
      <SPanelButton key={value}>
        <SButton
          mode="full"
          type="button"
          size="m"
          disabled={isChangingStatus}
          onClick={() => {
            setStatus(value);
          }}
        >
          {stage === value ? <SPanelButtonIcon /> : ''}
          {text}
        </SButton>
      </SPanelButton>
    );
  };

  return (
    <Layout>
      <>
        <SEmptyBlock height={80}></SEmptyBlock>

        <div className="col-xl-6 offset-xl-3">
          <SHeader1>Change stage</SHeader1>
          <>
            {Object.keys(STAGES).map((stageName) => {
              return renderButton(
                STAGES_TEXT[STAGES[stageName]],
                STAGES[stageName],
              );
            })}

            {changeStateError && (
              <STextError className="mb-0">{changeStateError}</STextError>
            )}
            {successMsg && (
              <STextSuccess className="mb-0">{successMsg}</STextSuccess>
            )}
          </>
        </div>
      </>
    </Layout>
  );
};
