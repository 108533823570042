import React, { FormEventHandler, SyntheticEvent, useEffect } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { SHeader1, SHeader3 } from '../styles';
import { useSetState } from 'react-use';
import { InputFileLocal } from '../../components/InputFileLocal/InputFileLocal';
import { Textarea } from '../../components/Textarea/Textarea';
import styled from 'styled-components';
import { Button } from '../../components/Button/Button';
import { postManuscriptRequest } from './api';
import { MANUSCRIPT_TITLES } from '../Manuscript/constants';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../constants/pages';
import { TManuscriptDataState } from './types';
import { Input } from '../../components/Input/v0.3/Input';
import axios from 'axios';

// Simple validation, just check that everything is filled
const validate = (data: TManuscriptDataState) =>
  Object.keys(data).every((key: keyof TManuscriptDataState) => {
    const dataItem = data[key];

    return dataItem !== '' && dataItem !== null && dataItem !== undefined;
  });

export const NewManuscript = () => {
  let navigate = useNavigate();

  const [state, setState] = useSetState<TManuscriptDataState>({
    attachment: null,
    awardee: '',
    title: '',
    journalInfo: '',
    manuscriptType: '',
    acknowledgement: '',
    acknowledgementPages: '',
    supportedByProject: '',
    userComments: '',
  });

  const [appState, setAppState] = useSetState({
    isLoading: true,
    isSubmitting: false,
    errors: [],
    reviewPeriodText: '',
  });

  useEffect(() => {
    axios
      .get('/api/user/manuscript-review-period')
      .then((response) => {
        const result = response.data;
        if (result?.reviewPeriod) {
          setAppState({ reviewPeriodText: ` at least ${result?.reviewPeriod} days ` });
        }
      })
      .finally(() => {
        setAppState({ isLoading: false });
      });
  }, []);

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    setAppState({
      isSubmitting: true,
    });

    const postData = {
      title: state.title,
      attachment: state.attachment,
      manuscriptType: state.manuscriptType,
      acknowledgement: state.acknowledgement,
      acknowledgementPages: state.acknowledgementPages,
      journalInfo: state.journalInfo,
      supportedByProject: state.supportedByProject,
      userComments: state.userComments,
    };

    try {
      if (!validate(postData)) {
        setAppState({ errors: ['Please fill in all fields'] });
        return;
      } else {
        setAppState({ errors: [] });
      }
      await postManuscriptRequest(postData);

      navigate(PAGES.MANUSCRIPTS);
    } catch (e) {
      console.error(e);
      setAppState({ errors: ['Error'] });
    } finally {
      setAppState({
        isSubmitting: false,
      });
    }
  };

  if (appState.isLoading) {
    return null;
  }

  return (
    <Layout>
      <SLayoutInner>
        <SContent>
          <SHeader1>MWRF Manuscript Submission</SHeader1>

          <p>
            In&nbsp;accordance with your award Agreement with the Milky Way Research Foundation (MWRF), please provide
            the information below for each proposed MWRF-supported manuscript {appState.reviewPeriodText} prior
            to&nbsp;submission for publication.
          </p>
          <br />
          <SHeader3>Purpose of Review</SHeader3>
          <p>
            The purpose of&nbsp;this review is&nbsp;not to&nbsp;delay publication; rather, it&nbsp;is&nbsp;to&nbsp;allow
            MWRF to&nbsp;be&nbsp;informed of&nbsp;the research being supported to&nbsp;manage our portfolio within the
            vision of&nbsp;the Foundation. Therefore, we&nbsp;request that you submit a&nbsp;draft of&nbsp;the
            manuscript even if&nbsp;it&nbsp;is&nbsp;not fully complete (~75%).
          </p>
          <p>
            If&nbsp;you have already submitted/published a&nbsp;manuscript that acknowledges MWRF support,
            we&nbsp;request that you upload that manuscript/publication at&nbsp;this time.
          </p>
          <br />

          <form onSubmit={onSubmit}>
            <SBlock>
              <SDesc>{MANUSCRIPT_TITLES.title}</SDesc>
              <Textarea
                value={state.title}
                onChange={(val: string) => {
                  setState({ title: val });
                }}
              />
            </SBlock>

            <SBlock>
              <SDesc>
                Please upload the full manuscript, including any figures, tables, and supplementary information,
                as&nbsp;a single document (PDF or&nbsp;MS&nbsp;Word format)
              </SDesc>
              <InputFileLocal
                disabled={false}
                value={state.attachment ? [state.attachment] : []}
                onChange={(attachment) => {
                  setState({ attachment: attachment[0] });
                }}
                placeholder="Click to upload file. Limit 20 MB"
                accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            </SBlock>

            <SBlock>
              <SDesc>{MANUSCRIPT_TITLES.journalInfo}</SDesc>
              <Input
                value={state.journalInfo}
                onChange={(val: string) => {
                  setState({ journalInfo: val });
                }}
              />
            </SBlock>

            <SBlock>
              <SDesc>{MANUSCRIPT_TITLES.manuscriptType}</SDesc>
              <Input
                value={state.manuscriptType}
                onChange={(val: string) => {
                  setState({ manuscriptType: val });
                }}
              />
            </SBlock>
            <SBlock>
              <SDesc>{MANUSCRIPT_TITLES.acknowledgement}</SDesc>
              <SItalic>See below for text of acknowledgement</SItalic>

              <Textarea
                value={state.acknowledgement}
                onChange={(val: string) => {
                  setState({ acknowledgement: val });
                }}
              />
            </SBlock>
            <SBlock>
              <SDesc>{MANUSCRIPT_TITLES.acknowledgementPages}</SDesc>
              <Input
                value={state.acknowledgementPages}
                onChange={(acknowledgementPages: string) => {
                  setState({ acknowledgementPages });
                }}
              />
            </SBlock>

            <SBlock>
              <SDesc>{MANUSCRIPT_TITLES.supportedByProject}</SDesc>
              <Textarea
                value={state.supportedByProject}
                onChange={(supportedByProject: string) => {
                  setState({ supportedByProject });
                }}
              />
            </SBlock>

            <SBlock>
              <SDesc>{MANUSCRIPT_TITLES.userComments}</SDesc>
              <Textarea
                value={state.userComments}
                onChange={(userComments: string) => {
                  setState({ userComments });
                }}
              />
            </SBlock>

            <Button
              type="submit"
              value={appState.isSubmitting ? 'Submitting...' : 'Submit'}
              disabled={appState.isSubmitting}
            />

            <br />
            <br />

            {appState.errors.length > 0 &&
              appState.errors.map((error) => {
                return <SErrorMessage>{error}</SErrorMessage>;
              })}

            <SBlock>
              <br />
              <SDesc>
                Support from the Milky Way Research Foundation may be&nbsp;acknowledged as&nbsp;follows:
                <br />
                <br />
                “This work was supported by&nbsp;an&nbsp;award from the Milky Way Research Foundation.”
              </SDesc>
              <SDesc>
                If required, additional information may be stated:
                <ul>
                  <li>Internal institutional award number</li>
                  <li>Initial(s)/name(s) of&nbsp;the awardee(s)</li>
                </ul>
              </SDesc>
              <SDesc>
                Examples:
                <ul>
                  <li>This work was supported by the Milky Way Research Foundation (P.A. Smith).</li>
                  <li>This work was supported by the Milky Way Research Foundation (PAS).</li>
                  <li>
                    This work was supported by award MWRF-012345 from the Milky Way Research Foundation to P. A. Smith.
                  </li>
                  <li>This work was supported by award MWRF-012345 from the Milky Way Research Foundation (PAS).</li>
                </ul>
              </SDesc>
              <SDesc>MWRF Logo</SDesc>
              <SDesc>
                <ul>
                  <li>
                    <a href="/i/logo.png">For slide presentations</a> (.png format)
                  </li>
                  <li>
                    <a href="/i/logo.ai">For printing</a> (.ai (Adobe Illustrator) format)
                  </li>
                </ul>
              </SDesc>
            </SBlock>
          </form>
        </SContent>
      </SLayoutInner>
    </Layout>
  );
};

// TODO: make this component global
export const SErrorMessage = styled.div`
  width: 100%;
  border: 1px solid #edb54f;
  border-radius: 3px;
  z-index: 3;
  background-color: #edb54f;
  color: white;
  padding: 1px 9px;
`;

const SDesc = styled.div`
  font-size: 14px;
  color: grey;
  margin-bottom: 7px;
`;

const SItalic = styled(SDesc)`
  font-style: italic;
  font-size: 12px;
`;

const SBlock = styled.div`
  margin-bottom: 20px;
`;
const SLayoutInner = styled.div`
  display: flex;
  justify-content: center;
`;

const SContent = styled.div`
  width: 600px;
  margin-bottom: 100px;
`;
