import { TMilestoneDeliverable, TMilestoneOutline, TMilestonePart } from '../../types';

type TProps = {
  id: number;
  part: TMilestonePart;
  field: keyof TMilestoneDeliverable | keyof TMilestoneOutline;
  value: string;
};

export const getPatchBody = ({ id, part, field, value }: TProps) => {
  return {
    milestonesAndDeliverable: {
      data: [
        {
          id,
          [part]: {
            [field]: value,
          },
        },
      ],
    },
  };
};
