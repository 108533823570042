import { css } from 'styled-components';

export const baseInputStyles = css`
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ light }) => (light ? '#ffffff' : '#FBFBFB')};

  font-family: 'Myriad Pro', Arial, sans-serif;
  font-size: 1em;
  margin: 0;
  color: #000;
  border-radius: 4px;
  border: ${({ isValid }) =>
    isValid ? '1px solid #CCCCCC;' : '1px solid #F36242'};
  margin-bottom: 10px;

  &:active,
  &:focus {
    outline: none;
  }
`;
