import React from 'react';
import styled from 'styled-components';
import { MilestonesAndDeliverableSelect } from './components/MilestonesAndDeliverableSelect';
import { MilestonesAndDeliverableTextarea } from './components/MilestonesAndDeliverableTextarea';
import format from 'date-fns/format';
import { convertSQlDateToObj } from '../../../../utils/utils';
import { TApplicationId, TChangeGlobalStateCb, TMilestone } from '../../types';
import { useReportingVersion } from '../../../../hooks/useReportingVersion';

type TProps = {
  data: TMilestone[];
  changeGlobalState: TChangeGlobalStateCb;
  disabled: boolean;
  applicationId: TApplicationId;
  forPrint: boolean;
};

const STATUS_OPTIONS = {
  notStarted: 'Not started',
  startedOrInProgress: 'Started / In progress',
  completed: 'Completed',
};

const TIMELINE_OPTIONS = {
  onTime: 'On time',
  delayed: 'Delayed',
  completed: 'Completed',
  noLongerWillBePursued: 'No longer will be pursued',
};

export const MilestonesDeliverables: React.FC<TProps> = ({
  data,
  changeGlobalState,
  disabled,
  applicationId,
  forPrint,
}) => {
  return (
    <>
      <STable id="milestonesToPrint">
        <thead>
          <tr>
            <STh style={{ width: forPrint ? '45%' : '40%' }}>Proposed</STh>
            <STh style={{ width: forPrint ? '5%' : '10%' }}>Status</STh>
            <STh style={{ width: forPrint ? '5%' : '10%' }}>Timeline</STh>
            <STh style={{ width: forPrint ? '45%' : '40%' }}>Comments</STh>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <React.Fragment key={item.id + '' + index}>
                <MilestoneLine
                  index={index}
                  changeGlobalState={changeGlobalState}
                  item={item}
                  part={'outline'}
                  disabled={disabled}
                  applicationId={applicationId}
                  forPrint={forPrint}
                />
                <MilestoneLine
                  index={index}
                  changeGlobalState={changeGlobalState}
                  item={item}
                  part={'deliverable'}
                  disabled={disabled}
                  applicationId={applicationId}
                  forPrint={forPrint}
                />
              </React.Fragment>
            );
          })}
        </tbody>
      </STable>
    </>
  );
};

// for version === 3 we show 'Y2' (prev year). Because version 3 means year 2.
const getYearPrefix = (version) => {
  if (version === 2) {
    return `Y0.5: `;
  }

  if (version > 2) {
    return `Y${version - 2}: `;
  }

  return '';
};

type TMilestoneLine = {
  item: TMilestone;
  part: 'outline' | 'deliverable';
  changeGlobalState: TChangeGlobalStateCb;
  disabled: boolean;
  forPrint: boolean;
  index: number;
  applicationId: TApplicationId;
};

const MilestoneLine: React.FC<TMilestoneLine> = ({
  item,
  part,
  changeGlobalState,
  disabled,
  index,
  applicationId,
  forPrint,
}) => {
  const { version } = useReportingVersion();
  const yearPrefix = getYearPrefix(version);
  return (
    <STr className={item.id % 2 === 0 ? 'row-even' : ''} key={index}>
      <STd textAlign="left">
        {part === 'outline' && (
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: 'bold' }}>#{index + 1}</div>
            {format(convertSQlDateToObj(item.date), 'PPP')}
          </div>
        )}
        <div style={{ color: 'grey' }}>
          {part === 'outline' && 'Milestone Outline:'}
          {part === 'deliverable' && 'Deliverable:'}
        </div>
        <div>{item[part].description}</div>
      </STd>
      <STdEditable forPrint={forPrint}>
        <MilestonesAndDeliverableSelect
          value={item[part].status}
          payload={{ part, id: item.id, field: 'status' }}
          changeGlobalState={changeGlobalState}
          disabled={disabled}
          applicationId={applicationId}
        >
          <option value=""></option>
          {Object.keys(STATUS_OPTIONS).map((key, index) => (
            <option key={index} value={key}>
              {STATUS_OPTIONS[key]}
            </option>
          ))}
        </MilestonesAndDeliverableSelect>
        {item[part].previousYear && (
          <SPrevYearInfo>
            {yearPrefix} {STATUS_OPTIONS[item[part].previousYear.status]}
          </SPrevYearInfo>
        )}
      </STdEditable>
      <STdEditable forPrint={forPrint}>
        <MilestonesAndDeliverableSelect
          value={item[part].timeline}
          payload={{ part, id: item.id, field: 'timeline' }}
          changeGlobalState={changeGlobalState}
          disabled={disabled}
          applicationId={applicationId}
        >
          <option value=""></option>
          {Object.keys(TIMELINE_OPTIONS).map((key, index) => (
            <option key={index} value={key}>
              {TIMELINE_OPTIONS[key]}
            </option>
          ))}
        </MilestonesAndDeliverableSelect>

        {item[part].previousYear && (
          <SPrevYearInfo>
            {yearPrefix} {TIMELINE_OPTIONS[item[part].previousYear.timeline]}
          </SPrevYearInfo>
        )}
      </STdEditable>
      <STdEditable forPrint={forPrint}>
        {forPrint && <div>{item[part].comment}</div>}
        {!forPrint && (
          <MilestonesAndDeliverableTextarea
            value={item[part].comment}
            payload={{ part, id: item.id, field: 'comment' }}
            changeGlobalState={changeGlobalState}
            disabled={disabled}
            applicationId={applicationId}
          />
        )}
      </STdEditable>
    </STr>
  );
};

const SPrevYearInfo = styled.div`
  padding-top: 5px;
  color: grey;
`;

const STable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #212529;
  vertical-align: top;
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
`;

const STh = styled.th`
  padding: 2rem 1rem;
  border: 1px solid #e6e6e6;
  font-weight: bold;
  text-align: center;
  background-color: #f3f3f3;
`;

const STd = styled.td<{ textAlign?: 'left' | 'right' }>`
  padding: 8px 16px;
  border: 1px solid #e6e6e6;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  background-color: #fff;
  position: relative;
`;

const STdEditable = styled.td<{ textAlign?: 'left' | 'right'; forPrint: boolean }>`
  padding: 8px 16px;
  border: 1px solid #e6e6e6;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  background-color: #fdf9ea;
  position: relative;

  ${({ forPrint }) => {
    if (forPrint) {
      return `vertical-align: initial;
      text-align: left;`;
    }
  }}
`;

const STr = styled.tr`
  &.row-even td {
    background-color: #f3f3f3;
  }

  &.row-even ${STdEditable}, &.row-even textarea {
    background-color: #fdf2cb;
  }
`;
