import React, { useEffect, useCallback, FC } from 'react';
import keyboardKey from 'keyboard-key';
import styled from 'styled-components';

import { PopupContent } from './PopupContent';

export const Popup: FC<{
  title?: string;
  hide?: () => void;
  width?: string;
}> = ({ title, hide, children, width = '100%' }) => {
  const onKeyDown = useCallback(
    (e) => {
      if (keyboardKey.getCode(e) === keyboardKey.Escape) {
        hide();
      }
    },
    [hide],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, false);
    return () => {
      window.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onKeyDown]);

  return (
    <SOverlay>
      <SPopup>
        <PopupContent title={title} hide={hide} width={width}>
          {children}
        </PopupContent>
      </SPopup>
    </SOverlay>
  );
};

export const SOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 1000;
`;

export const SPopup = styled.div`
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
`;
