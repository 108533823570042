import React from 'react';
import { PersonItem } from '../../components/PersonItem';
import { SHeader1 } from '../../../styles';

export const InvestigatorsSection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <PersonItem {...props} withBorder={false} />
    </section>
  );
};
