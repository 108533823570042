import React, { useState } from 'react';
import { SText } from '../../../styles';
import { getTextDate } from '../../../../utils/utils';
import { Datepicker } from '../../../../components/Datepicker/Datepicker';
import { httpClient } from '../../../../utils/httpClient';
import { useParams } from 'react-router-dom';
import { PseudoLink } from '../../../../components/PseudoLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import styled from 'styled-components';
import { dateToString, stringToDate } from '../../../../utils/dates';
import { useAsyncReqExperimental } from '../../../../utils/useAsyncExperimental';

export const MilestoneDateEditor = ({ initialDate, milestoneId }: { initialDate: string; milestoneId: number }) => {
  const applicationId = useParams().id;
  const [edit, setEdit] = useState(false);
  const { loading, error, run } = useAsyncReqExperimental();

  const changeDateReq = (editedDate) => async () => {
    return httpClient.post(`/api/applications/${applicationId}/edit-milestone-date/${milestoneId}`, {
      date: editedDate,
    });
  };

  const onDatepickerChange = async (date) => {
    const newDateStr = dateToString(date);
    await run(changeDateReq(newDateStr), () => {
      setEditedDate(newDateStr);
      setEdit(false);
    });
  };

  const [editedDate, setEditedDate] = useState(initialDate);
  const parsedDateObj = stringToDate(editedDate);

  return (
    <>
      <SWrapper>
        {!edit && <SText>{getTextDate(parsedDateObj)}</SText>}

        {loading && <SText>Loading...</SText>}

        {!loading && edit && (
          <SDatepicker>
            <Datepicker value={parsedDateObj} onChange={onDatepickerChange} placeholder={''} disabled={false} />
          </SDatepicker>
        )}

        {!loading && edit ? (
          <SCancelIcon>
            <PseudoLink onClick={() => setEdit(false)}>Cancel</PseudoLink>
          </SCancelIcon>
        ) : (
          !edit && (
            <SEditIcon>
              <FontAwesomeIcon
                icon={faPen}
                onClick={() => {
                  setEdit(true);
                }}
              />
            </SEditIcon>
          )
        )}
      </SWrapper>
      {error && <SError>Error. Please try again.</SError>}
    </>
  );
};

const SWrapper = styled.div`
  display: flex;
`;

const SError = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 15px;
`;

const SEditIcon = styled.div`
  color: gray;
  margin: 0 10px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
`;

const SCancelIcon = styled(SEditIcon)`
  top: 4px;
  margin: 0 5px;
`;

const SDatepicker = styled.div`
  margin: 0 10px 0 0;
`;
