import React from 'react';
import styled from 'styled-components';
import { CleanIcon, SInputIconClean } from './components/CleanIcon';
import { baseInputStyles } from './styles';

const defaultStyles = {};

export type TOnInputChange = (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;

export interface IInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  isValid?: boolean;
  onChange: TOnInputChange;
  onClean?: () => void;
  onOpen?: () => void;
  customRightIcons?: React.ReactNode;
  style?: React.CSSProperties;
}

export const Input = React.forwardRef(
  (
    {
      name = '',
      onChange,
      onFocus = () => {},
      onBlur = () => {},
      value = '',
      onClean = null,
      onOpen = null,
      autoFocus = false,
      placeholder = '',
      style = defaultStyles,
      customRightIcons = null,
      isValid = true,
      ...restHtmlInputProps
    }: IInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    // This hack is needed because we can not use normal flex layout for input, because it is overlapped by global
    // input's styles
    const getCountOfIcons = () => {
      if (onClean || onOpen) {
        return 1;
      }

      return 0;
    };

    const validValue = value || '';

    const renderRightIcons = () => {
      const shouldRenderCleaner = onClean && validValue !== '';
      if (customRightIcons) {
        return <SInputIcons>{customRightIcons}</SInputIcons>;
      }

      return (
        (shouldRenderCleaner || onOpen) && (
          <SInputIcons>
            {shouldRenderCleaner && (
              <CleanIcon onClean={onClean} value={value} disabled={restHtmlInputProps.disabled} />
            )}
            {onOpen && <SInputIcon onClick={onOpen} className="fa fa-chevron-down" />}
          </SInputIcons>
        )
      );
    };

    return (
      <SInput style={style}>
        <SInputElement
          name={name}
          onFocus={onFocus}
          value={validValue}
          onChange={(e) => {
            onChange(e.target.value, e);
          }}
          onBlur={onBlur}
          type="text"
          autoComplete="off"
          autoFocus={autoFocus}
          placeholder={placeholder}
          countOfIcons={getCountOfIcons()}
          ref={ref}
          isValid={isValid}
          {...restHtmlInputProps}
        />
        {renderRightIcons()}
      </SInput>
    );
  },
);

Input.defaultProps = {
  onClean: null,
  onOpen: null,
};

Input.displayName = 'Input';

// TODO: get rid of Important statement, it is here because of global styles
export const SInputElement = styled.input<{ countOfIcons: number; mode?: 'password' | null; isValid?: boolean }>`
  ${baseInputStyles};
  display: flex;
  flex: 1;
  &[type='text'],
  &[type='number'],
  &[type='password'] {
    padding: 4px 9px 4px 6px;
  }
  padding: 5px 7px 5px;
  // Important here is to override specificity of global input[type=text] css rules
  padding-right: ${({ countOfIcons }) => `${countOfIcons * 15}px !important`};

  &:active,
  &:focus {
    padding: 3px 8px 3px 5px;
    border: 2px solid #647488;
  }

  ${({ mode }) =>
    mode === 'password'
      ? `
      &[type='password'] {
          padding: 0px 9px 0px 6px;
          font-size: 40px;
          line-height: 0px;
          height: 37px;

          &:active,
          &:focus {
            padding: 0 8px 0 5px;
          }
        }
      };
      `
      : ''};
`;

export const SInputIcons = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-left: 4px;
  cursor: pointer;
  padding: 2px 2px;
  position: absolute;
  right: 3px;
  top: 0;
  bottom: 0;
`;

export const SInputIcon = styled.span`
  color: hsl(0, 0%, 80%);
  display: flex;
  padding: 3px;
  transition: color 150ms;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

export const SInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  &:hover ${SInputIconClean} {
    display: block;
  }
`;
