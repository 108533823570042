import { Input } from '../../../components/Input/v0.3/Input';
import React from 'react';

export const ProfileInput = ({
  state,
  setState,
  fieldName,
  errors,
  isValid,
  as = Input,
  validator = () => {},
  ...props
}) => {
  const Component = as;
  return <Component {...props} />;
};
