import styled from 'styled-components';

export const SSidebarBlock = styled.div`
  position: sticky;
  top: 162px;
  padding-bottom: 30px;
  max-height: calc(100vh - 134px);
  overflow: auto;
`;

export const SSteps = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
`;

export const SStepsLink = styled.div`
  margin: 12px 0 30px;
  padding-top: 24px;
  border-top: 1px solid #eee;
  font-weight: 400;
`;

export const SStepWrapper = styled.span<{ hasError?: boolean; selected?: boolean }>`
  display: flex;
  opacity: 0.3;
  transition: opacity 0.1s linear;
  cursor: pointer;
  margin-bottom: 3px;
  line-height: 20px;
  text-decoration: none;
  color: black;

  &:hover {
    color: #000;
    opacity: 0.5;
  }

  ${(props) =>
    props.hasError
      ? `
        opacity: .6;
        color: #F36242;
    `
      : ``}

  ${(props) =>
    props.selected
      ? `
        opacity: 1;
        cursor: default;

        &:hover {
          opacity: 1;
        }
    `
      : ``}
`;

export const SStepNumber = styled.span`
  position: relative;
  display: inline-block;
  width: 25px;
  text-align: right;
`;

export const SStepLink = styled.span`
  display: inline-block;
  padding: 0 0 5px 5px;
  width: 100%;
`;
