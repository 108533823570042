import React from 'react';
import { Select } from '../../../components/Select/Select';

export const GrantsRequestSelect = ({
  state,
  setState,
  fieldName,
  errors,
  validator = () => {},
  options,
  ...props
}) => {
  return (
    <Select
      {...props}
      value={state[fieldName]}
      onChange={(val) => {
        setState({
          [fieldName]: val,
        });
      }}
      isValid={!errors[fieldName]}
      onBlur={validator}
    >
      {options.map((item) => {
        return (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        );
      })}
    </Select>
  );
};
