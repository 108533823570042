import React from 'react';
import styled from 'styled-components';
import { SInputIcon } from './styles';

export function CleanIcon({ onClean, value, disabled }) {
  return value && onClean && !disabled ? (
    <SInputIconClean
      onClick={() => {
        onClean();
      }}
      className="fa fa-close"
    />
  ) : null;
}

export const SInputIconClean = styled(SInputIcon)`
  display: none;
  position: absolute;
  left: -10px;
  top: 3px;

  // TODO: think about this design improvement later
  //&:after {
  //  content: '';
  //  position: absolute;
  //  top: 2px;
  //  bottom: 0px;
  //  left: -2px;
  //  right: -3px;
  //  background: #ff0000;
  //  border-radius: 50%;
  //  z-index: -1;
  //  opacity: 1;
  //}
`;
