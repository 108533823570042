import { STAGES } from '../../../constants/stages';
import { STATUSES, STATUSES_TEXT } from '../../Application/constants/statuses';
import { ROLES } from '../../../constants/roles';
import React from 'react';
import styled from 'styled-components';

export const Tabs = ({
  currentTab,
  setCurrentTab,
  getFilteredData,
  stage,
  user,
}) => {
  const getTabCounter = (tabName) => {
    return getFilteredData({
      tabName,
    }).length;
  };

  const renderTabName = (text, tabId) => {
    return (
      <SAppsFilterItem
        active={currentTab === tabId}
        onClick={() => {
          setCurrentTab(tabId);
        }}
      >
        {text} <SCounter>{getTabCounter(tabId)}</SCounter>
      </SAppsFilterItem>
    );
  };

  let FilterContent;
  if (
    [
      STAGES.FINAL_SCORING_PREVIEW,
      STAGES.FINAL_SCORING,
      STAGES.WINNER_SELECTION,
    ].includes(stage)
  ) {
    FilterContent = (
      <>
        {renderTabName(STATUSES_TEXT.ELIGIBLE, 'all')}
        {renderTabName(
          STATUSES_TEXT.ELIGIBLE_FINAL_SCORING,
          STATUSES.ELIGIBLE_FINAL_SCORING,
        )}
        {renderTabName(
          STATUSES_TEXT.INELIGIBLE_FINAL_SCORING,
          STATUSES.INELIGIBLE_FINAL_SCORING,
        )}
      </>
    );
  } else if (user.userType === ROLES.SCIENTIFIC_BOARD) {
    FilterContent = (
      <>
        {renderTabName(STATUSES_TEXT.ELIGIBLE, STATUSES.ELIGIBLE)}
        {renderTabName(STATUSES_TEXT.REVIEW, STATUSES.REVIEW)}
      </>
    );
  } else {
    FilterContent = (
      <>
        {renderTabName('All', 'all')}
        {renderTabName(STATUSES_TEXT.ELIGIBLE, STATUSES.ELIGIBLE)}
        {renderTabName(STATUSES_TEXT.INELIGIBLE, STATUSES.INELIGIBLE)}
        {renderTabName(STATUSES_TEXT.UNDECIDED, STATUSES.UNDECIDED)}
      </>
    );
  }

  return FilterContent;
};

const SAppsFilterItem = styled.span`
  display: inline-block;
  padding: 14px 20px;
  cursor: pointer;
  position: relative;
  top: 1px;

  ${({ active }) =>
    active &&
    `
       border-bottom: 2px solid #000;
      `};
`;

const SCounter = styled.span`
  padding: 0px 5px;
  border: 1px solid black;
  border-radius: 6px;
`;
