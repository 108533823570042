import React, { FC, FormEvent, useState } from 'react';
import './Login.css';
import axios from 'axios';
import { Button } from '../../../../components/Button/Button';
import { Input } from '../../../../components/Input/v0.3/Input';
import styled from 'styled-components';
import { TOnSuccess, TOnSuccessAsync } from '../../../Login/types';

type TProps = {
  onSuccess: TOnSuccess | TOnSuccessAsync;
  onError?: () => void;
};

export const LoginForm: FC<TProps> = ({ onSuccess, onError }) => {
  const [formState, setFormState] = useState({ email: '', password: '' });
  const [message, setMessage] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoggingIn(true);
    setMessage(null);

    await axios.get('/csrf-token');

    return axios
      .post(`${process.env.REACT_APP_API_PATH}/login`, formState, {
        withCredentials: true,
      })
      .then((resp) => {
        setIsLoggingIn(false);
        onSuccess && onSuccess(resp.data.user, resp.data.return);
      })
      .catch((error) => {
        setIsLoggingIn(false);
        setMessage('Login failed. Please try again');
        onError && onError();
      });
  };

  return (
    <div className="form-login-wrap">
      <form onSubmit={onSubmit} className="form-login">
        <img src="/i/logo.svg" alt="Milky Way Research Foundation Logo" className="form-login-logo" />
        <div className="form-login-row">
          <Input
            placeholder="Email"
            value={formState.email}
            onChange={(email) => {
              setFormState({ ...formState, email });
            }}
            autoFocus
          />
        </div>

        <div className="form-login-row">
          <Input
            type="password"
            placeholder="Password"
            value={formState.password}
            onChange={(value) => {
              setFormState({ ...formState, password: value });
            }}
          />
        </div>

        <Button type="submit" value={isLoggingIn ? 'Logging In...' : 'Log In'} disabled={isLoggingIn} />
        {message && <SErrorMessage>{message}</SErrorMessage>}
      </form>
    </div>
  );
};

const SErrorMessage = styled.div`
  margin-top: 13px;
  text-align: center;
`;
