export const fillEmptyPersonnelReportDataWithZeroesIfNeeded = (data) => {
  if (!data) {
    data = {};
  }

  ['budgetStated', 'budgetSupported', 'subAwardBudgetStated', 'subAwardBudgetSupported'].forEach((field) => {
    data[field] = data[field] || 0;
  });

  return data;
};
