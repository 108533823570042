export const enum ROLES {
  PROPOSAL_ADMIN = 'proposalAdmin',
  OBSERVER = 'observer',
  SUPER_OBSERVER = 'superObserver',
  USER = 'user',
  MANAGEMENT_COMMITTEE_1 = 'managementCommittee1',
  MANAGEMENT_COMMITTEE_2 = 'managementCommittee2',
  SCIENTIFIC_BOARD = 'scientificBoard',
  SITE_ADMIN = 'siteAdmin',
  LEGAL = 'legal',
  FINANCE_APPROVAL = 'financeApproval',
}
