import { TGetPatchBodyHandler } from '../../types';

export const getPatchBody: TGetPatchBodyHandler = ({ field, value }) => {
  return {
    personnelReport: {
      data: {
        [field]: value,
      },
    },
  };
};
