import React, { FC } from 'react';
import styled from 'styled-components';
import { Textarea } from '../../../../components/Textarea/Textarea';
import { InputFileLocal } from '../components/InputFileLocal/InputFileLocal';
import { SButton } from '../../../../components/Button/styles';
import { STextError } from '../../../styles';
import { TOnSendBack, TSendBackState, TSetSandBackState } from './types';

type TProps = {
  onSendBack: TOnSendBack;
  setSandBackState: TSetSandBackState;
  sandBackState: TSendBackState;
};

export const SendBackForm: FC<TProps> = ({ onSendBack, setSandBackState, sandBackState }) => {
  return (
    <div>
      <Textarea
        value={sandBackState.comment}
        onChange={(comment: string) => {
          setSandBackState({ comment });
        }}
        disabled={sandBackState.isSendingBack}
      />

      <InputFileLocal
        disabled={false}
        value={sandBackState.attachment ? [sandBackState.attachment] : []}
        onChange={(f) => {
          console.log({ f });
          setSandBackState({ attachment: f[0] });
        }}
        placeholder="Click to upload files"
      />

      <br />
      <br />
      <SPostButtonWrapper>
        <SButton
          type="submit"
          size="m"
          disabled={sandBackState.isSendingBack}
          style={{ backgroundColor: '#edb54f', borderColor: '#edb54f' }}
          onClick={(e) => {
            e.preventDefault();
            onSendBack();
          }}
        >
          {sandBackState.isSendingBack ? 'Sending back...' : 'Send back'}
        </SButton>
      </SPostButtonWrapper>

      {sandBackState.errorText !== '' && <STextError>{sandBackState.errorText}</STextError>}
    </div>
  );
};

const SPostButtonWrapper = styled.div``;
