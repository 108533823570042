import React from 'react';
import { GrantsRequestInputFile } from '../../components/GrantsRequestInputFile';
import { SDescription, SInputTip } from '../../styles';
import { SHeader1 } from '../../../styles';

export const ProjectSection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        <p>
          <b>Background:</b> Briefly describe the rationale behind the proposed
          study and describe the specific MWRF strategic question(s) to be
          addressed/answered.
        </p>
        <p>
          <b>Specific Aims:</b> Concisely state the hypothesis and enumerate the
          specific aims of the proposed research study.
        </p>
        <p>
          <b>Scientific Plan:</b> Outline a vision for a high-risk/high-reward
          study with an underlying structured, hypothesis-driven research plan.
          Describe the anticipated results and the significance of those
          results, any anticipated challenges, and how those challenges will be
          addressed. Preliminary data that support the conceptual framework of
          the study are not required but may be included, if available.
          <br />
          <u>Note:</u> A detailed technical approach that describes the
          experimental design and methods is not necessary and should not be
          included in this proposal.
        </p>
        <p>
          <b>Outcomes:</b> Describe the impact of the potential results of the
          work on advancing our understanding of aging and rejuvenation.
        </p>
      </SDescription>
      <GrantsRequestInputFile {...props} accept="application/pdf" />
      <SInputTip style={{ marginTop: 10 }}>
        Limit &ndash; 5 pages, Format: PDF
      </SInputTip>
    </section>
  );
};
