import React from 'react';
import styled from 'styled-components';
import { TReportFile } from '../../types';
import { SFileLink } from '../../../Application/styles';

// TODO: check components folder, I copy pasted this component there also
export const AttachedFile = ({
  file,
  downloadUploadedFile,
  removeHandler,
  href = '',
}: {
  file: File | TReportFile;
  downloadUploadedFile?: (event: any) => void;
  removeHandler: () => void;
  href?: string;
}) => {
  const isFileUploaded = (file: TReportFile | File): file is TReportFile => {
    return 'fNameOriginal' in file;
  };

  return (
    <div style={{ margin: '8px 8px 0' }}>
      <SFileLink onClick={downloadUploadedFile || null} target={'_blank'} href={href}>
        {isFileUploaded(file) ? file.fNameOriginal : file.name}
      </SFileLink>
      {removeHandler && <SIconRemove onClick={removeHandler} />}
    </div>
  );
};

const SIconRemove = styled.i`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  left: 6px;
  top: 3px;
  background: url('/i/remove.svg') 50% 50% no-repeat;
  cursor: pointer;
`;
