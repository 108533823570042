import React from 'react';
import styled from 'styled-components';

export const SubmittedInfo = ({ reportSubmittedByPI }: { reportSubmittedByPI: boolean }) => {
  return (
    <>
      {reportSubmittedByPI && (
        <>
          <SInfo>Report submitted by PI.</SInfo>
        </>
      )}
    </>
  );
};

const SInfo = styled.div`
  display: inline-block;
  margin: 0 0 38px -9px;
  padding: 11px 16px;
  border-radius: 8px;
  background-color: #fff2c6;
`;
