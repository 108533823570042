import React, { useEffect, useRef, useState } from 'react';
import { useInputWithInnerState } from '../../../hooks/useInputWithInnerState';
import styled from 'styled-components';
import { formatNumber } from '../../../../../utils/utils';
import { ErrorMessage } from '../../../components/components/ErrorMessage';
import { getFinancialReportCellBgColor } from '../utils';
import {
  TApplicationId,
  TCellType,
  TChangeGlobalStateCb,
  TGetPatchBodyHandler,
  TProposalReportingPatch,
  TProposalReportingVersion,
} from '../../../types';

// TODO: refactor this later

type TProps = {
  value: string | number;
  changeGlobalState: TChangeGlobalStateCb;
  cellType?: TCellType;
  payload: any;
  getPatchBody: TGetPatchBodyHandler;
  patchAjaxFunction: (
    applicationId: number,
    data: TProposalReportingPatch,
    version: TProposalReportingVersion,
  ) => Promise<void>;
  disabled: boolean;
  type?: 'number' | undefined;
  applicationId: TApplicationId;
};

export const FinancialExpenditureInput = ({
  value,
  changeGlobalState,
  payload,
  getPatchBody,
  patchAjaxFunction,
  type = 'number',
  disabled = false,
  cellType,
  applicationId,
}: TProps) => {
  const { onBlur, onChangeInner, innerValue, loading, error, retry, onFocus } = useInputWithInnerState(
    value,
    changeGlobalState,
    payload,
    getPatchBody,
    patchAjaxFunction,
    type,
    applicationId,
  );

  const [isEditMode, setEditMode] = useState(false);
  const additionalProps = type === 'number' ? { min: 0 } : {};

  const onBlurInner = () => {
    setEditMode(false);
    onBlur();
  };

  const inputRef = useRef<HTMLInputElement>();

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    isEditMode && inputRef.current?.focus();
  }, [isEditMode]);

  if (loading) {
    return (
      <SFakeInput
        onClick={() => {
          setEditMode(!isEditMode);
        }}
      >
        Loading...
      </SFakeInput>
    );
  }

  if (isEditMode) {
    return (
      <>
        <SInput
          value={innerValue ?? ''}
          onChange={onChangeInner}
          onBlur={onBlurInner}
          ref={inputRef}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          type={type}
          {...additionalProps}
        />
        {error && <ErrorMessage retry={retry}>{error}</ErrorMessage>}
      </>
    );
  }

  return (
    <SFakeInput
      onClick={() => {
        !disabled && setEditMode(!isEditMode);
      }}
      disabled={disabled}
      cellType={cellType}
    >
      {innerValue === null || innerValue === undefined ? 'PI Entry' : formatNumber(innerValue)}
      {error && <ErrorMessage retry={retry}>{error}</ErrorMessage>}
    </SFakeInput>
  );
};

const SFakeInput = styled.div<{ cellType?: TCellType; disabled?: boolean }>`
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${getFinancialReportCellBgColor};

  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SInput = styled.input`
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff2c6;

  &:focus {
    border: none;
    outline: none;
    outline-width: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
