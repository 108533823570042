import React from 'react';
import { Textarea } from '../../../../../components/Textarea/Textarea';
import { GrantsRequestInput } from '../../../components/GrantsRequestInput';
import { SHeader1, SLabel } from '../../../../styles';

export const ProposalTitleSection = ({
  title,
  setState,
  state,
  validator,
  errors,
}) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <div>
        <SLabel htmlFor="proposalTitle">Proposal Title</SLabel>
        <GrantsRequestInput
          as={Textarea}
          state={state}
          fieldName={'proposalTitle'}
          errors={errors}
          setState={setState}
          validator={validator}
          max={150}
          advice="150 characters"
          autoFocus
        />
      </div>
      <div>
        <SLabel htmlFor="">Institution</SLabel>
        <GrantsRequestInput
          state={state}
          fieldName={'institution'}
          errors={errors}
          setState={setState}
          validator={validator}
          advice="50 characters"
          max={50}
        />
      </div>
    </section>
  );
};
