import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { getTextDate } from '../../../../../utils/utils';
import nl2br from 'react-nl2br';

export const Comment = ({ comment }) => {
  return (
    <SComment>
      <SIcon>
        <FontAwesomeIcon icon={faComment} />
      </SIcon>
      <SCommentBody>
        <SName>{comment.author.name}</SName>
        <SText>{nl2br(comment.text)}</SText>
        <SDate>{getTextDate(new Date(comment.created_at))}</SDate>
      </SCommentBody>
      <br />
    </SComment>
  );
};

const SComment = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const SCommentBody = styled.div`
  margin-bottom: 15px;
`;

const SText = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
`;

const SName = styled.div`
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const SDate = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  opacity: 0.5;
`;

const SIcon = styled.div`
  margin-right: 15px;
  opacity: 0.2;
`;
