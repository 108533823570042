import React from 'react';
import styled from 'styled-components';
import { CommentForm } from './components/CommentForm';
import { Comment } from './components/Comment';
import { TRemoveCommentCb, TReportComment, TReportId } from '../../../types';
import { SHeader1 } from '../../../../styles';

const canWriteComments = () => true;

export const ProposalReportComments = ({
  comments,
  addComment,
  reportId,
  onRemove,
}: {
  comments: TReportComment[];
  addComment: (newComment: TReportComment) => void;
  reportId: TReportId;
  onRemove: TRemoveCommentCb;
}) => {
  return (
    <div>
      <SDiscussDelimiter />
      <SHeader1>
        Comments
        {comments.length > 0 && <span className="hint">{comments.length}</span>}
      </SHeader1>
      {comments.map((comment) => {
        return <Comment comment={comment} reportId={reportId} key={comment.id} onRemove={onRemove} />;
      })}
      <br />
      {canWriteComments() && <CommentForm onAddComment={addComment} applicationId={reportId} />}
    </div>
  );
};

const SDiscussDelimiter = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 85px;
`;
