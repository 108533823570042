import React, { InputHTMLAttributes, useState } from 'react';
import { STextError } from '../../../../styles';
import { TReportFile } from '../../../types';
import { HtmlInput } from './HtmlInput';
import { AttachedFile } from '../../AttachedFile/AttachedFile';

interface IInputFileAjax extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  files: TReportFile[];
  onChange: (newFiles: TReportFile[] | FileList) => void;
  removeHandler: (file: TReportFile) => Promise<any>;
  getDownloadUrl: (fileId: number) => string;
  canChangeFile?: boolean;
  required?: boolean;
}

export const InputFileAjax = ({
  files,
  onChange,
  removeHandler,
  getDownloadUrl,
  disabled,
  placeholder,
  canChangeFile = true,
  ...props
}: IInputFileAjax) => {
  const [errorText, setErrorText] = useState(null);

  return (
    <>
      {canChangeFile && <HtmlInput onChange={onChange} disabled={disabled} placeholder={placeholder} {...props} />}

      {files &&
        files.map((file, index) => {
          return (
            <div style={{ margin: '8px 8px 0' }} key={index}>
              <AttachedFile
                file={file}
                removeHandler={
                  canChangeFile
                    ? () => {
                        removeHandler(file);
                      }
                    : undefined
                }
                href={getDownloadUrl(file.id)}
              />
            </div>
          );
        })}

      {errorText && <STextError>{errorText}</STextError>}
    </>
  );
};
