export enum PAGES {
  INDEX = '/',
  LOGIN = '/login',
  PROFILE = '/profile',
  GRANTS_APPLICATION = '/grants-application',
  GRANTS_APPLICATION_REPORTING = '/grants-application/reporting',
  CHANGE_STAGE = '/change-stage',
  APPLICATION = '/applications/:id',
  APPLICATIONS = '/applications',
  RECORDS = '/records',
  WINNERS = '/winners',
  PROPOSAL_REPORTING = '/applications/:id/reporting',
  SUMMARY = '/summary',
  MANUSCRIPT = '/manuscripts/:id',
  MANUSCRIPTS = '/manuscripts',
  MANUSCRIPTS_ADD = '/manuscripts/add',
}
