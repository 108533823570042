import { IMilestonesReport, TPatch } from '../types';
import { isSliceWithData } from './typeGuards';

export const mergeMilestones = (draft: IMilestonesReport, slice: TPatch<IMilestonesReport>) => {
  if (isSliceWithData(slice)) {
    return;
  }

  const sliceData = slice.data[0];
  const sourceData = draft.data;

  const indexToBeChanged = sourceData.findIndex((item) => {
    return sliceData.id === item.id;
  });

  const link = sourceData[indexToBeChanged];

  if (sliceData.outline) {
    link.outline = { ...link.outline, ...sliceData.outline };
  }

  if (sliceData.deliverable) {
    link.deliverable = { ...link.deliverable, ...sliceData.deliverable };
  }
};
