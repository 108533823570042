const MENU_LINKS_VERSION_1 = {
  'scientific-report': 'Scientific Progress Report',
  'milestones-report': 'Milestones and Deliverables',
  'financial-report': 'Financial Expenditure Report',
  'personnel-report': 'Personnel Report',
  'invoice-report': 'Invoice',
};

const MENU_LINKS_VERSION_2 = {
  'scientific-report': 'Scientific Progress Report',
  'publications-and-abstracts': 'Publications and Abstracts',
  'milestones-report': 'Milestones and Deliverables',
  'financial-report': 'Financial Expenditure Report',
  'accounting-report': 'Accounting Report',
  'personnel-report': 'Personnel Report',
  'invoice-report': 'Invoice',
};

const MENU_LINKS_VERSION_4 = {
  'scientific-report': 'Scientific Progress Report',
  'publications-and-abstracts': 'Publications and Abstracts',
  'future-research': 'Future Research',
  'milestones-report': 'Milestones and Deliverables',
  'financial-report': 'Financial Expenditure Report',
  'accounting-report': 'Accounting Report',
  'personnel-report': 'Personnel Report',
};

export const MENU_LINKS = {
  '1': MENU_LINKS_VERSION_1,
  '2': MENU_LINKS_VERSION_2,
  '3': MENU_LINKS_VERSION_2, // same as in 2 version
  '4': MENU_LINKS_VERSION_4,
};

export type TMenuLinksValues =
  typeof MENU_LINKS[keyof typeof MENU_LINKS][keyof typeof MENU_LINKS[keyof typeof MENU_LINKS]];
