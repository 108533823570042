import React from 'react';
import { SSidebarBlock } from '../../GrantsRequest/features/Menu/styles';
import { SPanelWrapper } from '../styles';
import { Menu } from './Menu';
import { useDictContext } from '../../../contexts/dictContext';
import styled from 'styled-components';
import { StatusSelector } from './StatusSelector';
import { ScoreSelector } from './ScoreSelector';
import { BoardMemberSelector } from './BoardMemberSelector';
import { useApplicationContext } from '../../../contexts/applicationContext';
import { canChangeBoardMember, canChangeScore, canChangeStatus } from '../../../utils/accesses';

export const Sidebar = ({ mode }: { mode: 'pi' | undefined }) => {
  return (
    <>
      <SSidebarBlock>
        <Menu />
        {mode !== 'pi' && <SidebarBottomContent />}
      </SSidebarBlock>
    </>
  );
};

const SidebarBottomContent = () => {
  const [dict] = useDictContext();
  const [applicationData] = useApplicationContext();

  return (
    <SPanelWrapper>
      {canChangeStatus(dict.user.userType, dict.stage) && <StatusSelector />}

      {canChangeScore(
        dict.user.userType,
        dict.stage,
        applicationData.proposalState,
        applicationData.proposal?.assigned,
        applicationData.selectedForFinalScoring,
      ) && <ScoreSelector />}

      {canChangeBoardMember(dict.user.userType, dict.stage, applicationData.proposalState) &&
        applicationData.scientificMembers && <BoardMemberSelector />}

      <SDiscussLinkWrap>
        <SDiscussLink
          onClick={() => {
            document.getElementById('comments').scrollIntoView(true); // Boolean parameter
          }}
        >
          Comments
        </SDiscussLink>
        <span className="hint">{applicationData.comments.length}</span>
      </SDiscussLinkWrap>
    </SPanelWrapper>
  );
};

const SDiscussLinkWrap = styled.div`
  margin-top: 15px;
  text-align: center;
`;

const SDiscussLink = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
`;
