import React, { useState } from 'react';
import axios from 'axios';
import { Button } from '../../components/Button/Button';
import { SEmptyBlock } from '../GrantsRequest/styles';
import { SHeader1, SLabel, SBlock, SHeader2, STextError } from '../styles';
import { ProfileInput } from './components/ProfileInput';
import { Layout } from '../../components/Layout/Layout';

export const ProfilePage = () => {
  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    setIsSaving(true);
    axios
      .post('/api/profile', {
        'old-password': form.querySelector('#old-pass').value,
        password: form.querySelector('#pass').value,
        'confirm-password': form.querySelector('#confirm-pass').value,
      })
      .then(() => {
        setErrorMessage('');
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 3000);
      })
      .catch((error) => {
        if (error.response.data) {
          const data = error.response.data;
          if (data['confirm-password']) {
            setErrorMessage(data['confirm-password'][0]);
          }
          if (data['old-password']) {
            setErrorMessage(data['old-password'][0]);
          }
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let [oldPass, setOldPass] = useState('');
  let [newPass, setNewPass] = useState('');
  let [confirmPass, setConfirmPass] = useState('');

  function getSubmitButtonText() {
    if (isSaving) {
      return 'Saving...';
    }

    if (isSaved) {
      return 'Saved';
    }

    if (errorMessage) {
      return 'Error while saving';
    }

    return 'Save';
  }

  function getSubmitButtonStatus() {
    if (isSaving) {
      return 'process';
    }

    if (isSaved) {
      return 'success';
    }

    if (errorMessage) {
      return 'error';
    }

    return 'default';
  }

  return (
    <Layout>
      <>
        <SEmptyBlock height={80}></SEmptyBlock>

        <div className="col-xl-6 offset-xl-3">
          <SHeader1>Profile</SHeader1>

          <form
            onSubmit={(e) => {
              onSubmit(e);
            }}
          >
            <SBlock withBorder={true}>
              <SHeader2>Change password</SHeader2>
              <div>
                <SLabel htmlFor="">Current Password</SLabel>
                <ProfileInput
                  id="old-pass"
                  name="old-password"
                  autoComplete="false"
                  value={oldPass}
                  onChange={(val) => {
                    setOldPass(val);
                  }}
                />
              </div>

              <div>
                <SLabel htmlFor="">New Password</SLabel>
                <ProfileInput
                  type="password"
                  id="pass"
                  autoComplete="false"
                  name="password"
                  value={newPass}
                  onChange={(val) => {
                    setNewPass(val);
                  }}
                  mode="password"
                />
              </div>

              <div>
                <SLabel htmlFor="">Confirm New Password</SLabel>
                <ProfileInput
                  type="password"
                  autoComplete="false"
                  id="confirm-pass"
                  name="confirm-password"
                  value={confirmPass}
                  onChange={(val) => {
                    setConfirmPass(val);
                  }}
                  mode="password"
                />
              </div>
            </SBlock>

            <div className="col-6">
              <Button
                type="submit"
                value={getSubmitButtonText()}
                mode="full"
                status={getSubmitButtonStatus()}
              />

              {errorMessage && <STextError>{errorMessage}</STextError>}
            </div>
          </form>
        </div>

        <SEmptyBlock height={150}></SEmptyBlock>
      </>
    </Layout>
  );
};
