import format from 'date-fns/format';
import { formatDistance, parseISO } from 'date-fns';
import { CELL_COLORS, CELL_TYPES, STAGES, STAGES_BY_ORDER } from './constants';
import { TSummaryItemReport } from './types';

const getIsCellFilled = (report: TSummaryItemReport, stage: STAGES) => report[stage] !== 'N';

export const getCellType = (report: TSummaryItemReport, stage: STAGES) => {
  if (!report || !report?.[stage]) {
    return CELL_TYPES.EMPTY;
  }

  if (getIsCellFilled(report, stage)) {
    return CELL_TYPES.FILLED;
  }

  if (getIsPrevCellFilled(report, stage)) {
    return CELL_TYPES.PENDING;
  }

  return CELL_TYPES.EMPTY;
};

const getPrevCellContent = (report: TSummaryItemReport, stage: STAGES) => {
  const currentIndex = STAGES_BY_ORDER.findIndex((stageName) => stage === stageName);
  if (currentIndex === 0) {
    return null;
  }
  const prevStage = STAGES_BY_ORDER[currentIndex - 1];
  return report[prevStage];
};

const getIsPrevCellFilled = (report: TSummaryItemReport, stage: STAGES) => {
  return getPrevCellContent(report, stage) !== 'N';
};

const getDuration = (date1: Date, date2: Date) => formatDistance(date2, date1, { includeSeconds: true });

export const getFormattedCellDate = (report: TSummaryItemReport, stage: STAGES) => {
  const cellType = getCellType(report, stage);

  if (cellType === CELL_TYPES.EMPTY) {
    return null;
  }

  if (cellType === CELL_TYPES.PENDING) {
    const dateStr = getPrevCellContent(report, stage);

    if (dateStr === null) {
      return null;
    }

    return getDuration(parseISO(dateStr), new Date());
  }

  return format(parseISO(report?.[stage]), 'MMM dd, yyyy');
};

export const getCellColor = (report: TSummaryItemReport, stage: STAGES) => {
  const cellType = getCellType(report, stage);

  return CELL_COLORS[cellType];
};
