import { useState } from 'react';

export const useAsyncReqExperimental = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const run = async (tryBlock, successBlock?, catchBlock?, otherwiseBlock?) => {
    try {
      setError(null);
      setLoading(true);
      await tryBlock();
      successBlock && successBlock();
    } catch (e) {
      setError(e.message);
      console.error({ e });

      catchBlock && catchBlock();
    } finally {
      setLoading(false);
      otherwiseBlock && otherwiseBlock();
    }
  };

  return {
    loading,
    error,
    run,
  };
};
