import React from 'react';
import { GrantsRequestInput } from '../../components/GrantsRequestInput';
import { GrantsRequestInputFile } from '../../components/GrantsRequestInputFile';
import { SDescription, SInputTip, SBudgetBlock } from '../../styles';
import { SBlock, SHeader1, SHeader2, SHeader3, SLabel } from '../../../styles';

export const BudgetSection = ({ title, ...props }) => {
  const maxDirectCost = 1000000;
  const y1Total =
    Number(props.state.y1DirectCost) + Number(props.state.y1IndirectCost);
  const y2Total =
    Number(props.state.y2DirectCost) + Number(props.state.y2IndirectCost);
  const y3Total =
    Number(props.state.y3DirectCost) + Number(props.state.y3IndirectCost);
  const sumTotal = y1Total + y2Total + y3Total;

  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        Applicants should provide an annual budget for the proposed project
        using the template provided. A maximum of $1,000,000 in direct costs may
        be requested for each year for a maximum of $3,000,000 in direct costs
        over three years. Indirect costs are limited to 15% of the total award
        amount. As requested in the budget template, applicants should also
        provide the budget amount for each enumerated specific aim for each year
        of the project.
      </SDescription>

      <SBlock withBorder={true}>
        <SHeader2>Funding Request Summary</SHeader2>
        <SBudgetBlock>
          <SHeader3>Year 1</SHeader3>
          <div>
            <SLabel>Direct Cost, $</SLabel>
            <GrantsRequestInput
              fieldName={'y1DirectCost'}
              autoFocus
              isValid={props.state.y1DirectCost <= maxDirectCost}
              type="number"
              min="0"
              max={maxDirectCost}
              {...props}
            />
            <SInputTip>Maximum $1,000,000</SInputTip>
          </div>
          <div>
            <SLabel>Indirect Cost, $</SLabel>
            <GrantsRequestInput
              isValid={
                props.state.y1IndirectCost <= props.state.y1DirectCost * 0.15
              }
              fieldName={'y1IndirectCost'}
              type="number"
              {...props}
            />
            <SInputTip>Maximum 15% of Direct Cost</SInputTip>
          </div>
          <div>
            Total Year 1: {window.isNaN(y1Total) ? 'n/a' : `$${y1Total}`}
          </div>
        </SBudgetBlock>

        <SBudgetBlock>
          <SHeader3>Year 2</SHeader3>
          <div>
            <SLabel>Direct Cost, $</SLabel>
            <GrantsRequestInput
              isValid={props.state.y2DirectCost <= maxDirectCost}
              fieldName={'y2DirectCost'}
              type="number"
              min="0"
              max={maxDirectCost}
              {...props}
            />
            <SInputTip>Maximum $1,000,000</SInputTip>
          </div>
          <div>
            <SLabel>Indirect Cost, $</SLabel>
            <GrantsRequestInput
              fieldName={'y2IndirectCost'}
              isValid={
                props.state.y2IndirectCost <= props.state.y2DirectCost * 0.15
              }
              type="number"
              {...props}
            />
            <SInputTip>Maximum 15% of Direct Cost</SInputTip>
          </div>
          <div>
            Total Year 2: {window.isNaN(y2Total) ? 'n/a' : `$${y2Total}`}
          </div>
        </SBudgetBlock>

        <SBudgetBlock>
          <SHeader3>Year 3</SHeader3>
          <div>
            <SLabel>Direct Cost, $</SLabel>
            <GrantsRequestInput
              fieldName={'y3DirectCost'}
              isValid={props.state.y3DirectCost <= maxDirectCost}
              type="number"
              min="0"
              max={maxDirectCost}
              {...props}
            />
            <SInputTip>Maximum $1,000,000</SInputTip>
          </div>
          <div>
            <SLabel>Indirect Cost, $</SLabel>
            <GrantsRequestInput
              fieldName={'y3IndirectCost'}
              isValid={
                props.state.y3IndirectCost <= props.state.y3DirectCost * 0.15
              }
              type="number"
              {...props}
            />
            <SInputTip>Maximum 15% of Direct Cost</SInputTip>
          </div>
          <div>
            Total Year 3: {window.isNaN(y3Total) ? 'n/a' : `$${y3Total}`}
          </div>
        </SBudgetBlock>
        <div>
          Total 3-Year Budget: {window.isNaN(sumTotal) ? 'n/a' : `$${sumTotal}`}
        </div>
        <br />
        <a href="/storage/mwrf_budget_template.xlsx" target="_blank">
          Download template – Excel format
        </a>
        <br />
        <br />
        <div>
          <GrantsRequestInputFile {...props} accept=".xls,.xlsx" />
          <SInputTip style={{ marginTop: 10 }}>Format: Excel</SInputTip>
        </div>
      </SBlock>
    </section>
  );
};
