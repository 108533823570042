import React from 'react';
import { Textarea } from '../../../../components/Textarea/Textarea';
import { GrantsRequestInput } from '../../components/GrantsRequestInput';
import { SDescription } from '../../styles';
import { SHeader1 } from '../../../styles';

export const AbstractSection = ({
  title,
  setState,
  state,
  errors,
  validator,
}) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        Describe the ideas and scientific rationale behind the proposed research
        as well as a brief study design. The specific aims of the application
        must be clear. State the significance of the proposed project, and
        describe how, if successful, the work will have a major impact on the
        field. Summarize how the proposed research creates new paradigms or
        challenges existing ones.
      </SDescription>
      <GrantsRequestInput
        as={Textarea}
        state={state}
        fieldName={'abstract'}
        errors={errors}
        setState={setState}
        validator={validator}
        advice="2,000 characters (approximately 300 words)"
        max={2000}
        autoFocus
      />
    </section>
  );
};
