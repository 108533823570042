import React, { useState } from 'react';
import jsonToFormData from 'json-form-data';
import { postProposalReportFileRequest } from './api';
import { getFilePatchBody } from '../../../utils/getFilePatchBody';
import { httpClient } from '../../../../../utils/httpClient';
import { TApplicationId, TChangeGlobalStateCb, TPatchSection, TReportFile } from '../../../types';
import { InputFileAjax } from '../InputFileAjax/InputFileAjax';
import { useReportingVersion } from '../../../../../hooks/useReportingVersion';

type TReportFileProps = {
  reportFile: TReportFile;
  changeGlobalState: TChangeGlobalStateCb;
  section: TPatchSection;
  placeholder: string;
  disabled: boolean;
  applicationId: TApplicationId;
  isSubmitted: boolean;
  required?: boolean;
};

export const ReportFile = ({
  reportFile,
  changeGlobalState,
  section,
  placeholder,
  disabled,
  applicationId,
  isSubmitted,
  required = false,
}: TReportFileProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { version } = useReportingVersion();

  const onChange = async (reportFilesNew: FileList) => {
    const singleFile = reportFilesNew[0];
    setLoading(true);

    try {
      const patchBodyJSON = getFilePatchBody({ reportFile: singleFile, section });
      const patchBodyFormData = jsonToFormData(patchBodyJSON);

      setError(null);

      const uploadedReportFile = await postProposalReportFileRequest(applicationId, patchBodyFormData, version);
      changeGlobalState(getFilePatchBody({ reportFile: uploadedReportFile[0], section }));
    } catch (e) {
      setError(e.response?.data?.message || e.message || 'Error');
      console.error({ e });
    } finally {
      setLoading(false);
    }
  };

  const onRemove = async (file: TReportFile) => {
    try {
      setLoading(true);
      setError(null);
      const removeDescriptor = await httpClient.delete(
        `/api/applications/${applicationId}/proposal-reporting/delete-file/${file.id}?version=${version}`,
      );

      const patch = getFilePatchBody({ reportFile: '', section });
      changeGlobalState(patch);

      return removeDescriptor;
    } catch (e) {
      console.error({ e });
      setError(e.message);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      {error && <div>{error}</div>}
      <InputFileAjax
        required={required}
        canChangeFile={!isSubmitted}
        disabled={disabled}
        files={reportFile ? [reportFile] : []}
        onChange={onChange}
        placeholder={placeholder}
        accept="application/pdf"
        removeHandler={onRemove}
        getDownloadUrl={(fileId) => {
          return `/api/applications/${applicationId}/proposal-reporting/download-file/${fileId}`;
        }}
      />
    </>
  );
};
