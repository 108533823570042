import axios from 'axios';
import { TManuscript, TServerComment, TYesOrNo } from '../../types';
import { httpClient } from '../../utils/httpClient';
import { TFormDataComment } from '../../components/Comments/types';

export const getManuscriptRequest = async (id: string | number): Promise<TManuscript> => {
  const settingsResp = await axios.get<TManuscript>(`/api/manuscript/${id}`);
  return settingsResp?.data;
};

export const removeManuscriptRequest = async (id: string | number): Promise<unknown> => {
  const settingsResp = await axios.delete<TManuscript>(`/api/manuscript/${id}/delete`);
  return settingsResp?.data;
};

export const sendAdministrativeReviewReq = async (id: number, reviewStatus: TYesOrNo): Promise<TManuscript> => {
  const resp = await httpClient.post<TManuscript>(`/api/manuscript/${id}/set-administrative-review`, {
    adminReview: reviewStatus,
  });
  return resp.data;
};

export const sendScientificReviewReq = async (id: number, reviewStatus: TYesOrNo): Promise<TManuscript> => {
  const resp = await httpClient.post<TManuscript>(`/api/manuscript/${id}/set-scientific-review`, {
    scientificReview: reviewStatus,
  });
  return resp.data;
};

export const addCommentReq = async (
  newComment: TFormDataComment,
  manuscriptId: string | number,
): Promise<TServerComment> => {
  const resp = await httpClient.post<{ comment: TServerComment }>(
    `/api/manuscript/${manuscriptId}/add-comment`,
    newComment,
    {
      withCredentials: true,
    },
  );

  return resp.data.comment;
};

export const removeCommentReq = async (manuscriptId: number | string, commentId: number | string): Promise<unknown> => {
  return await httpClient.delete(`/api/manuscript/${manuscriptId}/delete-comment/${commentId}`);
};
