import * as React from 'react';
import { HTMLAttributes } from 'react';

import styled, { css } from 'styled-components';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  selected?: boolean;
  disabled?: boolean;
  iconLeft?: React.ReactElement;
  iconRight?: React.ReactElement;
}
export function PseudoLink({
  selected = false,
  disabled = false,
  iconLeft = null,
  iconRight = null,
  children,
  ...restProps
}: Props) {
  return (
    <SPseudoLink selected={selected} disabled={disabled} {...restProps}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {iconLeft && <SPseudoLinkIconLeft>{iconLeft}</SPseudoLinkIconLeft>}
      <SPseudoLinkInner>{children}</SPseudoLinkInner>
      {iconRight && <SPseudoLinkIconRight>{iconRight}</SPseudoLinkIconRight>}
    </SPseudoLink>
  );
}

export const SPseudoLink = styled.span<{
  selected?: boolean;
  disabled?: boolean;
}>`
  cursor: pointer;

  &:hover {
    color: #177efb !important;
  }

  ${(props) =>
    props.selected
      ? css`
          color: #177efb !important;
          cursor: default;
        `
      : ''}

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : ''}
`;

export const SPseudoLinkInner = styled.span`
  border-bottom: 1px dashed;
`;

export const SPseudoLinkIconLeft = styled.span`
  margin-right: 0.3em;
`;

export const SPseudoLinkIconRight = styled.span`
  margin-left: 0.3em;
`;
