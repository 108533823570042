import React, { FC, useState } from 'react';
import { Button } from '../../../components/Button/Button';
import { TManuscript, TYesOrNo } from '../../../types';
import { sendAdministrativeReviewReq, sendScientificReviewReq } from '../api';
import styled from 'styled-components';

type TProps = {
  showBothButtons?: boolean;
  manuscript: TManuscript;
  updateManuscript: (manuscript: TManuscript) => void;
};

type TButtonType = 'admin' | 'scientific';

export const ManuscriptReview: FC<TProps> = ({ manuscript, updateManuscript, showBothButtons }) => {
  const [isSubmittingAdmin, setIsSubmittingAdmin] = useState(false);
  const [isSubmittingScientific, setIsSubmittingScientific] = useState(false);

  const getRevertedStatus = (type: TButtonType) => {
    if (getCurrentStatus(type) === 'N') {
      return 'Y';
    }

    return 'N';
  };

  const getCurrentStatus = (type: TButtonType): TYesOrNo => {
    if (type === 'admin') {
      return manuscript.administrativeReview;
    }
    if (type === 'scientific') {
      return manuscript.scientificReview;
    }
  };

  const sendOrRevertAdminReview = async () => {
    try {
      setIsSubmittingAdmin(true);
      const updatedManuscript = await sendAdministrativeReviewReq(manuscript.id, getRevertedStatus('admin'));
      updateManuscript(updatedManuscript);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmittingAdmin(false);
    }
  };

  const sendOrRevertScientificReview = async () => {
    try {
      setIsSubmittingScientific(true);
      const updatedManuscript = await sendScientificReviewReq(manuscript.id, getRevertedStatus('scientific'));
      updateManuscript(updatedManuscript);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmittingScientific(false);
    }
  };

  const AdminSubmitText =
    getCurrentStatus('admin') === 'N' ? 'Complete Administrative Review' : 'Revoke Administrative Review';
  const ScientificSubmitText =
    getCurrentStatus('scientific') === 'N' ? 'Complete Scientific Review' : 'Revoke Scientific Review';

  return (
    <SButons>
      {showBothButtons && (
        <>
          <Button
            type="submit"
            onClick={() => {
              sendOrRevertAdminReview();
            }}
            value={isSubmittingAdmin ? 'Submitting...' : AdminSubmitText}
            disabled={isSubmittingAdmin}
          />{' '}
          <SOr>or</SOr>
        </>
      )}

      <Button
        type="submit"
        onClick={() => {
          sendOrRevertScientificReview();
        }}
        value={isSubmittingScientific ? 'Submitting...' : ScientificSubmitText}
        disabled={isSubmittingScientific}
      />
    </SButons>
  );
};

const SButons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const SOr = styled.div`
  padding: 0 10px;
`;
