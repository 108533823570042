import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import nl2br from 'react-nl2br';
import { getTextDate } from '../../../utils/utils';
import { PseudoLink } from '../../PseudoLink';
import { SFileLink } from '../../../pages/Application/styles';
import { TGetDownloadLink, TRemoveCommentCb } from '../types';
import { TServerComment } from '../../../types';

export const Comment = ({
  comment,
  getDownloadLink,
  onRemove,
  canRemoveComment = true,
}: {
  comment: TServerComment;
  getDownloadLink: TGetDownloadLink;
  onRemove: TRemoveCommentCb;
  canRemoveComment: boolean;
}) => {
  const [removing, setRemoving] = useState(false);
  return (
    <SComment removing={removing}>
      <SIcon>
        <FontAwesomeIcon icon={faComment} />
      </SIcon>
      <SCommentBody>
        <SName>{comment.author.name}</SName>
        <SText>{nl2br(comment.text)}</SText>
        {(comment.attachments || []).map((file, index) => (
          <SFileLink href={getDownloadLink(file.id)} key={index} target={'_blank'}>
            {file.fNameOriginal}
          </SFileLink>
        ))}
        <SDate>{getTextDate(new Date(comment.created_at))}</SDate>
        {canRemoveComment && (
          <PseudoLink
            style={{ fontSize: 11, color: 'grey' }}
            onClick={async () => {
              if (window.confirm('Are you sure?')) {
                setRemoving(true);
                try {
                  await onRemove(comment.id);
                } catch (e) {
                  console.error(e);
                } finally {
                  setRemoving(false);
                }
              }
            }}
          >
            Remove Comment
          </PseudoLink>
        )}
      </SCommentBody>
      <br />
    </SComment>
  );
};

const SComment = styled.div<{ removing: boolean }>`
  display: flex;
  margin-bottom: 30px;
  position: relative;
  opacity: ${({ removing }) => (removing ? '0.3' : '1')};
`;

const SCommentBody = styled.div`
  margin-bottom: 15px;
`;

const SText = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 10px;
`;

const SName = styled.div`
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const SDate = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  opacity: 0.5;
`;

const SIcon = styled.div`
  margin-right: 15px;
  opacity: 0.2;
`;
