import React from 'react';
import styled from 'styled-components';
import { CommentForm } from './components/CommentForm';
import { Comment } from './components/Comment';
import { SHeader1 } from '../../pages/styles';
import { TGetDownloadLink, TOnAddComment, TRemoveCommentCb } from './types';
import { TServerComment } from '../../types';

export const Comments = ({
  comments = [],
  onAdd,
  getDownloadLink,
  onRemove,
  canRemoveComment = true,
}: {
  comments: TServerComment[];
  onAdd: TOnAddComment;
  getDownloadLink: TGetDownloadLink;
  onRemove: TRemoveCommentCb;
  canRemoveComment: boolean;
}) => {
  return (
    <div>
      <SDiscussDelimiter />
      <SHeader1>
        Comments
        {comments.length > 0 && <span className="hint">{comments.length}</span>}
      </SHeader1>
      {comments.map((comment) => {
        return (
          <Comment
            key={comment.id}
            comment={comment}
            getDownloadLink={getDownloadLink}
            onRemove={onRemove}
            canRemoveComment={canRemoveComment}
          />
        );
      })}
      <br />
      <CommentForm onAdd={onAdd} />
    </div>
  );
};

const SDiscussDelimiter = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 85px;
`;
