import { ProposalTitleSection } from '../pages/GrantsRequest/features/Steps/ProposalTitleSection/ProposalTitleSection';
import { InvestigatorsSection } from '../pages/GrantsRequest/features/Steps/InvestigatorsSection';
import { AdminSection } from '../pages/GrantsRequest/features/Steps/AdminSection';
import { personInitialState } from '../pages/GrantsRequest/components/PersonItem';
import { AbstractSection } from '../pages/GrantsRequest/features/Steps/AbstractSection';
import { SummarySection } from '../pages/GrantsRequest/features/Steps/SummarySection';
import { ProjectSection } from '../pages/GrantsRequest/features/Steps/ProjectSection';
import { ReferencesSection } from '../pages/GrantsRequest/features/Steps/ReferencesSection';
import { milestonesInitialState, MilestonesSection } from '../pages/GrantsRequest/features/Steps/MilestonesSection';
import { BiographySection } from '../pages/GrantsRequest/features/Steps/BiographySection';
import { KeyPersonnelSection } from '../pages/GrantsRequest/features/Steps/KeyPersonnelSection';
import { BudgetSection } from '../pages/GrantsRequest/features/Steps/BudgetSection';
import { BudgetJustificationSection } from '../pages/GrantsRequest/features/Steps/BudgetJustificationSection';
import { SubjectsResearchSection } from '../pages/GrantsRequest/features/Steps/SubjectsResearchSection';

// import { KeyPersonnelSubmitted } from '../pages/Application/Steps/KeyPersonnelSubmitted';

// TODO: combine this config with pages/application/steps
export const STEPS = [
  {
    name: 'Proposal Title',
    component: ProposalTitleSection,
    // submittedComponent: ProposalTitleSectionSubmitted,
    id: 'proposalTitle',
    fields: {
      proposalTitle: '',
      institution: '',
    },
  },
  {
    name: 'Principal Investigator',
    component: InvestigatorsSection,
    // submittedComponent: InvestigatorsSectionSubmitted,
    id: 'principalInvestigator',
    fields: {
      ...personInitialState,
    },
  },
  {
    component: AdminSection,
    // submittedComponent: AdminSectionSubmitted,
    id: 'admin',
    name: 'Institution Administrative Official',
    fields: {
      ...personInitialState,
      approveAdmin: false,
    },
  },
  {
    component: AbstractSection,
    // submittedComponent: AbstractSectionSubmitted,
    id: 'abstract',
    name: 'Abstract and Significance',
    fields: {
      abstract: '',
    },
  },
  {
    component: SummarySection,
    // submittedComponent: SummarySectionSubmitted,
    id: 'summary',
    name: "Layperson's Summary",
    fields: {
      summary: '',
    },
  },
  {
    component: ProjectSection,
    // submittedComponent: ProjectSectionSubmitted,
    id: 'project',
    name: 'Project Description',
    fields: {
      file: null,
    },
  },
  {
    component: ReferencesSection,
    // submittedComponent: ReferencesSectionSubmitted,
    id: 'references',
    name: 'References',
    fields: {
      file: null,
    },
  },
  {
    component: MilestonesSection,
    // submittedComponent: MilestonesSectionSubmitted,
    id: 'milestones',
    name: 'Project Milestones',
    fields: {
      milestones: [
        {
          ...milestonesInitialState,
        },
      ],
      file: null,
    },
  },
  {
    component: BiographySection,
    // submittedComponent: BiographySectionSubmitted,
    id: 'biography',
    name: 'Biographical Sketch',
    fields: {
      file: null,
    },
  },
  {
    component: KeyPersonnelSection,
    // submittedComponent: KeyPersonnelSubmitted,
    id: 'keyPersonnel',
    name: 'Key Personnel',
    fields: {
      keyPersonnel: [],
    },
  },
  {
    component: BudgetSection,
    // submittedComponent: BudgetSectionSubmitted,
    id: 'budget',
    name: 'Budget',
    fields: {
      y1DirectCost: '',
      y1IndirectCost: '',
      y2DirectCost: '',
      y2IndirectCost: '',
      y3DirectCost: '',
      y3IndirectCost: '',
      file: null,
    },
  },
  {
    component: BudgetJustificationSection,
    // submittedComponent: BudgetJustificationSectionSubmitted,
    id: 'budgetJustification',
    name: 'Budget Justification',
    fields: {
      budgetJustification: '',
      budgetJustificationSubAward: false,
    },
  },
  {
    component: SubjectsResearchSection,
    // submittedComponent: SubjectsResearchSectionSubmitted,
    id: 'subjectsResearch',
    name: 'Animal and Human Subjects Research',
    fields: {
      animalResearch: false,
      animalUseCareCommittees: false,
      humanSubjects: false,
      approvedByInstitutionalReview: false,
      usesEstablishedCellLines: false,
      verified: false,
    },
  },
];
