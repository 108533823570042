import { Header } from '../Header/Header';
import React from 'react';
import styled from 'styled-components';

export const Layout: React.FC<{ loading?: boolean; withSubheader?: boolean; subHeader?: React.ReactElement }> = ({
  loading,
  children,
  subHeader = null,
}) => {
  return (
    <div className="container layout">
      <div className="row">
        <SHeaderWrapper id={'page-header'} className="col-xl-10 offset-xl-1">
          <Header subHeader={subHeader} />
        </SHeaderWrapper>
        <div className="col-xl-10 offset-xl-1">{loading ? '' : children}</div>
      </div>
    </div>
  );
};

const SHeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  margin-bottom: 10px;
`;
