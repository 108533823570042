import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../GrantsRequest/features/Login/LoginForm';
import { whereToRedirectAfterLogin } from '../../utils/accesses';
import { useDictContext } from '../../contexts/dictContext';
import { getStageRequest } from '../../api/api';
import { IUser } from '../../types';
import { STAGES } from '../../constants/stages';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [dict, setDict] = useDictContext();

  const redirectIfNeeded = (user: IUser, redirectTo: string, stage: STAGES) => {
    if (redirectTo) {
      // we reload the page because back-end returns whole url with http://... and we can not just push it to history
      // Improve it later
      window.location.replace(redirectTo);
    } else if (user) {
      // here we return url after first slash, so we can push it to history
      navigate(whereToRedirectAfterLogin(user, stage));
    }
  };

  return (
    <div className="form-login-wrap">
      <LoginForm
        onSuccess={async (user: IUser, redirectTo: string) => {
          const stage = await getStageRequest();

          setDict({ ...dict, stage: stage });
          redirectIfNeeded(user, redirectTo, stage);
        }}
      />
    </div>
  );
};
