import React from 'react';
import styled from 'styled-components';

export const SubHeader = ({
  firstLine,
  secondLine,
}: {
  firstLine: React.ReactElement;
  secondLine?: React.ReactElement;
}) => {
  return (
    <>
      <SSubHeaderFirstLine>{firstLine}</SSubHeaderFirstLine>

      {secondLine && <SSubHeaderSecondLine>{secondLine}</SSubHeaderSecondLine>}
    </>
  );
};

const SSubHeaderFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
`;

const SSubHeaderSecondLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
`;
