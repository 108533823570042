import { Textarea } from '../../../../../components/Textarea/Textarea';
import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { STextError } from '../../../../styles';
import styled from 'styled-components';
import { SButton } from '../../../../../components/Button/styles';

export const CommentForm = ({ onAddComment }) => {
  const [newComment, setNewComment] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let { id } = useParams();

  const postComment = () => {
    if (newComment === '') {
      return;
    }

    setIsLoading(true);

    axios
      .post(
        `/api/applications/${id}/add-comment`,
        { text: newComment },
        {
          withCredentials: true,
        },
      )
      .then((resp) => {
        setNewComment('');
        setErrorText('');
        console.log({ resp });
        onAddComment(resp.data.comment);
      })
      .catch((error) => {
        setErrorText('Error: ' + error.response.data.message);
        console.log({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Textarea
        value={newComment}
        onChange={(comment) => {
          setNewComment(comment);
        }}
        disabled={isLoading}
      />

      <SPostButtonWrapepr>
        <SButton
          mode="full"
          type="button"
          size="m"
          disabled={isLoading}
          onClick={() => {
            postComment();
          }}
        >
          {isLoading ? 'Adding...' : 'Add comment'}
        </SButton>
      </SPostButtonWrapepr>

      {errorText !== '' && <STextError>{errorText}</STextError>}
    </>
  );
};

const SPostButtonWrapepr = styled.div``;
