import React, { FC, useState } from 'react';
import styled from 'styled-components';
import jsonToFormData from 'json-form-data';
import { Textarea } from '../../../../../../components/Textarea/Textarea';
import { SButton } from '../../../../../../components/Button/styles';
import { STextError } from '../../../../../styles';
import { httpClient } from '../../../../../../utils/httpClient';
import { InputFileLocal } from '../../InputFileLocal/InputFileLocal';
import { TApplicationId, TReportComment } from '../../../../types';
import { useSearchParams } from 'react-router-dom';
import { useReportingVersion } from '../../../../../../hooks/useReportingVersion';

type TProps = {
  onAddComment: (comment: TReportComment) => void;
  applicationId: TApplicationId;
};

export const CommentForm: FC<TProps> = ({ onAddComment, applicationId }) => {
  const [newComment, setNewComment] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { version } = useReportingVersion();

  const postComment = () => {
    if (newComment === '') {
      return;
    }

    setIsLoading(true);
    const newCommentFormData = jsonToFormData({
      text: newComment,
      attachments: attachments,
    });

    httpClient
      .post(
        `/api/applications/${applicationId}/proposal-reporting/add-comment?version=${version}`,
        newCommentFormData,
        {
          withCredentials: true,
        },
      )
      .then((resp) => {
        setNewComment('');
        setAttachments([]);
        setErrorText('');
        onAddComment(resp.data.comment);
      })
      .catch((error) => {
        setErrorText('Error: ' + error.response.data.message);
        console.log({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Textarea
        value={newComment}
        onChange={(comment: string) => {
          setNewComment(comment);
        }}
        disabled={isLoading}
      />

      <InputFileLocal
        multiple
        disabled={false}
        value={attachments}
        onChange={(f) => {
          setAttachments(f);
        }}
        placeholder="Click to upload files"
      />

      <br />
      <br />
      <SPostButtonWrapper>
        <SButton
          mode="full"
          type="button"
          size="m"
          disabled={isLoading}
          onClick={() => {
            postComment();
          }}
        >
          {isLoading ? 'Adding...' : 'Add comment'}
        </SButton>
      </SPostButtonWrapper>

      {errorText !== '' && <STextError>{errorText}</STextError>}
    </>
  );
};

const SPostButtonWrapper = styled.div``;
