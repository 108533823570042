import React from 'react';
import styled from 'styled-components';

export const Checkbox = ({ onChange, label = null, isValid, ...props }) => {
  return (
    <SCheckboxWrapper isValid={isValid}>
      <SCheckboxInputWrapper>
        <SCheckboxInput
          type="checkbox"
          onChange={(e) => {
            onChange(e.target.checked, e);
          }}
          {...props}
        />
      </SCheckboxInputWrapper>
      {label && <SCheckboxLabel htmlFor={props.id}>{label}</SCheckboxLabel>}
    </SCheckboxWrapper>
  );
};

const SCheckboxLabel = styled.label`
  width: 100%;
`;

const SCheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  ${({ isValid }) =>
    !isValid &&
    `
    border: 1px solid #F36242;
    border-radius: 5px;
  `}
`;

const SCheckboxInput = styled.input``;

const SCheckboxInputWrapper = styled.div`
  width: 25px;
`;
