import React from 'react';
import parse from 'date-fns/parse';
import { DATE_BACKEND_FORMAT } from '../../GrantsRequest/constants/dateFormat';
import { SBlock, SHeader2, SLabel, SText } from '../../styles';
import { getTextDate } from '../../../utils/utils';
import nl2br from 'react-nl2br';
import { MilestoneDateEditor } from './components/MilestoneDateEditor';
import { canEditManuscriptDate } from '../../../utils/accesses';
import { useDictContext } from '../../../contexts/dictContext';
import { stringToDate } from '../../../utils/dates';

export const MilestoneItem = ({ state, index }) => {
  const { date, milestoneOutline, deliverable, id } = state;
  const [dict] = useDictContext();
  const { userType } = dict.user;
  const canEditManuscriptDateFlag = canEditManuscriptDate(userType);

  return (
    <SBlock withBorder={true}>
      <SHeader2>#{index + 1}</SHeader2>
      <div>
        <SLabel htmlFor="">Date</SLabel>

        {canEditManuscriptDateFlag ? (
          <MilestoneDateEditor milestoneId={id} initialDate={date}></MilestoneDateEditor>
        ) : (
          <SText>{getTextDate(stringToDate(date))}</SText>
        )}
      </div>
      <div>
        <SLabel htmlFor="">Milestone Outline</SLabel>
        <SText>{nl2br(milestoneOutline)}</SText>
      </div>
      <div>
        <SLabel htmlFor="">Deliverable</SLabel>
        <SText>{nl2br(deliverable)}</SText>
      </div>
    </SBlock>
  );
};
