import React, { useRef, useEffect, useState } from 'react';
import { STextareaAdvice, STextarea, STextareaWrapper } from './styles';
import { STextError } from '../../pages/styles';

export function Textarea({
  light = false,
  isValid = true,
  onChange,
  max = null,
  maxHeight = '',
  advice = null,
  ...props
}) {
  const textArea = useRef(null);
  const [textExceeded, setTextExceeded] = useState(false);

  maxHeight = maxHeight || 300;

  useEffect(() => {
    handleKeyDown();
  }, []);

  function handleKeyDown() {
    textArea.current.style.height = 'inherit';
    textArea.current.style.height = `${Math.min(
      textArea.current.scrollHeight,
      maxHeight,
    )}px`;
  }

  return (
    <>
      <STextareaWrapper>
        <STextarea
          style={{ boxSizing: 'border-box' }}
          light={light}
          {...props}
          isValid={isValid}
          ref={textArea}
          onChange={(e) => {
            const value = e.target.value;
            handleKeyDown();
            onChange(value, e);

            if (max && value.length > max) {
              setTextExceeded(true);
            } else if (max) {
              setTextExceeded(false);
            }
          }}
        />

        {advice ? <STextareaAdvice>{advice}</STextareaAdvice> : ''}
      </STextareaWrapper>
      {textExceeded && (
        <STextError>{`You've enterеd more than ${max} symbols`}</STextError>
      )}
    </>
  );
}

Textarea.defaultProps = {
  light: false,
};
