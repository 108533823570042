import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { SHeader1 } from '../styles';
import { useSetState } from 'react-use';
import styled from 'styled-components';
import { addCommentReq, getManuscriptRequest, removeCommentReq, removeManuscriptRequest } from './api';
import { AttachedFile } from '../ProposalReporting/components/AttachedFile/AttachedFile';
import { MANUSCRIPT_TITLES } from './constants';
import { useNavigate, useParams } from 'react-router-dom';
import { TManuscript, TManuscriptTextFields } from '../../types';
import {
  canCompleteAdministrativeManuscriptReview,
  canCompleteScientificManuscriptReview,
  canRemoveComment,
  canRemoveManuscript,
  canSeeAndWriteManuscriptComments,
} from '../../utils/accesses';
import { getManuscriptStatus } from '../Manuscripts/utils';
import format from 'date-fns/format';
import { useDictContext } from '../../contexts/dictContext';
import { ManuscriptReview } from './components/ManuscriptReview';
import { ROLES } from '../../constants/roles';
import { Comments } from '../../components/Comments/Comments';
import { TFormDataComment } from '../../components/Comments/types';
import { Button } from '../../components/Button/Button';
import { PAGES } from '../../constants/pages';

export const Manuscript = () => {
  const [dict] = useDictContext();
  const { userType } = dict.user;
  const [isRemoving, setIsRemoving] = useState(false);
  const [state, setState] = useSetState<{ manuscript: TManuscript; isLoading: boolean; comments: any[] }>({
    manuscript: null,
    comments: [],
    isLoading: true,
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const updateManuscript = (manuscript: TManuscript) => {
    setState({ manuscript });
  };

  const loadManuscript = async () => {
    try {
      const result = await getManuscriptRequest(id);
      updateManuscript(result);
    } catch (e) {
    } finally {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    loadManuscript();
  }, []);

  if (state.isLoading) {
    return null;
  }

  const file = state.manuscript.manuscriptFile;
  const textFields = [
    'journalInfo',
    'manuscriptType',
    'acknowledgement',
    'acknowledgementPages',
    'supportedByProject',
    'userComments',
  ];

  const onAddComment = async (newComment: TFormDataComment) => {
    const comment = await addCommentReq(newComment, id);

    setState({ manuscript: { ...state.manuscript, comments: [...state.manuscript.comments, comment] } });
  };

  const onRemoveComment = async (commentId: number) => {
    try {
      await removeCommentReq(id, commentId);
      await loadManuscript();
    } catch (e) {
      alert('Sorry, an error occurred while deleting comment');
      console.error(e);
    }
  };

  const onRemoveManuscript = async () => {
    if (!confirm('Are you sure?')) {
      return;
    }

    try {
      setIsRemoving(true);
      await removeManuscriptRequest(id);
      navigate(PAGES.MANUSCRIPTS);
    } catch (e) {
      console.error({ e });
      alert('Error');
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <Layout>
      <SLayoutInner>
        <SContent>
          <SHeader1>Submitted Manuscript</SHeader1>

          {userType !== ROLES.USER && (
            <SBlock key={'awardee'}>
              <SDesc>{MANUSCRIPT_TITLES['awardee']}</SDesc>
              <SManuscriptTextItem>{state.manuscript?.user?.name}</SManuscriptTextItem>
            </SBlock>
          )}

          <SBlock key={'title'}>
            <SDesc>{MANUSCRIPT_TITLES['title']}</SDesc>
            <SManuscriptTextItem>{state.manuscript.title}</SManuscriptTextItem>
          </SBlock>

          <SBlock key={'status'}>
            <SDesc>Review status</SDesc>
            <SManuscriptTextItem>{getManuscriptStatus(state.manuscript)}</SManuscriptTextItem>
          </SBlock>

          <SBlock key={'date'}>
            <SDesc>Date of submission</SDesc>
            <SManuscriptTextItem>{format(new Date(state.manuscript.submittedAt), 'PP')}</SManuscriptTextItem>
          </SBlock>

          <SBlock>
            <SDesc>Uploaded manuscript</SDesc>
            <AttachedFile file={file} removeHandler={undefined} href={`/api/manuscript/${file.id}/download-file`} />
          </SBlock>

          {textFields.map((titleKey: keyof TManuscriptTextFields) => {
            return (
              <SBlock key={titleKey}>
                <SDesc>{MANUSCRIPT_TITLES[titleKey]}</SDesc>
                <SManuscriptTextItem>{state.manuscript[titleKey]}</SManuscriptTextItem>
              </SBlock>
            );
          })}

          {canCompleteAdministrativeManuscriptReview(userType) ? (
            <ManuscriptReview manuscript={state.manuscript} updateManuscript={updateManuscript} showBothButtons />
          ) : canCompleteScientificManuscriptReview(userType) ? (
            <ManuscriptReview manuscript={state.manuscript} updateManuscript={updateManuscript} />
          ) : null}

          {canRemoveManuscript(userType, state.manuscript.administrativeReview, state.manuscript.scientificReview) && (
            <>
              <Button disabled={isRemoving} onClick={onRemoveManuscript} value="Remove manuscript" />
              <br />
              <br />
              <br />
            </>
          )}

          {canSeeAndWriteManuscriptComments(userType) && (
            <Comments
              canRemoveComment={canRemoveComment(userType)}
              comments={state.manuscript.comments}
              onAdd={onAddComment}
              onRemove={onRemoveComment}
              getDownloadLink={(fileId) => `/api/manuscript/${id}/download-file/${fileId}`}
            />
          )}
        </SContent>
      </SLayoutInner>
    </Layout>
  );
};

const SDesc = styled.div`
  font-size: 14px;
  color: grey;
  margin-bottom: 7px;
`;

const SBlock = styled.div`
  margin-bottom: 20px;
`;
const SLayoutInner = styled.div`
  display: flex;
  justify-content: center;
`;

const SContent = styled.div`
  width: 600px;
  margin-bottom: 100px;
`;

const SManuscriptTextItem = styled.div`
  font-size: 16px;
`;
