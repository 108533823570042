import { calculateAndFillData } from './utils/calculateAndFillData';
import { httpClient } from '../../utils/httpClient';
import {
  IProposalReportingResponse,
  TProposalReportingPatch,
  TProposalReportingVersion,
  TSendBackPayload,
} from './types';

import { fillEmptyFinancialReportWithZeroesIfNeeded } from './utils/fillEmptyFinancialReportWithZeroesIfNeeded';
import { fillEmptyPersonnelReportDataWithZeroesIfNeeded } from './utils/fillEmptyPersonnelReportDataWithZeroesIfNeeded';

const fixFinancialExpenditureReportData = (responseData: IProposalReportingResponse) => {
  const { financialExpenditureReport, personnelReport } = responseData;

  financialExpenditureReport.data = fillEmptyFinancialReportWithZeroesIfNeeded(financialExpenditureReport.data);
  personnelReport.data = fillEmptyPersonnelReportDataWithZeroesIfNeeded(personnelReport.data);
  financialExpenditureReport.data = calculateAndFillData(financialExpenditureReport.data);
};

export const getInitialData = async (
  applicationId: string | number,
  version: TProposalReportingVersion,
): Promise<IProposalReportingResponse> => {
  const resp = await httpClient.get<IProposalReportingResponse>(
    `/api/applications/${applicationId}/proposal-reporting?version=${version}`,
  );

  fixFinancialExpenditureReportData(resp.data);

  return resp.data;
};

export const patchProposalReporting = async (
  applicationId: number | string,
  data: TProposalReportingPatch,
  version: TProposalReportingVersion,
) => {
  await httpClient.patch(`/api/applications/${applicationId}/proposal-reporting?version=${version}`, data);
};

export const patchProposalReportingApprovedBudget = async (
  applicationId: number | string,
  data: TProposalReportingPatch,
  version: TProposalReportingVersion,
) => {
  await httpClient.patch(
    `/api/applications/${applicationId}/proposal-reporting/update-financial-expenditure?version=${version}`,
    data,
  );
};

export const patchPersonnelByAdmin = async (
  applicationId: number | string,
  data: TProposalReportingPatch,
  version: TProposalReportingVersion,
) => {
  await httpClient.patch(
    `/api/applications/${applicationId}/proposal-reporting/personnel-report-update?version=${version}`,
    data,
  );
};

export const sendReportBackRequest = async (
  reportId: number | string,
  data: TSendBackPayload,
  version: TProposalReportingVersion,
) => {
  return await httpClient.post(`/api/applications/${reportId}/proposal-reporting/send-back?version=${version}`, data);
};
