import styled from 'styled-components';

export const SFileLink = styled.a`
  display: inline-block;
  padding: 3px 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: black;

  &:hover {
    background: #eee;
    color: black;
  }
`;

export const SFileWrapper = styled.div`
  margin-bottom: 10px;
`;

export const SPanelWrapper = styled.div`
  width: 90%;
  margin-top: 20px;
  padding: 5px 18px 15px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 20px;
`;

export const SPanelHeader = styled.span`
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  opacity: 0.5;
`;

export const SPanelButton = styled.div`
  margin-top: 10px;
`;

export const SPanelButtonIcon = styled.i`
  display: inline-block;
  width: 20px;
  height: 20px;

  background: url(/i/ok.svg) 50% 50% no-repeat;
  background-size: contain;

  position: absolute;
  margin-left: -30px;
  top: 50%;
  margin-top: -10px;
`;
