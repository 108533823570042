import React from 'react';
import { Button } from '../../../components/Button/Button';

export const ItemMultiplier = ({
  data,
  setState,
  errors = [],
  validator,
  fieldName,
  addButtonText = 'Add',
  newItemInitialState,
  ItemComponent,
  limit = null,
  title = 'Principal Investigator',
  allowRemoveSingle = false,
}) => {
  const onDelete = (index) => {
    setState({
      [fieldName]: data.filter((item, i) => i !== index),
    });
  };

  const setSliceState = (newData, principal, index) => {
    const dataCopy = [...data];
    dataCopy[index] = {
      ...principal,
      ...newData,
    };

    setState({
      [fieldName]: dataCopy,
    });
  };

  const isLast = data.length === 1;
  const showAddButton = (limit && data.length < limit) || !limit;

  return (
    <>
      {data.map((principalStateSlice, index) => {
        return (
          <ItemComponent
            titleBlock={title}
            index={index}
            key={index}
            state={principalStateSlice}
            onDelete={
              isLast && !allowRemoveSingle
                ? undefined
                : () => {
                    onDelete(index);
                  }
            }
            setState={(newData) => {
              setSliceState(newData, principalStateSlice, index);
            }}
            errors={errors[index] || {}}
            validator={validator}
          />
        );
      })}
      {showAddButton && (
        <Button
          value={addButtonText}
          type={'button'}
          mode="add"
          onClick={(e) => {
            e.preventDefault();
            setState({
              [fieldName]: [...data, { ...newItemInitialState }],
            });
          }}
        />
      )}
    </>
  );
};
