import React from 'react';
import { GrantsRequestInputFile } from '../../components/GrantsRequestInputFile';
import { SDescription, SInputTip } from '../../styles';
import { SHeader1 } from '../../../styles';

export const ReferencesSection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        <p>
          <b>References:</b> Provide a concise and relevant list of references
          cited for the proposal. References listed are not included in the five
          page limit of the project description.
        </p>
      </SDescription>
      <GrantsRequestInputFile {...props} accept="application/pdf" />
      <SInputTip style={{ marginTop: 10 }}>Format: PDF</SInputTip>
    </section>
  );
};
