import styled from 'styled-components';
import { TMode, TSize, TStatus } from './types';
type TButtonProps = { size?: TSize; mode?: TMode; status?: TStatus };

export const SButton = styled.button<TButtonProps>`
  position: relative;
  min-width: ${(p) => (p.size === 'm' ? '100px' : 'auto')};
  padding: ${(p) => (p.size === 'm' ? '0.5em 2.2em' : '3px 10px')};
  border: 0 none;
  border-radius: 6px;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  text-align: center;
  user-select: none;

  ${(props) =>
    props.mode === 'full'
      ? `
    display: block;
    width: 100%;
    `
      : ''}

  ${(props) =>
    props.mode === 'add'
      ? `
    position: relative;
    background: #f6f6f6;
    color: black;
    padding: 10px 45px 10px 20px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      top: 50%;
      margin-top: -8px;
      right: 20px;

      background: url(/i/cross.svg) 50% 50% no-repeat;
      background-size: contain;
    }
    `
      : ''}

  ${(props) =>
    props.mode === 'fix'
      ? `
    display: inline-block;
    width: 150px;
    `
      : ''}

  &:hover,
  &:active,
  &:focus {
    opacity: 0.75;
    outline: none;
  }

  &[type='button'] {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[type='button'] {
    ${(props) =>
      props.status === 'success'
        ? `
          border-color: #0CAF4D;
          color: #0CAF4D;
        `
        : ''}

    ${(props) =>
      props.status === 'alert'
        ? `
          border-color: rgba(216, 133, 108);
          background-color: rgba(250, 240, 237);
        `
        : ''}
  }

  &[type='submit'] {
    border: 1px solid #000;

    ${(props) =>
      props.status === 'success'
        ? `
          border: 1px solid #0CAF4D;
          background-color: #0CAF4D;
        `
        : ''}

    ${(props) =>
      props.status === 'error'
        ? `
          opacity: 1;
          border: 1px solid #F36242;
          background-color: #F36242;
        `
        : ''}
  }
`;
