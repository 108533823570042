import React from 'react';
import { SPersonInfo } from '../../GrantsRequest/styles';
import { SBlock, SHeader2, SHeader3, SLabel, SText, SLine } from '../../styles';
import { useDictContext } from '../../../contexts/dictContext';
import styled from 'styled-components';

const isEmpty = (val) => {
  return val === undefined || val === null || val === '';
};

export const SubmittedPersonItem = ({ titleBlock, index, state }) => {
  const [dict] = useDictContext();

  const applicationState = isEmpty(state.stateId)
    ? ''
    : dict.states.find((s) => s.id === state.stateId).state;

  const applicationCountry = isEmpty(state.countryId)
    ? ''
    : dict.countries.find((s) => s.id === state.countryId).country;

  return (
    <SBlock>
      {titleBlock && (
        <SHeader2>
          {titleBlock} #{index + 1}
        </SHeader2>
      )}

      <SPITitle>
        {state.firstName} {state.lastName}
      </SPITitle>

      <SPersonInfo>
        {state.position !== undefined && (
          <div>
            <SLabel htmlFor="">Role on Project</SLabel>
            <SText>{state.position}</SText>
          </div>
        )}
        <div>
          <SLabel htmlFor="">Title</SLabel>
          <SText>{state.title}</SText>
        </div>
        <div>
          <SLabel htmlFor="">Institution</SLabel>
          <SText>{state.institution}</SText>
        </div>
      </SPersonInfo>

      <SLine />

      <fieldset>
        <SHeader3>Contact Information</SHeader3>
        <div>
          <SLabel htmlFor="">Email</SLabel>
          <SText>{state.email}</SText>
        </div>
        <div>
          <SLabel htmlFor="">Address</SLabel>
          <SText>{state.address}</SText>
        </div>
        <div>
          <SLabel htmlFor="">City</SLabel>
          <SText>{state.city}</SText>
        </div>
        <div>
          <SLabel htmlFor="">State</SLabel>
          <SText>{applicationState}</SText>
        </div>
        <div>
          <SLabel htmlFor="">Zip Code</SLabel>
          <SText>{state.zipCode}</SText>
        </div>
        <div>
          <SLabel htmlFor="">Country</SLabel>
          <SText>{applicationCountry}</SText>
        </div>
        <div>
          <SLabel htmlFor="">Phone</SLabel>
          <SText>{state.phone}</SText>
        </div>
      </fieldset>
    </SBlock>
  );
};

export const SPITitle = styled.h3`
  height: 24px;
  font-size: 18px;
  font-weight: 700;
`;
