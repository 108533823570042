import React from 'react';
import { Checkbox } from '../../../components/Checkbox/Checkbox';

export const GrantsRequestCheckbox = ({
  state,
  setState,
  fieldName,
  errors,
  validator = () => {},
  advice,
  ...props
}) => {
  return (
    <Checkbox
      {...props}
      value={state[fieldName]}
      onChange={(val) => {
        setState({
          [fieldName]: val,
        });
      }}
      checked={state[fieldName]}
      isValid={!errors[fieldName]}
      // TODO: do more smart validation than just onBlur
      onBlur={validator}
    />
  );
};
