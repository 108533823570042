import styled from 'styled-components';

export const SNavigation = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  font-weight: 700;

  > div:last-child {
    text-align: right;
  }
`;

export const SNav = styled.a`
  display: inline-block;
  font-size: 14px;
  color: black;
  margin-right: 18px;

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const SNavPrev = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  color: black;

  &:hover {
    opacity: 0.5;
  }

  &:before {
    position: relative;
    display: inline-block;
    content: '';
    width: 9px;
    height: 14px;

    background: url('/i/arrow.svg') 50% 50% no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-right: 8px;
  }
`;

export const SNavNext = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;

  &:hover {
    opacity: 0.5;
  }

  &:after {
    position: relative;
    display: inline-block;
    content: '';
    width: 9px;
    height: 14px;
    transform: rotate(180deg);

    background: url('/i/arrow.svg') 50% 50% no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-left: 8px;
  }
`;
