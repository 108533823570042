import { Datepicker } from '../../../../components/Datepicker/Datepicker';
import { GrantsRequestInput } from '../../components/GrantsRequestInput';
import { Textarea } from '../../../../components/Textarea/Textarea';
import React from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { DATE_BACKEND_FORMAT } from '../../constants/dateFormat';
import { SControlRemoveWrapper, SControlRemove } from '../../styles';
import { SBlock, SHeader2, SLabel } from '../../../styles';

export const MilestoneItem = ({ onDelete, titleBlock, index, ...props }) => {
  const { date } = props.state;
  const parsedBackendDate = date ? parse(date, DATE_BACKEND_FORMAT, new Date()) : '';

  return (
    <SBlock withBorder={true}>
      <SHeader2>#{index + 1}</SHeader2>
      <div>
        <SLabel htmlFor="">Date</SLabel>
        <Datepicker
          value={parsedBackendDate}
          onChange={(date) => {
            props.setState({
              date: date ? format(date, DATE_BACKEND_FORMAT) : null,
            });
          }}
          isValid={!props.errors.date}
        />
      </div>
      <div>
        <SLabel htmlFor="">Milestone Outline</SLabel>
        <GrantsRequestInput as={Textarea} fieldName={'milestoneOutline'} {...props} max={250} advice="250 characters" />
      </div>
      <div>
        <SLabel htmlFor="">Deliverable</SLabel>
        <GrantsRequestInput as={Textarea} fieldName={'deliverable'} {...props} max={250} advice="250 characters" />
      </div>
      <SControlRemoveWrapper>
        {onDelete && <SControlRemove onClick={onDelete}>Delete</SControlRemove>}
      </SControlRemoveWrapper>
    </SBlock>
  );
};
