import React from 'react';
import {
  SPrerenderInput,
  SPrerenderLabel,
  SPrerenderTextarea,
} from '../styles';
import { SHeader1 } from '../../styles';

export const Preloader = ({ stepTitle }) => {
  return (
    <section>
      {stepTitle && <SHeader1>{stepTitle}</SHeader1>}

      <SPrerenderLabel />
      <SPrerenderInput />

      <SPrerenderLabel />
      <SPrerenderInput />

      <SPrerenderLabel />
      <SPrerenderTextarea />

      <SPrerenderLabel />
      <SPrerenderInput />
    </section>
  );
};
