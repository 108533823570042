import { getManuscriptsListRequest } from './api';
import { useDictContext } from '../../contexts/dictContext';
import { TManuscript, TManuscriptId } from '../../types';
import { useEffect, useState } from 'react';
import { useSetState } from 'react-use';
import { getIsManuscriptStatusFitsFilter } from './utils';
import { TFilterFunction } from './types';
import { removeManuscriptRequest } from '../Manuscript/api';

const PER_PAGE = 25;

export const useEnhanced = () => {
  const [shown, setShown] = useState(PER_PAGE);

  const [dict] = useDictContext();
  const { userType } = dict.user;

  const [state, setState] = useSetState<{
    manuscriptsList: TManuscript[];
    filteredManuscriptList: TManuscript[];
    isLoading: boolean;
  }>({
    manuscriptsList: [],
    filteredManuscriptList: [],
    isLoading: true,
  });

  const filteredLength = state.filteredManuscriptList.length;
  const rest = filteredLength - shown;

  const showMore = () => {
    let sum = PER_PAGE;

    if (rest <= PER_PAGE) {
      sum = rest;
    }
    setShown((shownCur) => shownCur + sum);
  };

  const loadManuscripts = async () => {
    try {
      setState({ isLoading: true });
      await fetchAndSetManuscripts();
    } catch (e) {
    } finally {
      setState({ isLoading: false });
    }
  };

  const fetchAndSetManuscripts = async () => {
    const result = await getManuscriptsListRequest(userType);
    setState({ manuscriptsList: result, filteredManuscriptList: result });
  };

  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveManuscript = async (id: TManuscriptId) => {
    if (!confirm('Are you sure?')) {
      return;
    }
    try {
      setIsRemoving(true);
      await removeManuscriptRequest(id);
      await fetchAndSetManuscripts();
    } catch (e) {
      console.error({ e });
      alert(e.message);
    } finally {
      setIsRemoving(false);
    }
  };

  useEffect(() => {
    loadManuscripts();
  }, []);

  const shownItems = state.filteredManuscriptList.slice(0, shown);
  const showMoreText = rest <= PER_PAGE ? rest : PER_PAGE;

  const filter: TFilterFunction = ({ text, status }) => {
    const filtered = state.manuscriptsList.filter((item) => {
      const statusCondition = getIsManuscriptStatusFitsFilter(item, status);
      let textCondition = text ? item.user?.name.toLowerCase().includes(text.toLowerCase()) : true;

      return textCondition && statusCondition;
    });

    setState({ filteredManuscriptList: filtered });
  };

  return {
    noManuscriptsSent: state.manuscriptsList.length === 0,
    noManuscriptsFound: shownItems.length === 0,
    manuscriptsList: shownItems,
    isLoading: state.isLoading,
    userType,
    showMoreText,
    showMore,
    showShowMoreButton: filteredLength > shown,
    filter,
    onRemoveManuscript,
    isRemoving,
  };
};
