import React from 'react';
import { Textarea } from '../../../../components/Textarea/Textarea';
import { GrantsRequestInput } from '../../components/GrantsRequestInput';
import { SDescription } from '../../styles';
import { SHeader1 } from '../../../styles';
import { GrantsRequestCheckbox } from '../../components/GrantsRequestCheckbox';
import styled from 'styled-components';

export const BudgetJustificationSection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        <p>
          Applicants should also provide a concise justification of costs and
          indicate if equipment will be purchased using award funds.
        </p>
        <p>
          If a sub-award will be made, a clear justification must be provided.
          The amount of the budget to be allocated to the sub-award must also be
          provided. All sub-awards will be reviewed and must be approved by
          MWRF.
        </p>
      </SDescription>

      <SCheckboxListItem>
        <GrantsRequestCheckbox
          label="Check box if a sub-award will be made"
          id="form-checkbox-sub-award"
          fieldName={'budgetJustificationSubAward'}
          {...props}
        />
      </SCheckboxListItem>

      <GrantsRequestInput
        as={Textarea}
        fieldName={'budgetJustification'}
        advice="7000 characters"
        max={7000}
        autoFocus
        {...props}
      />
    </section>
  );
};

const SCheckboxListItem = styled.div`
  margin-bottom: 11px;
`;
