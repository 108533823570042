import React from 'react';
import styled from 'styled-components';

export const Select = ({ options, value, onChange, children, ...props }) => {
  return (
    <SSelect
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        onChange(val, e);
      }}
      {...props}
    >
      {children}
    </SSelect>
  );
};

export const SSelect = styled.select`
  width: 100%;
  padding: 6px 9px 6px 6px;
  box-sizing: border-box;
  background-color: #fbfbfb;
  border-radius: 4px;
  margin-bottom: 10px;
  border: ${({ isValid }) =>
    isValid ? '1px solid #CCCCCC;' : '1px solid #F36242'};
`;
