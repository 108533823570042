import React from 'react';
import { Layout } from '../../components/Layout/Layout';
import { SHeader1, SHeader3 } from '../styles';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PAGES } from '../../constants/pages';
import { ROLES } from '../../constants/roles';
import format from 'date-fns/format';
import { getManuscriptStatus } from './utils';
import { Filter } from './Filter';
import { useEnhanced } from './useEnhanced';
import { Button } from '../../components/Button/Button';
import { canRemoveManuscript } from '../../utils/accesses';

const STATUSES = {
  completed: 'Completed',
  pending: 'Pending',
};

export const Manuscripts = () => {
  const {
    manuscriptsList,
    isLoading,
    userType,
    showMoreText,
    showMore,
    showShowMoreButton,
    filter,
    noManuscriptsSent,
    noManuscriptsFound,
    onRemoveManuscript,
    isRemoving,
  } = useEnhanced();

  if (isLoading) {
    return null;
  }

  return (
    <Layout>
      <SLayoutInner>
        <SContent>
          <SHeader1>Manuscripts</SHeader1>
          {userType !== ROLES.USER && <Filter filter={filter} />}

          {noManuscriptsSent && <div>There are no manuscripts sent.</div>}
          {!noManuscriptsSent && noManuscriptsFound && <div>There are no manuscripts found.</div>}

          {userType === ROLES.USER && (
            <>
              <br />
              <Link to={'/manuscripts/add'} className="link-alt-2">
                Add Manuscript
              </Link>
              <br />
              <br />
              <br />
              <SDesc>MWRF Logo</SDesc>
              <SDesc>
                <ul>
                  <li>
                    <a href="/i/logo.png">For slide presentations</a> (.png format)
                  </li>
                  <li>
                    <a href="/i/logo.ai">For printing</a> (.ai (Adobe Illustrator) format)
                  </li>
                </ul>
              </SDesc>
              <br />
              <br />
            </>
          )}

          {userType === ROLES.USER && manuscriptsList.length > 0 && <SHeader3>Past Manuscripts Submissions</SHeader3>}

          {manuscriptsList.map((manuscript, i) => {
            const dateFormatted = format(new Date(manuscript.submittedAt), 'PP');
            return (
              <SSubmittedManuscript
                key={manuscript.id}
                to={`${PAGES.MANUSCRIPTS}/${manuscript.id}`}
                className="link-alt-1"
              >
                <div>
                  {i + 1}.{' '}
                  {userType !== ROLES.USER && (
                    <>
                      <SLabel>Awardee:</SLabel> {manuscript?.user?.name}
                      <br />
                    </>
                  )}
                  <SLabel>Title:</SLabel> {manuscript.title}
                  <br />
                  <SLabel>Journal:</SLabel> {manuscript.journalInfo}
                  <br />
                  <SLabel>Submitted:</SLabel> {dateFormatted}
                  <br />
                  <SLabel>Review status:</SLabel> {STATUSES[getManuscriptStatus(manuscript)]}{' '}
                  {userType !== ROLES.USER && manuscript.commentsNumber > 0 && (
                    <div style={{ position: 'absolute', top: 7, right: 36, fontSize: 13 }}>
                      <SLabel>Comments:</SLabel> {manuscript.commentsNumber}
                    </div>
                  )}
                  {canRemoveManuscript(userType, manuscript.administrativeReview, manuscript.scientificReview) && (
                    <SControlRemove
                      onClick={(e) => {
                        e.preventDefault();
                        onRemoveManuscript(manuscript.id);
                      }}
                    />
                  )}
                </div>
              </SSubmittedManuscript>
            );
          })}
          {showShowMoreButton && (
            <SShowMoreWrapper>
              <Button type="button" onClick={showMore} value={`Show ${showMoreText} more`}></Button>
            </SShowMoreWrapper>
          )}
        </SContent>
      </SLayoutInner>
    </Layout>
  );
};

const SSubmittedManuscript = styled(Link)`
  display: block;
  position: relative;
  margin: 0 0 10px -12px;
  padding: 10px 12px;
  border: 1px solid #eee;
  border-radius: 10px;

  &:hover {
    background-color: #fbfbfb;
    text-decoration: none;
    opacity: 1;
  }
`;

const SShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const SLayoutInner = styled.div`
  display: flex;
  justify-content: center;
`;

const SContent = styled.div`
  width: 600px;
  padding-top: 40px;
  margin-bottom: 100px;
`;

const SLabel = styled.span`
  color: #8d8989;
`;

const SDesc = styled.div`
  font-size: 14px;
  color: grey;
  margin-bottom: 7px;
`;

const SControlRemove = styled.span`
  position: absolute;
  color: grey;
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  background: url(/i/close.svg) no-repeat;
  background-size: 16px 16px;
`;
