import React from 'react';
import { SHeader1, SText, SSection } from '../../styles';
import nl2br from 'react-nl2br';

export const AbstractAndSignificance = ({ title, state }) => {
  return (
    <SSection>
      <SHeader1>{title}</SHeader1>
      <SText>{nl2br(state.abstract)}</SText>
    </SSection>
  );
};
