import React, { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../components/Input/v0.3/Input';
import { Button } from '../../components/Button/Button';
import { Radio } from '../../components/Radio/Radio';
import { TFilterFunction, TFilterStatus } from './types';

type TProps = {
  filter: TFilterFunction;
};

export const Filter: FC<TProps> = ({ filter }) => {
  const [status, setStatus] = useState<TFilterStatus>('all');
  const [text, setText] = useState('');

  const setter = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value as TFilterStatus);
  };

  return (
    <SFilterWrapper
      onSubmit={(e) => {
        e.preventDefault();
        filter({ text, status: status });
      }}
    >
      <Input type="text" onChange={setText} value={text} placeholder="PI" />
      <SRadioFilters>
        <div>
          <SFilterItem>
            <Radio<TFilterStatus> label="All" name="filter" value="all" onChange={setter} selectedValue={status} />
          </SFilterItem>
          <SFilterItem>
            <Radio label="Completed" name="filter" value="completed" onChange={setter} selectedValue={status} />
          </SFilterItem>
          <SFilterItem>
            <Radio<TFilterStatus>
              label="Pending"
              name="filter"
              value="pending"
              onChange={setter}
              selectedValue={status}
            />
          </SFilterItem>
        </div>
        <SFilterItem>
          <Radio<TFilterStatus>
            label="Pending by scientific review"
            name="filter"
            value="pendingScientific"
            onChange={setter}
            selectedValue={status}
          />
        </SFilterItem>
        <SFilterItem>
          <Radio<TFilterStatus>
            label="Pending by admin review"
            name="filter"
            value="pendingAdmin"
            onChange={setter}
            selectedValue={status}
          />
        </SFilterItem>
      </SRadioFilters>

      <Button type={'submit'} value={'Filter'} style={{ padding: 2, backgroundColor: 'grey', borderColor: 'grey' }} />
    </SFilterWrapper>
  );
};

const SFilterItem = styled.div`
  display: inline-block;
  margin: 0 20px 8px 0;
`;

const SRadioFilters = styled.div`
  margin-bottom: 15px;
`;

const SFilterWrapper = styled.form`
  margin: 0 0 28px -12px;
  padding: 15px;
  border: 1px dashed #d7d7d7;
`;
