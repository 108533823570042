import React from 'react';
import { SHeader1 } from '../../../styles';
import { PersonItem } from '../../components/PersonItem';
import { GrantsRequestCheckbox } from '../../components/GrantsRequestCheckbox';

export const AdminSection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <PersonItem withBorder={false} {...props} />
      <GrantsRequestCheckbox
        label="The Institution Administrative Official is an authorized individual at the Sponsored Research Office (or equivalent) of the applicant’s organization who oversees grant application submissions. By checking this box, the submitter acknowledges that the institution’s administrative official listed below has reviewed and approved the application prior to submission."
        id="form-checkbox-admin"
        fieldName={'approveAdmin'}
        {...props}
      />
    </section>
  );
};
