import { Input } from '../../../components/Input/v0.3/Input';
import React from 'react';

export const GrantsRequestInput = ({
  state,
  setState,
  fieldName,
  errors,
  isValid,
  as = Input,
  validator = () => {},
  ...props
}) => {
  const Component = as;
  return (
    <Component
      {...props}
      value={state[fieldName]}
      onChange={(val) => {
        setState({
          [fieldName]: val,
        });
      }}
      isValid={isValid === undefined ? !errors[fieldName] : isValid}
      // TODO: do more smart validation than just onBlur
      onBlur={validator}
    />
  );
};
