import React from 'react';
import { MilestoneItem } from './MilestoneItem';
import { SubmittedFile } from '../components/SubmittedFile';
import { SHeader1, SSection } from '../../styles';

export const ProjectMilestones = ({ title, state }) => {
  return (
    <SSection>
      <SHeader1>{title}</SHeader1>
      {state.milestones.map((milestone, index) => {
        return <MilestoneItem key={index} state={milestone} index={index} />;
      })}
      <SubmittedFile state={state} />
    </SSection>
  );
};
