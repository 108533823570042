import { TPatchSection, TReportFile } from '../types';

type TParams = {
  section: TPatchSection;
  reportFile: File | TReportFile | '';
};

export const getFilePatchBody = ({ section, reportFile }: TParams) => {
  return {
    [section]: {
      reportFile,
    },
  };
};
