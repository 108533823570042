import styled from 'styled-components';
import { getFinancialReportCellBgColor } from '../../parts/FinancialExpenditure/utils';
import { TCellType } from '../../types';

export const STextarea = styled.textarea<{ cellType?: TCellType }>`
  width: 100%;
  height: 100%;

  border: none;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: ${getFinancialReportCellBgColor};

  resize: none;

  &:focus {
    background-color: #fff2c6;
    border: none;
    outline: none;
    outline-width: 0;
  }
`;
