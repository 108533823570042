import { ROLES } from '../constants/roles';
import { PAGES } from '../constants/pages';
import { STAGES } from '../constants/stages';
import { STATUSES } from '../pages/Application/constants/statuses';
import { IUser, TReportLevels, TYesOrNo } from '../types';

const ALLOWED_FOR_EVERYONE = [
  PAGES.INDEX,
  PAGES.LOGIN,
  PAGES.PROFILE,
  PAGES.WINNERS,
  PAGES.GRANTS_APPLICATION_REPORTING,
  PAGES.MANUSCRIPTS,
  PAGES.MANUSCRIPT,
];

const ALLOWED_PAGES = {
  [ROLES.SITE_ADMIN]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.GRANTS_APPLICATION,
    PAGES.CHANGE_STAGE,
    PAGES.RECORDS,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
  [ROLES.SUPER_OBSERVER]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.GRANTS_APPLICATION,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
  [ROLES.PROPOSAL_ADMIN]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.RECORDS,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
  [ROLES.OBSERVER]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
  [ROLES.USER]: [...ALLOWED_FOR_EVERYONE, PAGES.GRANTS_APPLICATION, PAGES.PROPOSAL_REPORTING, PAGES.MANUSCRIPTS_ADD],
  [ROLES.MANAGEMENT_COMMITTEE_1]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
  [ROLES.MANAGEMENT_COMMITTEE_2]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
  [ROLES.SCIENTIFIC_BOARD]: [...ALLOWED_FOR_EVERYONE, PAGES.APPLICATIONS, PAGES.APPLICATION, PAGES.SUMMARY],
  [ROLES.LEGAL]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
  [ROLES.FINANCE_APPROVAL]: [
    ...ALLOWED_FOR_EVERYONE,
    PAGES.APPLICATIONS,
    PAGES.APPLICATION,
    PAGES.PROPOSAL_REPORTING,
    PAGES.SUMMARY,
  ],
};

export const whereToRedirectAfterLogin = (user: IUser, stage: STAGES) => {
  if (user.userType === ROLES.USER) {
    return PAGES.GRANTS_APPLICATION;
  } else {
    return PAGES.APPLICATIONS;
  }
};

export const canChangeStatus = (userType: ROLES, stage: STAGES) => {
  return userType === ROLES.PROPOSAL_ADMIN && stage === STAGES.ADMINISTRATIVE_REVIEW;
};

export const canChangeScore = (
  userType: ROLES,
  stage: STAGES,
  proposalState: STATUSES,
  isUserAssigned: boolean,
  isEligibleForFinalScoring = false,
) => {
  return (
    (proposalState === STATUSES.ELIGIBLE &&
      ((userType === ROLES.MANAGEMENT_COMMITTEE_1 && stage === STAGES.MANAGEMENT_COMMITTEE1) ||
        (userType === ROLES.MANAGEMENT_COMMITTEE_2 && stage === STAGES.MANAGEMENT_COMMITTEE2))) ||
    (userType === ROLES.SCIENTIFIC_BOARD && stage === STAGES.SCIENTIFIC_BOARD && isUserAssigned) ||
    (stage === STAGES.FINAL_SCORING &&
      isEligibleForFinalScoring &&
      (userType === ROLES.MANAGEMENT_COMMITTEE_1 ||
        userType === ROLES.MANAGEMENT_COMMITTEE_2 ||
        userType === ROLES.SCIENTIFIC_BOARD))
  );
};

export const canChangeStage = (userType: ROLES) => {
  return userType === ROLES.SITE_ADMIN;
};

export const hasAccessApplicationsLink = (userType: ROLES) => {
  return userType !== ROLES.USER;
};

export const canSeeWinnersLink = (userType: ROLES, stage: STAGES) => {
  return (
    [STAGES.FINAL_SCORING, STAGES.WINNER_SELECTION].includes(stage) &&
    [
      ROLES.MANAGEMENT_COMMITTEE_1,
      ROLES.MANAGEMENT_COMMITTEE_2,
      ROLES.SITE_ADMIN,
      ROLES.PROPOSAL_ADMIN,
      ROLES.SUPER_OBSERVER,
    ].includes(userType)
  );
};

export const canSeeAppLink = (userType: ROLES) => {
  return userType === ROLES.USER;
};

export const canOpenPage = (userType: ROLES, page: PAGES) => {
  return ALLOWED_PAGES[userType].includes(page);
};

export const canChangeBoardMember = (userType: ROLES, stage: STAGES, proposalState: STATUSES) => {
  return (
    userType === ROLES.MANAGEMENT_COMMITTEE_2 &&
    stage === STAGES.MANAGEMENT_COMMITTEE2 &&
    proposalState === STATUSES.ELIGIBLE
  );
};

export const canSeeCommittee1Score = (userType: ROLES, stage: STAGES) => {
  return (
    userType === ROLES.SITE_ADMIN ||
    userType === ROLES.SUPER_OBSERVER ||
    (userType === ROLES.MANAGEMENT_COMMITTEE_1 && stage === STAGES.MANAGEMENT_COMMITTEE1) ||
    (userType === ROLES.PROPOSAL_ADMIN && stage === STAGES.SCIENTIFIC_BOARD) ||
    (userType === ROLES.MANAGEMENT_COMMITTEE_2 && stage === STAGES.SCIENTIFIC_BOARD) ||
    [STAGES.FINAL_SCORING_PREVIEW, STAGES.FINAL_SCORING, STAGES.WINNER_SELECTION].includes(stage)
  );
};

export const canSeeCommittee2Score = (userType: ROLES, stage: STAGES) => {
  return (
    userType === ROLES.SITE_ADMIN ||
    userType === ROLES.SUPER_OBSERVER ||
    userType === ROLES.MANAGEMENT_COMMITTEE_2 ||
    (userType === ROLES.PROPOSAL_ADMIN && (stage === STAGES.SCIENTIFIC_BOARD || stage === STAGES.WINNER_SELECTION)) ||
    (userType === ROLES.MANAGEMENT_COMMITTEE_1 && stage === STAGES.WINNER_SELECTION) ||
    [STAGES.FINAL_SCORING_PREVIEW, STAGES.FINAL_SCORING, STAGES.WINNER_SELECTION].includes(stage)
  );
};

export const canSeeGrantAcceptedButton = (userType: ROLES, stage: STAGES) => {
  return (
    stage === STAGES.WINNER_SELECTION && (userType === ROLES.MANAGEMENT_COMMITTEE_2 || userType === ROLES.SITE_ADMIN)
  );
};

export const canWriteComments = (userType: ROLES, isUserAssigned: boolean, stage: STAGES) => {
  return (
    userType !== ROLES.SCIENTIFIC_BOARD ||
    (userType === ROLES.SCIENTIFIC_BOARD && isUserAssigned) ||
    stage === STAGES.FINAL_SCORING ||
    stage === STAGES.FINAL_SCORING_PREVIEW
  );
};

export const canSeeReportingLink = (userType: ROLES, stage: STAGES) => {
  return stage === STAGES.PROPOSAL_REPORTING;
};

export const canSendReportingBack = (userType: ROLES, reportLevels: TReportLevels) => {
  return (
    (userType === ROLES.PROPOSAL_ADMIN && !reportLevels.adminReviewCompleted && reportLevels.reportSubmittedByPI) ||
    (userType === ROLES.MANAGEMENT_COMMITTEE_1 &&
      !reportLevels.scientificReviewCompleted &&
      reportLevels.adminReviewCompleted) ||
    (userType === ROLES.FINANCE_APPROVAL &&
      !reportLevels.financialReviewCompleted &&
      reportLevels.adminReviewCompleted &&
      reportLevels.scientificReviewCompleted)
  );
};

export const canSubmitReporting = (userType: ROLES, reportLevels: TReportLevels) => {
  const step1 = [ROLES.USER, ROLES.PROPOSAL_ADMIN].includes(userType) && !reportLevels.reportSubmittedByPI;
  const step2 =
    userType === ROLES.PROPOSAL_ADMIN && !reportLevels.adminReviewCompleted && reportLevels.reportSubmittedByPI;

  const step3 =
    userType === ROLES.MANAGEMENT_COMMITTEE_1 &&
    !reportLevels.scientificReviewCompleted &&
    reportLevels.adminReviewCompleted &&
    reportLevels.reportSubmittedByPI;

  const step4 =
    userType === ROLES.FINANCE_APPROVAL &&
    !reportLevels.financialReviewCompleted &&
    reportLevels.scientificReviewCompleted &&
    reportLevels.adminReviewCompleted &&
    reportLevels.reportSubmittedByPI;

  return step1 || step2 || step3 || step4;
};

export const canSeeAndWriteReportingComments = (userType: ROLES) => {
  return userType !== ROLES.USER;
};

export const canSeeAndWriteManuscriptComments = (userType: ROLES) => {
  return userType !== ROLES.USER;
};

export const canSeeNewsfeed = (userType: ROLES) => {
  return userType !== ROLES.USER;
};

export const canRemoveComment = (userType: ROLES) => {
  return [ROLES.PROPOSAL_ADMIN, ROLES.SITE_ADMIN].includes(userType);
};

export const canSeeSummaryInfoInProposalReporting = (userType: ROLES) => {
  return userType !== ROLES.USER;
};

export const canCompleteAdministrativeManuscriptReview = (userType: ROLES) => {
  return [ROLES.PROPOSAL_ADMIN].includes(userType);
};

export const canCompleteScientificManuscriptReview = (userType: ROLES) => {
  return [ROLES.PROPOSAL_ADMIN, ROLES.MANAGEMENT_COMMITTEE_1].includes(userType);
};

export const canRemoveManuscript = (
  userType: ROLES,
  administrativeReviewPassed: TYesOrNo,
  scientificReviewPassed: TYesOrNo,
) => {
  if ([ROLES.PROPOSAL_ADMIN, ROLES.SITE_ADMIN].includes(userType)) {
    return true;
  }

  return userType === ROLES.USER && administrativeReviewPassed !== 'Y' && scientificReviewPassed !== 'Y';
};

export const canEditReportingInitialData = (userType: ROLES) => {
  return [ROLES.PROPOSAL_ADMIN, ROLES.SITE_ADMIN].includes(userType);
};

export const canEditManuscriptDate = (userType: ROLES) => {
  if ([ROLES.PROPOSAL_ADMIN, ROLES.SITE_ADMIN].includes(userType)) {
    return true;
  }

  return false;
};
