import { STEPS } from '../../../../constants/steps';
import { isAnyErrorsInSection } from '../../utils/validation';
import React from 'react';
import { SStepLink, SStepNumber, SSteps, SStepsLink, SStepWrapper } from './styles';

export const Menu = ({ proposalSubmitted, setCurrentStep, isSubmitTouched, currentStep, errors }) => {
  return (
    <SSteps>
      {STEPS &&
        STEPS.map((step, index) => {
          return (
            <div key={index}>
              <SStepWrapper
                onClick={() => {
                  !proposalSubmitted && setCurrentStep(index);
                }}
                selected={proposalSubmitted ? false : currentStep === index}
                hasError={isSubmitTouched ? isAnyErrorsInSection(errors[step.id]) : false}
              >
                <SStepNumber>{index + 1}.</SStepNumber>
                <SStepLink>{step.name}</SStepLink>
              </SStepWrapper>
            </div>
          );
        })}
      <SStepsLink>
        <a href="/storage/mwrf_rfp.pdf" target="_blank">
          Download RFP
        </a>
      </SStepsLink>
    </SSteps>
  );
};
