import styled from 'styled-components';

export const SBlock = styled.div<{ withBorder: boolean }>`
  position: relative;
  border: ${({ withBorder }) => (withBorder ? '1px solid #eee' : 'none')};
  padding: ${({ withBorder }) => (withBorder ? '30px 15px 15px;' : '0')};
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const SSection = styled.section`
  padding-bottom: 60px;
`;

export const SHeader1 = styled.h1<{ withSubheader?: boolean }>`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: ${({ withSubheader }) => (!withSubheader ? '36px' : '5px')};
  padding: 0;
`;

export const SHeader1Subheader = styled.h4`
  margin-bottom: 36px;
  font-size: 16px;
  font-weight: bold;
`;

export const SHeader2 = styled.h2`
  height: 24px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;

  position: absolute;
  background: white;

  top: -10px;
  padding: 0 8px;
  left: 8px;
`;

export const SHeader3 = styled.h3`
  font-weight: bold;
  font-size: 18px;
  opacity: 0.3;
  margin-bottom: 15px;
`;

export const SLabel = styled.label`
  font-size: 16px;
  opacity: 0.5;
`;

export const SText = styled.div`
  padding-bottom: 15px;
`;

export const STextError = styled.p`
  color: #f36242;
  margin-top: 15px;
  font-size: 14px;
`;

export const STextSuccess = styled.p`
  color: #000;
  margin-top: 15px;
  font-size: 14px;
`;

export const SContentLeft = styled.div`
  padding-top: 103px;
`;

export const SContentRight = styled.div`
  @media print {
    width: 100%;
    max-width: 100%;
  }
`;

export const SLine = styled.hr`
  height: 1px;
  border-top: 1px dashed #000000;
  opacity: 0.2;
  margin: -10px 0 30px 0;
  background: none;
`;
