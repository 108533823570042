import { TManuscriptTextFields } from '../../types';

type TTitle = {
  [name in keyof TManuscriptTextFields]: string;
};

export const MANUSCRIPT_TITLES: TTitle = {
  awardee: 'Awardee',
  title: 'Title of manuscript',
  journalInfo: 'Journal to which manuscript will be submitted',
  manuscriptType: 'Type of manuscript (e.g., research article, review article, technical publication)',
  acknowledgement: 'State how support from the Milky Way Research Foundation is acknowledged in manuscript',
  acknowledgementPages: 'Page number(s) of manuscript where the Milky Way Research Foundation is acknowledged',
  supportedByProject: 'Title of MWRF project/award which supported the research for this manuscript',
  userComments: 'Comments regarding this manuscript submission',
};
