import { Link } from 'react-router-dom';
import { SHeader1 } from '../../styles';
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const ReportingLink = ({ href }) => {
  return (
    <SLinkWrap>
      <Link to={href} className="link-alt-1">
        <SLinkContent>
          <SHeader1 style={{ marginBottom: 10 }}>3 Year Reporting</SHeader1>
          Click here to&nbsp;complete and submit the progress report for your Milky Way award.
          <SIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </SIcon>
        </SLinkContent>
      </Link>
    </SLinkWrap>
  );
};

const SLinkWrap = styled.div`
  margin-bottom: 60px;
`;

const SLinkContent = styled.div`
  display: inline-block;
  position: relative;
  width: 80%;
  margin-left: -16px;
  padding: 16px 42px 16px 16px;
  border-radius: 8px;
  background-color: #fff2c6;
`;

const SIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
  font-size: 20px;
`;
