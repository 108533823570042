import React, { useState } from 'react';
import { Popup } from '../../../../components/Popup/Popup';
import { LoginForm } from './LoginForm';

export const LoginPopup = ({ hide }: { hide: () => void }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  if (isLoggedIn && hide) {
    hide();
  }

  return (
    <Popup>
      <LoginForm
        onSuccess={() => {
          setIsLoggedIn(true);
        }}
      />
    </Popup>
  );
};
