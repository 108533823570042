import React, { useEffect, useState } from 'react';
import { httpClient } from '../../utils/httpClient';
import { LoginPopup } from '../../pages/GrantsRequest/features/Login/LoginPopup';

// TODO: add axios interceptor here
export const AuthLoginWrapper: React.FC = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    // Add a response interceptor
    httpClient.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const status = error.response.status;

        if (status === 401 || status === 403) {
          setShowPopup(true);
        }

        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <>
      {children}
      {showPopup && (
        <LoginPopup
          hide={() => {
            setShowPopup(false);
          }}
        />
      )}
    </>
  );
};
