export enum STAGES {
  APPLICATION_CLOSED = 'applicationClosed',
  APPLICATION_OPENED = 'applicationOpened',
  ADMINISTRATIVE_REVIEW = 'administrativeReview',
  MANAGEMENT_COMMITTEE1 = 'managementCommittee1',
  MANAGEMENT_COMMITTEE2 = 'managementCommittee2',
  SCIENTIFIC_BOARD = 'scientificBoard',
  FINAL_SCORING_PREVIEW = 'finalScoringPreview',
  FINAL_SCORING = 'finalScoring',
  WINNER_SELECTION = 'winnerSelection',
  PROPOSAL_REPORTING = 'proposalReporting',
}

export const STAGES_TEXT = {
  [STAGES.APPLICATION_CLOSED]: 'Application Closed',
  [STAGES.APPLICATION_OPENED]: 'Application Opened',
  [STAGES.ADMINISTRATIVE_REVIEW]: 'Administrative Review',
  [STAGES.MANAGEMENT_COMMITTEE1]: 'Management Committee 1',
  [STAGES.MANAGEMENT_COMMITTEE2]: 'Management Committee 2',
  [STAGES.SCIENTIFIC_BOARD]: 'Scientific Board',
  [STAGES.FINAL_SCORING_PREVIEW]: 'Pre Final Scoring',
  [STAGES.FINAL_SCORING]: 'Final Scoring',
  [STAGES.WINNER_SELECTION]: 'Final Scoring Complete',
  [STAGES.PROPOSAL_REPORTING]: 'Reporting',
};
