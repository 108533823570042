import { ProposalTitle } from '../Steps/ProposalTitle';
import { PrincipalInvestigator } from '../Steps/PrincipalInvestigator';
import { InstitutionAdministrativeOfficial } from '../Steps/InstitutionAdministrativeOfficial';
import { AbstractAndSignificance } from '../Steps/AbstractAndSignificance';
import { LaypersonsSummary } from '../Steps/LaypersonsSummary';
import { ProjectDescription } from '../Steps/ProjectDescription';
import { References } from '../Steps/References';
import { ProjectMilestones } from '../Steps/ProjectMilestones';
import { BiographicalSketch } from '../Steps/BiographicalSketch';
import { KeyPersonnelSubmitted } from '../Steps/KeyPersonnelSubmitted';
import { Budget } from '../Steps/Budget';
import { BudgetJustification } from '../Steps/BudgetJustification';
import { AnimalHumanSubjectsResearch } from '../Steps/AnimalHumanSubjectsResearch';

export const SubmittedSteps = [
  {
    name: 'Proposal Title',
    component: ProposalTitle,
    id: 'proposalTitle',
  },
  {
    name: 'Principal Investigator',
    component: PrincipalInvestigator,
    id: 'principalInvestigator',
  },
  {
    component: InstitutionAdministrativeOfficial,
    id: 'admin',
    name: 'Institution Administrative Official',
  },
  {
    component: AbstractAndSignificance,
    id: 'abstract',
    name: 'Abstract and Significance',
  },
  {
    component: LaypersonsSummary,
    id: 'summary',
    name: "Layperson's Summary",
  },
  {
    component: ProjectDescription,
    id: 'project',
    name: 'Project Description',
  },
  {
    component: References,
    id: 'references',
    name: 'References',
  },
  {
    component: ProjectMilestones,
    id: 'milestones',
    name: 'Project Milestones',
  },
  {
    component: BiographicalSketch,
    id: 'biography',
    name: 'Biographical Sketch',
  },
  {
    component: KeyPersonnelSubmitted,
    id: 'keyPersonnel',
    name: 'Key Personnel',
  },
  {
    component: Budget,
    id: 'budget',
    name: 'Budget',
  },
  {
    component: BudgetJustification,
    id: 'budgetJustification',
    name: 'Budget Justification',
  },
  {
    component: AnimalHumanSubjectsResearch,
    id: 'subjectsResearch',
    name: 'Animal and Human Subjects Research',
  },
];
