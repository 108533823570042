import React from 'react';
import { GrantsRequestInputFile } from '../../components/GrantsRequestInputFile';
import { SDescription, SInputTip } from '../../styles';
import { SHeader1 } from '../../../styles';

export const BiographySection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        Provide a 5-page NIH-format biosketch of only the PI.
      </SDescription>
      <GrantsRequestInputFile {...props} accept="application/pdf" />
      <SInputTip style={{ marginTop: 10 }}>
        Limit &ndash; 5 pages, Format: PDF
      </SInputTip>
    </section>
  );
};
