import { SubmittedSteps } from '../constants/submittedSteps';
import React from 'react';
import { SStepLink, SStepNumber, SSteps, SStepWrapper } from '../../GrantsRequest/features/Menu/styles';

export const Menu = () => {
  return (
    <SSteps>
      {SubmittedSteps &&
        SubmittedSteps.map((step, index) => {
          return (
            <div key={index}>
              <SStepWrapper as={'a'} href={`#${step.id}`}>
                <SStepNumber>{index + 1}.</SStepNumber>
                <SStepLink>{step.name}</SStepLink>
              </SStepWrapper>
            </div>
          );
        })}
    </SSteps>
  );
};
