import axios from 'axios';
import { TSummaryItem } from './types';
import { TProposalReportingVersion } from '../ProposalReporting/types';

export const getSummaryRequest = async (version: TProposalReportingVersion): Promise<TSummaryItem[]> => {
  const resp = await axios.get<TSummaryItem[]>(`/api/summary?version=${version}`);
  return resp.data;
};

export const getSummaryItemRequest = async (
  id: string | number,
  version: TProposalReportingVersion,
): Promise<TSummaryItem> => {
  const resp = await axios.get<TSummaryItem>(`/api/summary/${id}?version=${version}`);
  return resp.data;
};
