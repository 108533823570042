import { Draft } from 'immer/src/types/types-external';
import { initialFinancialReportItems, TInitialFinancialReportItems } from '../../../constants/financialReportItems';
import { IFinancialExpenditureReportLineEditable, IFinancialReportStructureEditable } from '../types';
const initialFields = initialFinancialReportItems;

const sumFromTop = (data: IFinancialReportStructureEditable, key: keyof IFinancialExpenditureReportLineEditable) => {
  let sum: number = 0;
  initialFields.forEach((initialField: keyof IFinancialReportStructureEditable) => {
    sum = sum + (Number(data[initialField][key]) || 0);
    // sum = sum + (Number(data?.[initialField]?.[key]) || 0);
  });
  return sum;
};

const getPercentExpended = (item: IFinancialExpenditureReportLineEditable): number | null => {
  if (item.budgetExpenses === null || item.budgetExpenses === undefined) {
    return null;
  }

  const res = (item.budgetExpenses * 100) / item.approvedBudget;

  return Number(Number.parseFloat(res.toString()).toFixed(1));
};

const getVariance = (item: IFinancialExpenditureReportLineEditable) => {
  if (item.budgetExpenses === null || item.budgetExpenses === undefined) {
    return null;
  }

  return item.budgetExpenses - item.approvedBudget;
};

export const calculateAndFillData = (draft: Draft<IFinancialReportStructureEditable>) => {
  (initialFields as TInitialFinancialReportItems[]).forEach((key) => {
    const item = draft[key];
    item.percentExpended = getPercentExpended(item);
    item.variance = getVariance(item);
  });

  draft.directCosts.approvedBudget = sumFromTop(draft, 'approvedBudget');
  draft.directCosts.budgetExpenses = sumFromTop(draft, 'budgetExpenses');
  draft.directCosts.percentExpended = getPercentExpended(draft.directCosts);
  draft.directCosts.variance = getVariance(draft.directCosts);

  draft.indirectCosts.percentExpended = getPercentExpended(draft.indirectCosts);
  draft.indirectCosts.variance = getVariance(draft.indirectCosts);

  draft.total.approvedBudget = (draft.directCosts.approvedBudget || 0) + (draft.indirectCosts.approvedBudget || 0);
  draft.total.budgetExpenses = (draft.directCosts.budgetExpenses || 0) + (draft.indirectCosts.budgetExpenses || 0);
  draft.total.percentExpended = getPercentExpended(draft.total);
  draft.total.variance = getVariance(draft.total);

  return draft;
};
