import qs from 'qs';

const stringifyOptions: qs.IStringifyOptions = {
  arrayFormat: 'brackets',
  encodeValuesOnly: true,
  skipNulls: true,
};

const cleanEmptyStrings = (object = {}) => {
  const formattedObject = {};
  Object.entries(object).forEach((array) => {
    const [key, value] = array;
    formattedObject[key] = value === '' ? undefined : value;
  });
  return formattedObject;
};

export const stringifyQS = (object = {}, options: qs.IStringifyOptions = {}): string => {
  return qs.stringify(cleanEmptyStrings(object), {
    ...stringifyOptions,
    ...options,
  });
};
