import React from 'react';
import { Textarea } from '../../../../components/Textarea/Textarea';
import { GrantsRequestInput } from '../../components/GrantsRequestInput';
import { SDescription } from '../../styles';
import { SHeader1 } from '../../../styles';

export const SummarySection = ({ title, ...props }) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        Provide a Layperson’s Summary of the proposed work. Describe, in simple,
        non-technical terms, the overall goals of the proposed work, the
        potential significance of the results, and the impact of the work on
        advancing the field. The information provided in this summary may be
        made publicly available. Proprietary information should not be included
        in the Layperson’s Summary.
      </SDescription>
      <GrantsRequestInput
        autoFocus
        as={Textarea}
        fieldName={'summary'}
        {...props}
        max={2000}
        advice="2,000 characters (approximately 300 words)"
      />
    </section>
  );
};
