const notEmptyValidator = (val) => {
  return val ? false : true;
};

const fileValidator = (val) => {
  return val ? false : true;
};

const shouldBeTrue = (val) => {
  return val ? false : true;
};

const alwaysValid = () => {
  return false;
};

const emailValidator = (val) => {
  if (val.indexOf('@') > -1) {
    return false;
  } else {
    return true;
  }
};

const specialValidators = {
  animalResearch: alwaysValid,
  animalUseCareCommittees: alwaysValid,
  humanSubjects: alwaysValid,
  approvedByInstitutionalReview: alwaysValid,
  usesEstablishedCellLines: alwaysValid,
  budgetJustificationSubAward: alwaysValid,
  verified: alwaysValid,
  stateId: alwaysValid,
  email: emailValidator,
  file: fileValidator,
  approveAdmin: shouldBeTrue,
};

const getValidator = (stateName) => {
  return specialValidators[stateName] || notEmptyValidator;
};

const getArrItemsErrors = (val, stateName) => {
  const arrItemErrors = [];
  val.forEach((arrItem, index) => {
    arrItemErrors.push({});

    for (let innerArrItemName in arrItem) {
      const validator = getValidator(innerArrItemName);
      const arrItemVal = val[index][innerArrItemName];
      arrItemErrors[index][innerArrItemName] = validator(arrItemVal);
    }
  });

  return arrItemErrors;
};

export const getAllErrors = (state) => {
  const errors = {};

  for (let stepName in state) {
    errors[stepName] = {};
    for (let stateName in state[stepName]) {
      const val = state[stepName][stateName];

      if (Array.isArray(val)) {
        errors[stepName][stateName] = getArrItemsErrors(val, stateName);
      } else {
        const validator = getValidator(stateName);
        errors[stepName][stateName] = validator(val);
      }
    }
  }

  return errors;
};

export const isAnyErrorsInSection = (errStateSlice, debug = false) => {
  let isErrors = false;
  for (let stateItemName in errStateSlice) {
    // if (isErrors) {
    //   return true;
    // }
    // if (stateItemName === 'keyPersonnel') {
    //   continue;
    // }
    const stateSliceItemVal = errStateSlice[stateItemName];
    if (Array.isArray(stateSliceItemVal)) {
      stateSliceItemVal.forEach((item) => {
        if (!isErrors) {
          isErrors = isAnyErrorsInSection(item);
        }
      });
    } else {
      if (stateSliceItemVal) {
        isErrors = true;
      }
    }
  }

  return isErrors;
};

export const isAnyErrorsInWholeForm = (state) => {
  let isErrors = false;
  for (let sliceName in state) {
    if (isErrors) {
      return true;
    } else {
      isErrors = isAnyErrorsInSection(state[sliceName]);
    }
  }

  return isErrors;
};
