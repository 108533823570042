import React from 'react';
import { ReportFile } from '../../components/components/ReportFile/ReportFile';
import { TApplicationId, TChangeGlobalStateCb, TReportFile } from '../../types';
import { useReportingVersion } from '../../../../hooks/useReportingVersion';

type TProps = {
  reportFile: TReportFile;
  changeGlobalState: TChangeGlobalStateCb;
  disabled: boolean;
  applicationId: TApplicationId;
  isSubmitted: boolean;
};
export const Accounting = ({ reportFile, changeGlobalState, disabled = false, applicationId, isSubmitted }: TProps) => {
  const { version } = useReportingVersion();

  return (
    <>
      <div style={{ marginBottom: 18 }}>
        <ReportFile
          required
          reportFile={reportFile}
          changeGlobalState={changeGlobalState}
          section="accountingReport"
          placeholder="Click to upload PDF file. No page limit"
          disabled={disabled}
          applicationId={applicationId}
          isSubmitted={isSubmitted}
        />
      </div>

      <ul className="hint-list">
        <li>
          Provide an&nbsp;institutional accounting report{' '}
          {version === '3' && <span style={{ fontWeight: 'bold' }}>for 2 years of the award </span>}
          {version === '4' && <span style={{ fontWeight: 'bold' }}>for all 3 years of the award </span>}
          for the expense categories in&nbsp;the table above: Salary and fringe, Travel, Equipment, Supplies/Reagents,
          Animals, Other, Sub-award (if&nbsp;applicable), Directs Costs, Indirect Costs, and Total.
        </li>
      </ul>
    </>
  );
};
