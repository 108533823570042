import { STAGES, STAGES_BY_ORDER, STAGES_TEXTS } from '../../Summary/constants';
import { getCellColor, getFormattedCellDate } from '../../Summary/utils';
import React, { useEffect, useState } from 'react';
import { getSummaryItemRequest } from '../../Summary/api';
import { summaryRespMockLite } from '../../Summary/__mocks__/summaryRespMockLite';
import styled from 'styled-components';
import { TSummaryItem } from '../../Summary/types';
import { useReportingVersion } from '../../../hooks/useReportingVersion';

export const SummaryInfo = ({ reportIdFromUrl }: { reportIdFromUrl: string | number }) => {
  const [loading, setLoading] = useState(true);
  const [summaryItem, setSummaryItem] = useState<TSummaryItem>(null);
  const { version } = useReportingVersion();

  useEffect(() => {
    async function getSummaryItem() {
      try {
        const itemResp = await getSummaryItemRequest(reportIdFromUrl, version);
        setSummaryItem(itemResp);
        // setSummaryItem(summaryRespMockLite[0]);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    getSummaryItem();
  }, []);

  return (
    <STable>
      <thead>
        <tr>
          {STAGES_BY_ORDER.map((stageName: STAGES) => (
            <STdScore key={stageName}>{STAGES_TEXTS[stageName]}</STdScore>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <Loading />
        ) : (
          <tr>
            {STAGES_BY_ORDER.map((stage, index) => {
              return (
                <STd
                  key={index}
                  style={{
                    width: '15%',
                    backgroundColor: getCellColor(summaryItem.report, stage),
                  }}
                >
                  {getFormattedCellDate(summaryItem.report, stage)}
                </STd>
              );
            })}
          </tr>
        )}
      </tbody>
    </STable>
  );
};

const Loading = () => (
  <tr>
    <STd
      colSpan={STAGES_BY_ORDER.length}
      style={{
        backgroundColor: '#80808036',
      }}
    >
      Loading...
    </STd>
  </tr>
);

const STable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #212529;
  vertical-align: top;
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
`;

const STd = styled.td<{ textAlign?: 'left' | 'right' }>`
  padding: 0.5rem 0.5rem;
  border: 1px solid #e6e6e6;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
`;

const STh = styled(STd)`
  padding: 0.5rem 0.5rem;
  border: 1px solid #e6e6e6;
  font-weight: bold;
  background-color: #f3f3f3;
`;

const STdScore = styled(STh)`
  width: 7%;
`;
