import React from 'react';
import styled from 'styled-components';

export const PopupContent = ({ title, size = 'm', hide, width, children }) => {
  return (
    <SPopupContent size={size} style={{ width: `${width}` }}>
      {title && (
        <SPopupTitleLine>
          <SPopupHeader>{title}</SPopupHeader>
          {hide && <SPopupCloser size={size} onClick={hide} />}
        </SPopupTitleLine>
      )}

      {children}
    </SPopupContent>
  );
};

PopupContent.displayName = 'PopupContentComponent';

const SPopupCloser = styled.div`
  //position: absolute;
  line-height: 1;
  color: #a3a3a3;
  cursor: pointer;
  font-size: 20px;
  &:after {
    content: '×';
  }
`;

const SPopupContent = styled.div`
  display: inline-block;
  position: relative;
  padding: ${({ size }) => (size === 'm' ? '20px' : '10px 15px 15px 15px')};
  border-radius: 5px;
  background-color: #fff;
  text-align: left;
`;

const SPopupHeader = styled.div`
  margin-bottom: 5px;
  font-size: 15px;
  word-spacing: 2px;
`;

const SPopupTitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  border-bottom: 1px solid #e5e4e4;
`;
