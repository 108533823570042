import React from 'react';
import styled from 'styled-components';
import { FinancialExpenditureInput } from '../FinancialExpenditure/components/FinancialExpenditureInput';
import { getPatchBody } from './utils';
import { patchPersonnelByAdmin, patchProposalReporting } from '../../api';
import { useDictContext } from '../../../../contexts/dictContext';
import { canEditReportingInitialData } from '../../../../utils/accesses';
import { formatNumber } from '../../../../utils/utils';

export const PersonnelReport = ({ data, changeGlobalState, disabled, applicationId, forPrint }) => {
  const [dict] = useDictContext();
  const { userType } = dict.user;
  const canAdminEditInitialData = canEditReportingInitialData(userType);
  //
  // if (!canEditReportingInitialData(userType) && data === undefined) {
  //   return <div>No data provided</div>;
  // }

  return (
    <>
      <STables forPrint={forPrint}>
        <STable>
          <thead>
            <tr>
              <STh>
                Personnel Stated
                <br />
                in Approved Budget
              </STh>
              <STh>Personnel Supported</STh>
            </tr>
          </thead>
          <tbody>
            <tr>
              {canAdminEditInitialData ? (
                <STdWithInput>
                  <FinancialExpenditureInput
                    changeGlobalState={changeGlobalState}
                    value={data.budgetStated}
                    getPatchBody={getPatchBody}
                    patchAjaxFunction={patchPersonnelByAdmin}
                    payload={{
                      field: 'budgetStated',
                    }}
                    disabled={disabled}
                    applicationId={applicationId}
                    cellType="editableByAdmin"
                  />{' '}
                </STdWithInput>
              ) : (
                <STd>{formatNumber(data.budgetStated)}</STd>
              )}

              <STdWithInput>
                <FinancialExpenditureInput
                  changeGlobalState={changeGlobalState}
                  value={data.budgetSupported}
                  getPatchBody={getPatchBody}
                  patchAjaxFunction={patchProposalReporting}
                  payload={{
                    field: 'budgetSupported',
                  }}
                  disabled={disabled}
                  applicationId={applicationId}
                />
              </STdWithInput>
            </tr>
          </tbody>
        </STable>

        <STable>
          <thead>
            <tr>
              <STh>
                Personnel Stated
                <br />
                in Approved Sub-Award Budget
              </STh>
              <STh>Sub-Award Personnel Supported</STh>
            </tr>
          </thead>
          <tbody>
            <tr>
              {canAdminEditInitialData ? (
                <STdWithInput>
                  <FinancialExpenditureInput
                    changeGlobalState={changeGlobalState}
                    value={data.subAwardBudgetStated}
                    getPatchBody={getPatchBody}
                    patchAjaxFunction={patchPersonnelByAdmin}
                    payload={{
                      field: 'subAwardBudgetStated',
                    }}
                    disabled={disabled}
                    applicationId={applicationId}
                    cellType="editableByAdmin"
                  />
                </STdWithInput>
              ) : (
                <STd>{formatNumber(data.subAwardBudgetStated)}</STd>
              )}

              <STdWithInput>
                <FinancialExpenditureInput
                  changeGlobalState={changeGlobalState}
                  value={data.subAwardBudgetSupported}
                  getPatchBody={getPatchBody}
                  patchAjaxFunction={patchProposalReporting}
                  payload={{
                    field: 'subAwardBudgetSupported',
                  }}
                  disabled={disabled}
                  applicationId={applicationId}
                />
              </STdWithInput>
            </tr>
          </tbody>
        </STable>
      </STables>
    </>
  );
};

const STables = styled.div`
  display: flex;
  justify-content: space-between;
`;

const STable = styled.table`
  width: 49%;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #212529;
  vertical-align: top;
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
`;

const STh = styled.th`
  padding: 1rem 1rem;
  border: 1px solid #e6e6e6;
  font-weight: bold;
  text-align: center;
  background-color: #f3f3f3;
`;

const STd = styled.td`
  position: relative;
  padding: 1rem 1rem;
  border: 1px solid #e6e6e6;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  background-color: ${(props) => '#fff'};
`;

const STdWithInput = styled(STd)`
  position: relative;
  padding: 0;
  cursor: pointer;
  height: 40px;
`;
