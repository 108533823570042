import classnames from 'classnames';
import { TProposalReportingVersion } from '../types';

export const getLinkClassByVersion = (
  version: TProposalReportingVersion,
  currentVersion: TProposalReportingVersion,
) => {
  const activeClass = 'link-alt-2 link-alt-2_short link-alt-2_yellow';
  const defaultClass = 'link-alt-1';

  return classnames({ [activeClass]: version === currentVersion, [defaultClass]: version !== currentVersion });
};
