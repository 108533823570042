export const FINANCIAL_REPORT_COLORS = {
  subAward: '#f3f3f3',
  directCosts: '#eefaff',
  indirectCosts: '#eefaff',
  total: '#ddf2fb',
};

export const APPROVED_BUDGET_EDITABLE_COLOR = '#8bad9c5c';

export const REPORT_NAME = {
  1: 'Mid-year',
  2: 'Year 1',
  3: 'Year 2',
  4: 'Year 3',
};
