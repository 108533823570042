import { TManuscript } from '../../types';
import { TFilterStatus, TManuscriptStatus } from './types';

export const getManuscriptStatus = (manuscriptData: TManuscript): TManuscriptStatus => {
  if (manuscriptData.scientificReview === 'Y' && manuscriptData.administrativeReview === 'Y') {
    return 'completed';
  }
  return 'pending';
};

export const getIsManuscriptStatusFitsFilter = (manuscriptData: TManuscript, filterStatus: TFilterStatus): boolean => {
  if (filterStatus === 'pending') {
    return getManuscriptStatus(manuscriptData) === 'pending';
  }
  if (filterStatus === 'completed') {
    return getManuscriptStatus(manuscriptData) === 'completed';
  }

  if (filterStatus === 'pendingScientific') {
    return manuscriptData.scientificReview !== 'Y';
  }

  if (filterStatus === 'pendingAdmin') {
    return manuscriptData.administrativeReview !== 'Y';
  }

  return true;
};
