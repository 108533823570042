import React, { useState } from 'react';
import styled from 'styled-components';

import { SPanelButton, SPanelButtonIcon, SPanelHeader } from '../styles';
import { SButton } from '../../../components/Button/styles';
import { STextError, STextSuccess } from '../../styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useApplicationContext } from '../../../contexts/applicationContext';
import { SCORES_TEXT } from '../constants/SCORES_TEXT';
import nl2br from 'react-nl2br';
import { useDictContext } from '../../../contexts/dictContext';
import { STAGES } from '../../../constants/stages';

export const ScoreSelector = () => {
  const [changeStateError, setChangeStateError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationData, setApplicationData] = useApplicationContext();
  const [{ stage }] = useDictContext();
  const conflicted = applicationData?.score?.[stage]?.conflicted;
  const { id } = useParams();

  const setScore = async (score) => {
    setIsLoading(true);
    try {
      setSuccessMsg(null);
      setChangeStateError(null);

      const changeStatusResp = await axios.post(
        `/api/applications/${id}/change-score`,
        {
          score,
        },
      );

      if (!changeStatusResp.data.state) {
        throw new Error('Bad response from server, sorry');
      }
      const newScore = changeStatusResp.data.score;
      setApplicationData({
        ...applicationData,
        score: newScore,
      });
      setSuccessMsg(
        newScore[stage] ? 'Application scored' : 'Application score removed',
      );
    } catch (e) {
      console.log({ e });
      const message = e?.response?.data?.message;
      setChangeStateError(message || e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const setConflict = async () => {
    setIsLoading(true);
    try {
      setSuccessMsg(null);
      setChangeStateError(null);

      const newConflictedliteral = conflicted ? 'N' : 'Y';
      const newConflictedBoolean = !conflicted;
      const changeStatusResp = await axios.post(
        `/api/applications/${id}/conflicted`,
        {
          conflicted: newConflictedliteral,
        },
      );

      if (!changeStatusResp.data.state) {
        throw new Error('Bad response from server, sorry');
      }
      const newScore = changeStatusResp.data.score;
      setApplicationData({
        ...applicationData,
        score: newScore,
      });
      setSuccessMsg(
        newConflictedBoolean ? 'Conflict registered' : 'Conflict removed',
      );
    } catch (e) {
      console.log({ e });
      const message = e?.response?.data?.message;
      setChangeStateError(message || e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderScoreButton = (score) => {
    return (
      <SPanelButton>
        <SButton
          status={score === 'conflict' ? 'alert' : undefined}
          mode="full"
          type="button"
          size="s"
          disabled={isLoading || conflicted}
          onClick={() => {
            setScore(score);
          }}
          style={{ fontSize: '13px', padding: '5px 10px' }}
        >
          <SScoreButtonInner>
            <SScoreButtonScore>{SCORES_TEXT[score].score}</SScoreButtonScore>
            <SScoreButtonText>
              {nl2br(SCORES_TEXT[score].text)}
            </SScoreButtonText>
            <SScoreButtonIconWrapper>
              {applicationData.score &&
              applicationData.score[stage] &&
              applicationData.score[stage].score === score ? (
                <SScoreButtonIcon />
              ) : (
                ''
              )}
            </SScoreButtonIconWrapper>
          </SScoreButtonInner>
        </SButton>
      </SPanelButton>
    );
  };

  const renderConflictButton = () => {
    return (
      <SPanelButton>
        <SButton
          status={'alert'}
          mode="full"
          type="button"
          size="s"
          disabled={isLoading}
          onClick={() => {
            setConflict();
          }}
          style={{
            fontSize: '13px',
            padding: '13px 10px',
            marginBottom: '15px',
          }}
        >
          <SScoreButtonInner>
            <SConflictButtonText>Conflict</SConflictButtonText>
            <SConflictButtonIconWrapper>
              {conflicted ? <SScoreButtonIcon /> : ''}
            </SConflictButtonIconWrapper>
          </SScoreButtonInner>
        </SButton>
      </SPanelButton>
    );
  };

  return (
    <>
      {stage === STAGES.FINAL_SCORING && renderConflictButton()}
      <div>
        <SPanelHeader>Score</SPanelHeader>
      </div>
      {renderScoreButton(3.0)}
      {renderScoreButton(2.5)}
      {renderScoreButton(2.0)}
      {renderScoreButton(1.5)}
      {renderScoreButton(1.0)}

      {changeStateError && (
        <STextError className="mb-0">{changeStateError}</STextError>
      )}
      {successMsg && <STextSuccess className="mb-0">{successMsg}</STextSuccess>}
    </>
  );
};

const SScoreButtonInner = styled.div`
  display: flex;
  align-items: center;
`;

const SScoreButtonScore = styled.div`
  font-size: 18px;
  min-width: 46px;
  text-align: left;
`;

const SScoreButtonText = styled.div`
  flex: 1;
  text-align: left;
  line-height: 1.3;
`;

const SScoreButtonIconWrapper = styled.div`
  width: 40px;
`;

const SScoreButtonIcon = styled(SPanelButtonIcon)`
  margin-left: 0;
`;

const SConflictButtonText = styled(SScoreButtonText)`
  text-align: center;
`;

const SConflictButtonIconWrapper = styled.div`
  width: 40px;
  width: 40px;
  position: absolute;
  right: 5px;
`;
