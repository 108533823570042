import parse from 'date-fns/parse';
import { DATE_BACKEND_FORMAT } from '../pages/GrantsRequest/constants/dateFormat';
import format from 'date-fns/format';

export const stringToDate = (dateStr) => {
  return dateStr ? parse(dateStr, DATE_BACKEND_FORMAT, new Date()) : null;
};

export const dateToString = (dateObj) => {
  return dateObj ? format(dateObj, DATE_BACKEND_FORMAT) : '';
};
