import React from 'react';
import styled from 'styled-components';
import { SHeader1, SSection } from '../../styles';

export const AnimalHumanSubjectsResearch = ({ title, state }) => {
  return (
    <SSection>
      <SHeader1>{title}</SHeader1>
      <div>
        {state.animalResearch && (
          <SCheckboxListItem>
            This proposal includes animal research
          </SCheckboxListItem>
        )}

        {state.animalUseCareCommittees && (
          <SCheckboxListItem>
            The proposed animal research is currently approved by the
            appropriate Institutional Animal Use and Care Committees
          </SCheckboxListItem>
        )}

        {state.humanSubjects && (
          <SCheckboxListItem>
            This proposal includes human subjects or human tissue/cells
          </SCheckboxListItem>
        )}

        {state.approvedByInstitutionalReview && (
          <SCheckboxListItem>
            The proposed human or human tissue research is currently approved by
            the appropriate Institutional Review Board
          </SCheckboxListItem>
        )}

        {state.usesEstablishedCellLines && (
          <SCheckboxListItem>
            This proposal uses established cell lines
          </SCheckboxListItem>
        )}

        {state.verified && (
          <SCheckboxListItem>
            The proposed cell lines have been validated/verified
          </SCheckboxListItem>
        )}
      </div>
    </SSection>
  );
};

const SCheckboxListItem = styled.div`
  margin-bottom: 11px;
  position: relative;
  padding-left: 15px;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 6px;
    border-color: transparent transparent transparent #000;
  }
`;
