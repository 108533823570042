import React from 'react';
import { SubmittedFile } from '../components/SubmittedFile';
import {
  SBudgetBlock,
  SBudgetYearValues,
  SBudgetYearValue,
} from '../../GrantsRequest/styles';

import { SBlock, SHeader1, SHeader3, SLabel, SSection } from '../../styles';
import { formatNumber } from '../../../utils/utils';

export const Budget = ({ title, state }) => {
  const y1Total = Number(state.y1DirectCost) + Number(state.y1IndirectCost);
  const y2Total = Number(state.y2DirectCost) + Number(state.y2IndirectCost);
  const y3Total = Number(state.y3DirectCost) + Number(state.y3IndirectCost);

  // In case user did not fill proper numbers
  const sumTotal = (y1Total || 0) + (y2Total || 0) + (y3Total || 0);

  return (
    <SSection>
      <SHeader1>{title}</SHeader1>

      <SBlock>
        <SBudgetBlock>
          <SHeader3>Year 1</SHeader3>
          <SBudgetYearValues>
            <div className="row">
              <div className="col-4">
                <SLabel>Direct Cost, $</SLabel>
                <SBudgetYearValue>
                  {formatNumber(state.y1DirectCost)}
                </SBudgetYearValue>
              </div>
              <div className="col-8">
                <SLabel>Indirect Cost, $</SLabel>
                <SBudgetYearValue>
                  {formatNumber(state.y1IndirectCost)}
                </SBudgetYearValue>
              </div>
            </div>
          </SBudgetYearValues>
        </SBudgetBlock>

        <SBudgetBlock>
          <SHeader3>Year 2</SHeader3>
          <SBudgetYearValues>
            <div className="row">
              <div className="col-4">
                <SLabel>Direct Cost, $</SLabel>
                <SBudgetYearValue>
                  {formatNumber(state.y2DirectCost)}
                </SBudgetYearValue>
              </div>
              <div className="col-8">
                <SLabel>Indirect Cost, $</SLabel>
                <SBudgetYearValue>
                  {formatNumber(state.y2IndirectCost)}
                </SBudgetYearValue>
              </div>
            </div>
          </SBudgetYearValues>
        </SBudgetBlock>

        <SBudgetBlock>
          <SHeader3>Year 3</SHeader3>
          <SBudgetYearValues>
            <div className="row">
              <div className="col-4">
                <SLabel>Direct Cost, $</SLabel>
                <SBudgetYearValue>
                  {formatNumber(state.y3DirectCost)}
                </SBudgetYearValue>
              </div>
              <div className="col-8">
                <SLabel>Indirect Cost, $</SLabel>
                <SBudgetYearValue>
                  {formatNumber(state.y3IndirectCost)}
                </SBudgetYearValue>
              </div>
            </div>
          </SBudgetYearValues>
        </SBudgetBlock>

        <SBudgetBlock>
          <SHeader3>Total 3-Year Budget, $</SHeader3>
          <SBudgetYearValue>{formatNumber(sumTotal)}</SBudgetYearValue>
        </SBudgetBlock>

        <div>
          <SubmittedFile state={state} />
        </div>
      </SBlock>
    </SSection>
  );
};
