import React from 'react';
import { SubmittedFile } from '../components/SubmittedFile';
import { SHeader1, SSection } from '../../styles';

export const References = ({ title, state }) => {
  return (
    <SSection>
      <SHeader1>{title}</SHeader1>
      <SubmittedFile state={state} />
    </SSection>
  );
};
