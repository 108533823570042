import React from 'react';
import { SDescription, SInputTip } from '../../styles';
import { SHeader1 } from '../../../styles';
import { MilestoneItem } from './MilestoneItem';
import { ItemMultiplier } from '../../components/ItemMultiplier';
import { GrantsRequestInputFile } from '../../components/GrantsRequestInputFile';

export const milestonesInitialState = {
  date: '',
  milestoneOutline: '',
  deliverable: '',
};

export const MilestonesSection = ({
  title,
  state,
  setState,
  errors,
  validator,
}) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <SDescription className="decor-alt-1">
        State the proposed major milestones and deliverables for the study.
        Technical project milestones should be specific and feasible. Adherence
        to milestones will be a key aspect during award administration.
      </SDescription>
      <ItemMultiplier
        data={state.milestones}
        setState={setState}
        errors={errors.milestones}
        validator={validator}
        fieldName={'milestones'}
        ItemComponent={MilestoneItem}
        newItemInitialState={milestonesInitialState}
      />
      <br />
      <br />
      <div className="decor-alt-1">
        Please also provide these proposed major milestones as a single
        document. The milestones may be presented in a tabular, graphical,
        and/or chart summary or may simply be listed as stated above.
      </div>
      <br />
      <GrantsRequestInputFile
        state={state}
        setState={setState}
        errors={errors}
        validator={validator}
        accept="application/pdf"
      />
      <SInputTip style={{ marginTop: 10 }}>
        Limit &ndash; 1 page, Format: PDF
      </SInputTip>
    </section>
  );
};
