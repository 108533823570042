import React from 'react';
import { SButton } from './styles';
import { IButtonProps, TMode, TSize, TStatus } from './types';

interface IButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size?: TSize;
  mode?: TMode;
  status?: TStatus;
}

export const Button: React.FC<IButtonProps> = ({
  value = 'Save',
  size = 'm',
  mode,
  status,
  type = 'button',
  ...props
}) => {
  return (
    <SButton size={size} mode={mode} status={status} type={type} {...props}>
      {value}
    </SButton>
  );
};
