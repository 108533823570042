import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { ROLES } from '../../constants/roles';
import { getTextScore } from '../../utils/utils';

export const RecordsPage = () => {
  const [appsData, setAppsData] = useState([]);
  const [juryData, setJuryData] = useState([]);
  let urlGetJury = '/api/users?';
  urlGetJury += `filters[userType][]=${ROLES.MANAGEMENT_COMMITTEE_1}`;
  urlGetJury += `&filters[userType][]=${ROLES.MANAGEMENT_COMMITTEE_2}`;
  urlGetJury += `&filters[userType][]=${ROLES.SCIENTIFIC_BOARD}`;

  useEffect(() => {
    axios.get(urlGetJury).then((response) => {
      setJuryData(response.data);
    });
    axios.get('/api/records').then((response) => {
      setAppsData(response.data);
    });
  }, []);

  const getStatus = (userId, records) => {
    let status = '';
    const record = records.filter((record) => record.userId === userId)[0];
    if (record) {
      if (record.conflicted === 'Y') {
        status = 'conflict';
      } else if (record.score !== null) {
        status = 'voted';
      }
    }
    return status;
  };

  const getScore = (userId, records) => {
    const score = records.filter((record) => record.userId === userId)[0]
      ?.score;
    return getTextScore(score);
  };

  return (
    <Layout>
      {appsData.length > 0 && juryData.length > 0 && (
        <STable>
          <thead>
            <tr>
              <STd>Application</STd>
              {juryData.map((user) => (
                <STdScore key={user.id}>{user.name}</STdScore>
              ))}
              <STdScore>Average</STdScore>
              <STdScore>Total</STdScore>
            </tr>
          </thead>
          <tbody>
            {appsData.map((appData, i) => (
              <tr key={i}>
                <STd textAlign="left">
                  <div>
                    <div>
                      <Link
                        to={`/applications/${appData.id}`}
                        className="link-alt-1"
                      >
                        #{appData.id}.{' '}
                        {`${appData.proposal?.principalInvestigator?.firstName} ${appData.proposal?.principalInvestigator?.lastName}`}
                      </Link>
                    </div>
                    {appData.proposal?.principalInvestigator?.institution}
                  </div>
                </STd>
                {juryData.map((user) => (
                  <STd
                    status={getStatus(user.id, appData.proposal.records)}
                    key={user.id}
                  >
                    {getScore(user.id, appData.proposal.records)}
                  </STd>
                ))}
                <STd>{appData.proposal.average}</STd>
                <STd>{appData.proposal.total}</STd>
              </tr>
            ))}
          </tbody>
        </STable>
      )}
    </Layout>
  );
};

const colors = {
  voted: '#dffbe0',
  conflict: '#f6f6f6',
};

const STable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #212529;
  vertical-align: top;
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
`;

const STd = styled.td`
  padding: 0.5rem 0.5rem;
  border: 1px solid #e6e6e6;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  background-color: ${(props) => colors[props.status] || '#fff'};
`;

const STdScore = styled(STd)`
  width: 7%;
`;
