import axios from 'axios';
import { ROLES } from '../../constants/roles';
import { TManuscript } from '../../types';

export const getManuscriptsListRequest = async (userType: ROLES): Promise<TManuscript[]> => {
  if (userType === ROLES.USER) {
    const settingsResp = await axios.get<TManuscript[]>(`/api/manuscript/my-list`);

    return settingsResp.data;
  }

  if ([ROLES.SITE_ADMIN, ROLES.PROPOSAL_ADMIN, ROLES.MANAGEMENT_COMMITTEE_1].includes(userType)) {
    const settingsResp = await axios.get<TManuscript[]>(`/api/manuscript/list`);

    return settingsResp.data;
  }

  return [];
};
