import React, { useEffect } from 'react';
import { Layout } from '../../components/Layout/Layout';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { SContentLeft, SContentRight } from '../styles';
import { Sidebar } from './components/Sidebar';
import { Comments } from './features/Comments/Comments';
import { useApplicationContext } from '../../contexts/applicationContext';
import { useDictContext } from '../../contexts/dictContext';
import { SubmittedApplicationView } from './SubmittedApplicationView';
import { STAGES } from '../../constants/stages';
import { SNavPrev } from '../GrantsRequest/features/Nav/styles';
import { SubHeader } from '../../components/Header/SubHeader';
import { SLeft, SRight } from '../../components/Header/styles';
import { ReportVersionsSwitcher } from '../ProposalReporting/components/ReportVersionsSwitcher';
import { hasAccessApplicationsLink } from '../../utils/accesses';

export const Application = ({ mode }: { mode?: 'pi' | undefined }) => {
  if (mode === 'pi') {
    return <ApplicationForPi />;
  } else {
    return <ApplicationForOthers />;
  }
};

const ApplicationForPi = () => {
  const [{ stage, proposal, user }] = useDictContext();

  return <ApplicationItself mode={'pi'} stage={stage} proposal={proposal} id={user.id} />;
};

const ApplicationForOthers = () => {
  const [applicationData, setApplicationData] = useApplicationContext();
  const [{ stage }] = useDictContext();
  const { id } = useParams();

  useEffect(() => {
    axios.get(`/api/applications/${id}`).then((response) => {
      setApplicationData(response.data);
    });
  }, []);

  if (applicationData === null) {
    return null;
  }

  return <ApplicationItself stage={stage} proposal={applicationData.proposal} id={id} />;
};

type TApplicationProps = { mode?: 'pi' | undefined; stage: STAGES; id: string | number; proposal: any };

const ApplicationItself: React.FC<TApplicationProps> = ({ mode, stage, proposal, id }) => {
  const [dict] = useDictContext();

  return (
    <Layout
      subHeader={
        <SubHeader
          firstLine={
            <>
              <SLeft>
                {hasAccessApplicationsLink(dict.user.userType) && (
                  <Link to={`/applications#${id}`}>
                    <SNavPrev>Applications</SNavPrev>
                  </Link>
                )}
              </SLeft>
              <SRight>
                <ReportVersionsSwitcher mode={mode} id={id} />
              </SRight>
            </>
          }
        ></SubHeader>
      }
    >
      <div className="row">
        <SContentLeft className="col-4 p-0 d-print-none">
          <Sidebar mode={mode} />
        </SContentLeft>
        <SContentRight className="col-8 d-print-block" style={{ paddingTop: 22 }}>
          <SubmittedApplicationView mode={mode} stage={stage} proposal={proposal} id={id} />
          {mode !== 'pi' && (
            <div id="comments">
              <Comments />
            </div>
          )}
        </SContentRight>
      </div>
    </Layout>
  );
};
