import React from 'react';
import { SFileWrapper, SFileLink } from '../styles';

export const SubmittedFile = ({ state }) => {
  const { file } = state;

  return (
    <SFileWrapper>
      {file ? (
        <SFileLink
          target={'_blank'}
          href={`/api/grants-application/download-file/${file.id}`}
          download
        >
          {file.fNameOriginal}
        </SFileLink>
      ) : (
        <>No uploaded file</>
      )}
    </SFileWrapper>
  );
};
