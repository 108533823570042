import React, { forwardRef, useCallback, useState } from 'react';
import { Input } from '../../Input/v0.3/Input';

export const CustomInput = forwardRef(
  (
    {
      onFocus,
      onBlur,
      placeholder: passedPlaceholder,
      focusedPlaceholder,
      onClean = null,
      onChange,
      light,
      isValid,
      ...props
    },
    ref,
  ) => {
    const [placeholder, setPlaceholder] = useState(passedPlaceholder);

    const onInputChangeCb = useCallback(
      (str, event) => {
        onChange(event);
      },
      [onChange],
    );

    return (
      <Input
        onFocus={(e) => {
          onFocus(e);
          if (focusedPlaceholder) setPlaceholder(focusedPlaceholder);
        }}
        onBlur={(e) => {
          if (focusedPlaceholder) setPlaceholder(passedPlaceholder);
          onBlur(e);
        }}
        placeholder={placeholder}
        onClean={onClean}
        onChange={onInputChangeCb}
        style={{
          width: '150px',
        }}
        light={light}
        ref={ref}
        isValid={isValid}
        {...props}
      />
    );
  },
);
