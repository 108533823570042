import { mergeMilestones } from './mergeMilestones';
import { mergeFinancial } from './mergeFinancial';
import { calculateAndFillData } from './calculateAndFillData';
import { IFinancialExpenditureReport, IMilestonesReport, IPersonnelReport, IProposalReporting, TPatch } from '../types';
import { isSliceWithFile } from './typeGuards';

// TODO: кажется, эта функция вообще не нужна. Лучше хранить все состояния в своих компонентах, например стейт для milestonesAndDeliverable
// хранить именно в компоненте milestonesAndDeliverable. Делать initial state на основе данных с бэка, а потом все изменения хранить уже внутри маленьких компонентов.
// Т.к. мы отправляем только инкрементальные изменения (после каждого действия пользователя мы сохраняем изменения на бэке), то общий стейт нам и не нужен.
/**
 * TODO:  Подумать на тему переноса этой логики внутрь компонентов
 */
export const changeSliceOfState = (
  draft: IProposalReporting,
  slice: TPatch<IFinancialExpenditureReport | IMilestonesReport | IPersonnelReport>,
) => {
  const keyToMerge = Object.keys(slice)[0] as keyof IProposalReporting;

  // если мы меняем файл - то структура во всех случаях у нас заложена одинаковая, так что не паримся и просто мерджим
  // @ts-ignore
  if (isSliceWithFile(slice[keyToMerge])) {
    // @ts-ignore
    draft[keyToMerge].reportFile = slice[keyToMerge].reportFile;
    return draft;
  }

  // milestonesAndDeliverable - особый случай как говорится, там надо мерджить хитро. Я думал создать универсальную функцию, чтобы
  //   мерджила что угодно, но подумал что будет запутанно. Лучше для каждого случая пусть будет своя.
  if (keyToMerge === 'milestonesAndDeliverable') {
    // @ts-ignore
    mergeMilestones(draft.milestonesAndDeliverable, slice.milestonesAndDeliverable);

    return draft;
  }

  if (keyToMerge === 'financialExpenditureReport') {
    // @ts-ignore
    mergeFinancial(draft.financialExpenditureReport, slice.financialExpenditureReport);
    calculateAndFillData(draft.financialExpenditureReport.data);
    return draft;
  }

  // @ts-ignore
  draft[keyToMerge].data = {
    // @ts-ignore
    ...draft[keyToMerge].data,
    // @ts-ignore
    ...slice[keyToMerge].data,
  };
  return draft;
};
