import React, { useState } from 'react';
import { getPatchBody } from '../utils';
import { patchProposalReporting } from '../../../api';
import {
  TApplicationId,
  TChangeGlobalStateCb,
  TMilestoneDeliverable,
  TMilestoneOutline,
  TMilestonePart,
} from '../../../types';
import { useReportingVersion } from '../../../../../hooks/useReportingVersion';

type TProps = {
  value: string;
  changeGlobalState: TChangeGlobalStateCb;
  children: React.ReactNode;
  payload: {
    id: number;
    part: TMilestonePart;
    field: keyof TMilestoneDeliverable | keyof TMilestoneOutline;
  };
  disabled: boolean;
  applicationId: TApplicationId;
};

export const MilestonesAndDeliverableSelect: React.FC<TProps> = ({
  value,
  changeGlobalState,
  children,
  payload,
  disabled,
  applicationId,
}) => {
  const [innerValue, setInnerValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const { part, id, field } = payload;
  const [error, setError] = useState(null);
  const { version } = useReportingVersion();

  const onChangeInner: React.ReactEventHandler = async (e) => {
    const newValue = (e.target as HTMLInputElement).value;
    setInnerValue(newValue);

    // TODO: make universal hook.
    try {
      const patchBody = getPatchBody({ id, part, field, value: newValue });
      setError(null);
      setLoading(true);
      await patchProposalReporting(applicationId, patchBody, version);
      setLoading(false);
      changeGlobalState(patchBody);
    } catch (e) {
      setInnerValue(value);
      setLoading(false);
      setError(e.message);
      console.error({ e });
    }
  };

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <select disabled={disabled} value={innerValue} onChange={onChangeInner} style={{ width: 155 }}>
        {children}
      </select>
      {error && <div>{error}</div>}
    </>
  );
};
