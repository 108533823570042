import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout/Layout';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { convertSQlDateToObj, getTextScore } from '../../utils/utils';
import { STATUSES } from '../Application/constants/statuses';
import { cut } from './utils/cut';
import { useDictContext } from '../../contexts/dictContext';
import { STAGES } from '../../constants/stages';
import { ROLES } from '../../constants/roles';
import { canSeeCommittee1Score, canSeeCommittee2Score } from '../../utils/accesses';
import { SPanelButtonIcon } from '../Application/styles';
import { Tabs } from './components/Tabs';

const getInitialTab = (userType, stage) => {
  if ([STAGES.FINAL_SCORING_PREVIEW, STAGES.FINAL_SCORING, STAGES.WINNER_SELECTION].includes(stage)) {
    return STATUSES.ELIGIBLE_FINAL_SCORING;
  } else {
    if (userType === ROLES.MANAGEMENT_COMMITTEE_1 || userType === ROLES.MANAGEMENT_COMMITTEE_2) {
      return STATUSES.ELIGIBLE;
    } else if (userType === ROLES.SCIENTIFIC_BOARD) {
      return 'review';
    }
    return 'all';
  }
};

export const ApplicationsPage = () => {
  const [originalAppsData, setOriginalAppsData] = useState([]);
  const [appsData, setAppsData] = useState([]);
  const [{ user, stage }] = useDictContext();
  const [currentTab, setCurrentTab] = useState(getInitialTab(user.userType, stage));

  const getFilteredData = ({ tabName }) => {
    if (tabName === 'all') {
      return originalAppsData;
    }

    if ([STAGES.FINAL_SCORING_PREVIEW, STAGES.FINAL_SCORING, STAGES.WINNER_SELECTION].includes(stage)) {
      return originalAppsData.filter(
        (appDataItem) => appDataItem.selectedForFinalScoring === (tabName === STATUSES.ELIGIBLE_FINAL_SCORING),
      );
    } else {
      return originalAppsData.filter((appDataItem) => appDataItem.proposalState === tabName);
    }
  };

  useEffect(() => {
    axios.get('/api/applications').then((response) => {
      setOriginalAppsData(response.data);
    });
  }, []);

  useEffect(() => {
    setAppsData(
      getFilteredData({
        tabName: currentTab,
      }),
    );
  }, [currentTab, originalAppsData]);

  return (
    <Layout loading={originalAppsData.length === 0}>
      <SAppsFilter>
        <Tabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          getFilteredData={getFilteredData}
          stage={stage}
          user={user}
        />
      </SAppsFilter>
      <div>{appsData.length > 0 && <ApplicationsList appsData={appsData} stage={stage} user={user} />}</div>
    </Layout>
  );
};

const ApplicationsList = ({ appsData, stage, user }) => {
  useEffect(() => {
    const idToScroll = document.location.hash.replace('#', '');
    const titleElement = document.getElementById(idToScroll);
    titleElement && titleElement.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return appsData.map((appData, i) => {
    const { proposal } = appData;
    const { principalInvestigator, proposalTitle } = proposal;
    const score = appData.score;

    return (
      <Link
        key={appData.id}
        to={`/applications/${appData.id}`}
        className="link-alt-1 list-apps-item"
        style={{ display: 'block', position: 'relative' }}
      >
        <div id={`id${appData.id}`} style={{ position: 'absolute', top: -95 }}></div>
        <SAppLink>
          <SApp stage={stage} appData={appData}>
            <div>
              <span>#{appData.id}. </span>
              <SAppPI>{`${principalInvestigator.firstName} ${principalInvestigator.lastName}`}</SAppPI>
              {principalInvestigator.institution}
            </div>
            <SAppProposal title={proposalTitle.proposalTitle}>{cut(proposalTitle.proposalTitle, 70)}</SAppProposal>
            {appData.proposalSubmittedAt && (
              <SAppDate>{format(convertSQlDateToObj(appData.proposalSubmittedAt), 'PP')}</SAppDate>
            )}
            {score && (
              <div>
                {/* Committee 1 Scores */}
                {score[STAGES.MANAGEMENT_COMMITTEE1] && (
                  <SScoredBlock>
                    <SScoredBlockName>{score[STAGES.MANAGEMENT_COMMITTEE1]?.author?.name}</SScoredBlockName>
                    <SScoredBlockScore>
                      scored{' '}
                      {canSeeCommittee1Score(user.userType, stage)
                        ? getTextScore(score[STAGES.MANAGEMENT_COMMITTEE1].score)
                        : ''}
                    </SScoredBlockScore>
                  </SScoredBlock>
                )}

                {/* Committee 2 Scores */}
                {score[STAGES.MANAGEMENT_COMMITTEE2] && (
                  <SScoredBlock>
                    <SScoredBlockName>{score[STAGES.MANAGEMENT_COMMITTEE2]?.author?.name}</SScoredBlockName>
                    <SScoredBlockScore>
                      scored{' '}
                      {canSeeCommittee2Score(user.userType, stage)
                        ? getTextScore(score[STAGES.MANAGEMENT_COMMITTEE2].score)
                        : ''}
                      {appData.scientificMember && <span> and assigned {appData.scientificMember.name}</span>}
                    </SScoredBlockScore>
                  </SScoredBlock>
                )}

                {/* Scientific Board Scores */}
                {score[STAGES.SCIENTIFIC_BOARD] &&
                  ([STAGES.FINAL_SCORING_PREVIEW, STAGES.FINAL_SCORING, STAGES.WINNER_SELECTION].includes(stage) ||
                    user.name === score[STAGES.SCIENTIFIC_BOARD]?.author?.name) && (
                    <SScoredBlock>
                      <SScoredBlockName>{score[STAGES.SCIENTIFIC_BOARD]?.author?.name}</SScoredBlockName>
                      <SScoredBlockScore>scored {getTextScore(score[STAGES.SCIENTIFIC_BOARD].score)}</SScoredBlockScore>
                    </SScoredBlock>
                  )}
              </div>
            )}
          </SApp>
        </SAppLink>
      </Link>
    );
  });
};

const SAppLink = styled.div`
  padding: 7px 9px;
  border-radius: 7px;

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
  }
`;

const borderColors = {
  undecided: '#e6e6e6',
  eligible: '#0CAF4D',
  ineligible: '#F36242',
};

const getBorderStyle = (props) => {
  let borderStyle;
  if ([STAGES.FINAL_SCORING_PREVIEW, STAGES.FINAL_SCORING, STAGES.WINNER_SELECTION].includes(props.stage)) {
    borderStyle = 'none';
    if (props?.appData?.score?.finalScoring) {
      if (props?.appData?.score?.finalScoring?.conflicted) {
        borderStyle = '5px solid #e6e6e6';
      }
      if (props?.appData?.score?.finalScoring?.score > 0) {
        borderStyle = '5px solid #0CAF4D';
      }
    }
  } else {
    borderStyle = `5px solid ${borderColors[props?.appData?.proposalState]}`;
  }
  return borderStyle;
};

const SAppsFilter = styled.div`
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  border-bottom: 1px solid #e6e6e6;
`;

const SApp = styled.div`
  padding-left: 10px;
  border-left: ${(props) => getBorderStyle(props)};
`;

const SAppPI = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
`;

const SAppProposal = styled.div`
  margin: 8px 0 2px;
`;

const SAppDate = styled.div`
  opacity: 0.5;
`;

const SScoredBlock = styled.span`
  padding: 4.5px 8px;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 8px;
`;

const SScoredBlockName = styled.span`
  opacity: 0.5;
  margin-right: 4px;
  text-decoration: none;
`;

const SScoredBlockScore = styled.span`
  text-decoration: none;
`;

const SScoreButtonIcon = styled(SPanelButtonIcon)`
  position: relative;
  margin: 0 0 0 9px;
  top: 3px;
`;
