import styled from 'styled-components';
import React from 'react';
import { allUiFinancialReportItems, financialReportItems } from '../../../../constants/financialReportItems';
import { ReportFile } from '../../components/components/ReportFile/ReportFile';
import { FinancialExpenditureInput } from './components/FinancialExpenditureInput';
import { FinancialExpenditureTextarea } from './components/FinancialExpenditureTextarea';
import { getPatchBody } from './utils';
import { patchProposalReporting, patchProposalReportingApprovedBudget } from '../../api';
import { formatNumber } from '../../../../utils/utils';
import { FINANCIAL_REPORT_COLORS } from '../../constants';
import {
  IFinancialReportStructureEditable,
  TApplicationId,
  TCellType,
  TChangeGlobalStateCb,
  TReportFile,
} from '../../types';
import { canEditReportingInitialData } from '../../../../utils/accesses';
import { useDictContext } from '../../../../contexts/dictContext';

type TProps = {
  data: IFinancialReportStructureEditable | undefined;
  reportFile: TReportFile;
  changeGlobalState: TChangeGlobalStateCb;
  disabled: boolean;
  applicationId: TApplicationId;
  isSubmitted: boolean;
  forPrint: boolean;
};

export const FinancialExpenditureReport: React.FC<TProps> = ({
  data,
  reportFile,
  changeGlobalState,
  disabled = false,
  applicationId,
  isSubmitted,
  forPrint,
}) => {
  const [dict] = useDictContext();
  const { userType } = dict.user;

  if (!canEditReportingInitialData(userType) && data === undefined) {
    return <div>No data provided</div>;
  }

  return (
    <>
      <STable>
        <thead>
          <tr>
            <STh style={{ width: '10%' }}>Categories</STh>
            <STh style={{ width: '10%' }}>
              Approved Budget
              <br />
              (USD, $)
            </STh>
            <STh style={{ width: '10%' }}>
              Actual Budget Expenses
              <br />
              (USD, $)
            </STh>
            <STh style={{ width: '10%' }}>% Budget Expended</STh>
            <STh style={{ width: '10%' }}>
              Budget Remaining/ Overspent
              <br />
              (USD, $)
            </STh>
            <STh style={{ width: '10%' }}>% Budget Remaining/ Overspent</STh>
            <STh>Explanation if % Budget Remaining/Overspent &gt;±5%</STh>
          </tr>
        </thead>
        <tbody>
          {allUiFinancialReportItems.map((type: TCellType) => {
            const item = data[type];

            return (
              <tr key={type}>
                {/* Categories */}
                <STd textAlign="left" type={type}>
                  {financialReportItems[type]}
                </STd>

                {/* Approved Budget */}
                {/*<STd textAlign="right" type={type}>*/}
                {/*{formatNumber(item.approvedBudget)}*/}
                {['directCosts', 'total'].includes(type) || !canEditReportingInitialData(userType) ? (
                  <STd type={type}>{formatNumber(item.approvedBudget)}</STd>
                ) : (
                  <STdWithInput textAlign="left">
                    <FinancialExpenditureInput
                      changeGlobalState={changeGlobalState}
                      value={item.approvedBudget}
                      payload={{
                        part: type,
                        field: 'approvedBudget',
                      }}
                      patchAjaxFunction={patchProposalReportingApprovedBudget}
                      getPatchBody={getPatchBody}
                      disabled={disabled}
                      cellType="editableByAdmin"
                      applicationId={applicationId}
                    />
                  </STdWithInput>
                )}

                {/* Budget expenses*/}
                {['directCosts', 'total'].includes(type) ? (
                  <STd type={type}>{formatNumber(item.budgetExpenses)}</STd>
                ) : (
                  <STdWithInput textAlign="left">
                    <FinancialExpenditureInput
                      changeGlobalState={changeGlobalState}
                      value={item.budgetExpenses}
                      payload={{
                        part: type,
                        field: 'budgetExpenses',
                      }}
                      patchAjaxFunction={patchProposalReporting}
                      getPatchBody={getPatchBody}
                      disabled={disabled}
                      cellType={type}
                      applicationId={applicationId}
                    />
                  </STdWithInput>
                )}

                {/*% Budget Expended*/}
                <STd type={type}>
                  {isNaN(item.percentExpended) || !isFinite(item.percentExpended)
                    ? ''
                    : formatNumber(item.percentExpended)}
                </STd>

                {/* Variance - Budget Remaining/Overspent */}
                <STd type={type}>{isNaN(item.variance) ? '' : formatNumber(item.variance)}</STd>

                {/* % Budget Remaining/Overspent */}
                <STd type={type}>
                  {isNaN(item.percentExpended) || !isFinite(item.percentExpended) || item.percentExpended === null
                    ? ''
                    : formatNumber(item.percentExpended - 100, { fractionLength: 1 })}
                </STd>

                {/* Explanation if variance >±5% */}
                {['directCosts', 'total'].includes(type) ? (
                  <STd type={type} />
                ) : (
                  <STdWithInput textAlign="left">
                    <FinancialExpenditureTextarea
                      changeGlobalState={changeGlobalState}
                      value={item.comment}
                      payload={{
                        part: type,
                        field: 'comment',
                      }}
                      disabled={disabled}
                      cellType={type}
                      applicationId={applicationId}
                      forPrint={forPrint}
                    />
                  </STdWithInput>
                )}
              </tr>
            );
          })}
        </tbody>
      </STable>
      <div>
        <ReportFile
          reportFile={reportFile}
          changeGlobalState={changeGlobalState}
          section={'financialExpenditureReport'}
          placeholder="Click to upload PDF file. 2-page limit"
          disabled={disabled}
          applicationId={applicationId}
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  );
};

const STable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #212529;
  vertical-align: top;
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
`;

const STh = styled.th`
  padding: 2rem 1rem;
  border: 1px solid #e6e6e6;
  font-weight: bold;
  text-align: center;
  background-color: #f3f3f3;
`;

const STd = styled.td<{ textAlign?: string; type?: TCellType }>`
  padding: 2rem 1rem;
  border: 1px solid #e6e6e6;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  background-color: ${(props) =>
    props.type ? FINANCIAL_REPORT_COLORS[props.type as keyof typeof FINANCIAL_REPORT_COLORS] : '#fff'};
`;

const STdWithInput = styled(STd)`
  position: relative;
  padding: 0;
  cursor: pointer;
`;
