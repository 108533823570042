export type TInitialFinancialReportItems =
  | 'salary'
  | 'travel'
  | 'equipment'
  | 'suppliersReagents'
  | 'animals'
  | 'other'
  | 'subAward'
  | 'indirectCosts'
  | 'directCosts'
  | 'total';

export const financialReportItems = {
  salary: 'Salary',
  travel: 'Travel',
  equipment: 'Equipment',
  suppliersReagents: 'Supplies/Reagents',
  animals: 'Animals',
  other: 'Other',
  subAward: 'Sub-Award',
  indirectCosts: 'Indirect Costs',
  directCosts: 'Direct Costs',
  total: 'Total',
};

export const initialFinancialReportItems = [
  'salary',
  'travel',
  'equipment',
  'suppliersReagents',
  'animals',
  'other',
  'subAward',
];
export const allUiFinancialReportItems = [...initialFinancialReportItems, 'directCosts', 'indirectCosts', 'total'];
export const allEditableFinancialReportItems = [...initialFinancialReportItems, 'indirectCosts'];
