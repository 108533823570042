import React, { FC } from 'react';
import styled from 'styled-components';
import { useDictContext } from '../../contexts/dictContext';
import {
  canSeeAppLink,
  hasAccessApplicationsLink,
  canChangeStage,
  canOpenPage,
  canSeeWinnersLink,
} from '../../utils/accesses';
import { PAGES } from '../../constants/pages';
import { CustomLink } from '../CustomLink/CustomLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

type TProps = {
  subHeader?: React.ReactElement;
};
export const Header: FC<TProps> = ({ subHeader }) => {
  const [dict] = useDictContext();
  const hasUserName = dict.user && dict.user.name && dict.user.name.length > 0;

  return (
    <>
      <SHeader className="row d-flex align-items-center" withSubheader={!!subHeader}>
        <SLogo className="col-4">
          <img src="/i/logo.svg" alt="Milky Way Research Foundation Logo" />
        </SLogo>
        <SMenuContainer className="col-8 d-print-none">
          <SMenu>
            <CustomLink to={PAGES.MANUSCRIPTS} className="link-alt-1">
              Manuscripts
            </CustomLink>
            <SSeparator />

            {canSeeAppLink(dict.user.userType) && (
              <>
                <CustomLink to="/grants-application" className="link-alt-1">
                  Application
                </CustomLink>
                <SSeparator />
              </>
            )}

            {canSeeWinnersLink(dict.user.userType, dict.stage) && (
              <>
                <CustomLink to="/winners" className="link-alt-1">
                  Final Score
                </CustomLink>
                <SSeparator />
              </>
            )}

            {canOpenPage(dict.user.userType, PAGES.RECORDS) && (
              <>
                <CustomLink to={PAGES.RECORDS} className="link-alt-1">
                  Records
                </CustomLink>
                <SSeparator />
              </>
            )}

            {canOpenPage(dict.user.userType, PAGES.SUMMARY) && (
              <>
                <CustomLink to={PAGES.SUMMARY} className="link-alt-1">
                  Summary
                </CustomLink>
                <SSeparator />
              </>
            )}

            {hasAccessApplicationsLink(dict.user.userType) && (
              <>
                <CustomLink to={PAGES.APPLICATIONS} className="link-alt-1">
                  Applications
                </CustomLink>
                <SSeparator />
              </>
            )}

            <SDropdownMenu>
              <SUsernameAndIcon>
                <SUsername> {hasUserName ? dict.user.name : dict.user.email} </SUsername>
                <FontAwesomeIcon icon={faChevronDown} color="grey" />
              </SUsernameAndIcon>

              <SBurgerMenu>
                {canChangeStage(dict.user.userType) && (
                  <div>
                    <CustomLink to="/change-stage" className="link-alt-1">
                      Change Stage
                    </CustomLink>
                  </div>
                )}

                <div>
                  <CustomLink to="/profile" className="link-alt-1">
                    Change Password
                  </CustomLink>
                </div>

                <div>
                  <a href="/logout" className="link-alt-1">
                    Log Out
                  </a>
                </div>
              </SBurgerMenu>
            </SDropdownMenu>
          </SMenu>
        </SMenuContainer>
      </SHeader>
      {subHeader && (
        <SSubHeader className="row d-flex">
          <SNavigation className={'d-print-none'}>{subHeader}</SNavigation>
        </SSubHeader>
      )}
    </>
  );
};

const SNavigation = styled.div`
  padding: 5px 0 5px 0;
`;

const SUsername = styled.span`
  margin-right: 10px;
`;

const SUsernameAndIcon = styled.div`
  display: inline-flex;
  align-items: center;
`;

const SSubHeader = styled.div``;

const SHeader = styled.header<{ withSubheader: boolean }>`
  padding: 10px 0 20px;
  padding-bottom: ${(props) => (props.withSubheader ? 0 : '20px')};
`;

const SLogo = styled.div``;

const SMenu = styled.span`
  display: inline-block;
  background: #fbfbfb;
  padding: 8px 18px;
  border-radius: 8px;
  font-size: 14px;
`;

const SSeparator = styled.span`
  opacity: 0.2;
  display: inline-block;
  padding: 0 8px;

  &:after {
    content: '|';
  }
`;

const SMenuContainer = styled.div`
  text-align: right;
`;

const SBurgerMenu = styled.div`
  display: none;
  position: absolute;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  top: -16px;
  right: 0;
  width: 150px;
`;

const SDropdownMenu = styled.div`
  text-align: right;
  display: inline-flex;
  align-items: center;

  position: relative;

  &:hover ${SBurgerMenu} {
    color: #fff;
    display: block;
  }
`;
