import { useParams, useSearchParams } from 'react-router-dom';
import { useDictContext } from '../../../contexts/dictContext';
import { useImmer } from 'use-immer';
import { IProposalReportingResponse, TChangeGlobalStateCb, TProposalReportingMode, TRemoveCommentCb } from '../types';
import { useSetState } from 'react-use';
import { TSendBackState } from '../components/SendBackForm/types';
import { getInitialData, sendReportBackRequest } from '../api';
import { useEffect, useState } from 'react';
import { changeSliceOfState } from '../utils/changeSliceOfState';
import { validateProposalReporting } from '../utils/validateProposalReporting';
import { httpClient } from '../../../utils/httpClient';
import jsonToFormData from 'json-form-data';
import { ROLES } from '../../../constants/roles';
import { TReportLevels } from '../../../types';
import { canSendReportingBack } from '../../../utils/accesses';
import { useReportingVersion } from '../../../hooks/useReportingVersion';

const getIsFormEditEnabled = (userType: ROLES, reportLevels: TReportLevels): boolean => {
  if (userType === ROLES.USER && !reportLevels.reportSubmittedByPI) {
    return true;
  }

  if (userType === ROLES.PROPOSAL_ADMIN && !reportLevels.adminReviewCompleted) {
    return true;
  }

  if (userType === ROLES.SITE_ADMIN) {
    return true;
  }

  return false;
};

export const useEnhanced = (mode: TProposalReportingMode) => {
  const { id: reportIdFromUrl } = useParams();
  const [dict] = useDictContext();
  const [proposalReporting, setProposalReporting] = useImmer<IProposalReportingResponse>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getReportingId = (): string | number => {
    return mode === 'pi' ? dict.id : reportIdFromUrl;
  };
  let [searchParams] = useSearchParams();
  const { version } = useReportingVersion();
  // Когда мы просто опираемся на  version, то при смене версий страница все же рендерится с данными,
  // предназначенными для другого типа репорта. Это плохо, т.к.
  // 1) мелькает старые значения
  // 2) страница падает, если встречает данные, которые не предназначены для текущего типа репорта.
  // Поэтому приходится дополнительно держать версию репорта еще и в стейте activeVersion,
  // чтобы точно удостовериться что мы записали данные для нового репорта
  // Проблема описана Петей:
  //    начал смотреть проект, первый вопрос, почему-то сразу не открывается ссылка на новый год
  //    первый раз - пустой белый экран, нажимаю F5 и все перегружается
  //    в консоли не делаются запросы на клик
  // Кейс исследовался "в ваккуме" вот здесь - https://stackblitz.com/edit/vitejs-vite-ztqhkv
  const [activeVersion, setActiveVersion] = useState(version);
  const print = searchParams.get('print');

  const [state, setState] = useSetState({
    canEditForm: false,
    isSubmitting: false,
    showThankYou: false,
    validationError: '',
  });

  const [sendBackState, setSandBackState] = useSetState<TSendBackState>({
    isSendingBack: false,
    showSentBack: false,
    comment: '',
    errorText: '',
    attachment: null,
  });

  const loadReportData = async () => {
    setProposalReporting(null);

    const data = await getInitialData(getReportingId(), version);

    setProposalReporting(data);
    setState({
      canEditForm: getIsFormEditEnabled(dict.user.userType, data.reportLevels),
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setActiveVersion(version);
    (async () => {
      try {
        await loadReportData();
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }

      if (print) {
        window.print();
      }
    })();
  }, [version]);

  const changeGlobalState: TChangeGlobalStateCb = (slice) => {
    setProposalReporting((draft) => {
      return changeSliceOfState(draft, slice);
    });
  };

  const onSubmit = async (userType) => {
    if ([ROLES.USER, ROLES.PROPOSAL_ADMIN].includes(userType)) {
      const validationError = validateProposalReporting(proposalReporting, version);
      if (validationError !== '') {
        setState({ validationError });
        return;
      }
    }

    try {
      setState({ isSubmitting: true, validationError: '' });

      const baseUrl = `/api/applications/${getReportingId()}/proposal-reporting/`;

      if (getIsSubmitOnBehalfMode()) {
        await httpClient.post(`${baseUrl}submit-on-behalf?version=${version}`);
      } else {
        await httpClient.post(`${baseUrl}submit?version=${version}`);
      }

      const reportData = await loadReportData();
      setState({ showThankYou: true });
    } catch (e) {
      console.error(e);
    } finally {
      setState({ isSubmitting: false });
    }
  };

  const onSendBack = async () => {
    if (sendBackState.comment === '') {
      window.alert('Please fill in some comment');
      return;
    }

    try {
      setSandBackState({ isSendingBack: true });

      const newCommentFormData = jsonToFormData({
        text: sendBackState.comment,
        attachment: sendBackState.attachment,
      });

      await sendReportBackRequest(getReportingId(), newCommentFormData, version);

      setSandBackState({
        comment: '',
        attachment: null,
        errorText: '',
        showSentBack: true,
      });

      await loadReportData();
    } catch (e) {
      setSandBackState({ errorText: 'Error: ' + e.response.data.message });
    } finally {
      setSandBackState({ isSendingBack: false });
    }
  };

  const onRemoveComment: TRemoveCommentCb = async (commentId: number) => {
    try {
      await httpClient.delete(
        `/api/applications/${getReportingId()}/proposal-reporting/delete-comment/${commentId}?version=${version}`,
      );
      await loadReportData();
    } catch (e) {
      alert('Sorry, an error occurred while deleting comment');
      console.error(e);
    }
  };

  const isSubmittedByPI = proposalReporting?.reportLevels?.reportSubmittedByPI || false;
  const canSendBack = proposalReporting
    ? canSendReportingBack(dict.user.userType, proposalReporting.reportLevels)
    : false;

  const getIsSubmitOnBehalfMode = () => {
    const userType: ROLES = dict.user.userType;

    return userType === ROLES.PROPOSAL_ADMIN && !isSubmittedByPI;
  };

  const getSubmitButtonText = () => {
    if (getIsSubmitOnBehalfMode()) {
      return 'Submit on behalf of Awardee';
    }
    const userType: ROLES = dict.user.userType;

    if (userType === ROLES.PROPOSAL_ADMIN) {
      return 'Forward for Scientific Review';
    } else if (userType === ROLES.MANAGEMENT_COMMITTEE_1) {
      return 'Forward for Financial Review';
    } else if (userType === ROLES.FINANCE_APPROVAL) {
      return 'Approve Report and Payment';
    }

    return 'Submit';
  };

  return {
    proposalReporting,
    setProposalReporting,
    isLoading: isLoading || activeVersion !== version,
    dict,
    state,
    onSubmit,
    getReportingId,
    changeGlobalState,
    setSandBackState,
    sendBackState,
    onSendBack,
    onRemoveComment,
    print,
    submitButtonText: getSubmitButtonText(),
    canSendBack,
    isSubmittedByPI,
    reportIdFromUrl,
    version,
  };
};
