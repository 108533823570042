import React from 'react';
import { Header } from '../../components/Header/Header';
import './../../css/grid.css';
import styled from 'styled-components';
import { useGrantRequest } from './hooks/useGrantsRequest';
import { Menu } from './features/Menu/Menu';
import { Nav } from './features/Nav/Nav';
import { MainContent } from './features/MainContent/MainContent';
import { SContentLeft, SContentRight, SHeader1 } from '../styles';
import { useDictContext } from '../../contexts/dictContext';
import { STAGES } from '../../constants/stages';
import { Application } from '../Application/Application';

const GrantsApplicationForm = ({
  setState,
  isSaving,
  ComponentSection,
  submit,
  save,
  getSaveButtonText,
  getSaveButtonStatus,
  getSubmitButtonText,
  getSubmitButtonStatus,
  setCurrentStep,
  currentStep,
  stepId,
  isSubmitTouched,
  errors,
  stepTitle,
  stateSlice,
  validator,
  errorsSlice,
  isSubmitError,
  isSubmitting,
  serverMessage,
  proposalSubmitted,
}) => {
  return (
    <>
      <SContentLeft className="col-4">
        <Menu
          setCurrentStep={setCurrentStep}
          isSubmitTouched={isSubmitTouched}
          currentStep={currentStep}
          errors={errors}
          proposalSubmitted={proposalSubmitted}
        />
      </SContentLeft>
      <SContentRight className="col-8">
        <>
          <Nav currentStep={currentStep} setCurrentStep={setCurrentStep} />
          <main style={{ paddingTop: 30 }}>
            <MainContent
              setState={setState}
              isSaving={isSaving}
              ComponentSection={ComponentSection}
              submit={submit}
              save={save}
              getSaveButtonText={getSaveButtonText}
              getSaveButtonStatus={getSaveButtonStatus}
              getSubmitButtonText={getSubmitButtonText}
              getSubmitButtonStatus={getSubmitButtonStatus}
              stepId={stepId}
              isSubmitTouched={isSubmitTouched}
              errors={errors}
              stepTitle={stepTitle}
              stateSlice={stateSlice}
              validator={validator}
              errorsSlice={errorsSlice}
              isSubmitError={isSubmitError}
              isSubmitting={isSubmitting}
              serverMessage={serverMessage}
            />
          </main>
        </>
      </SContentRight>{' '}
    </>
  );
};

const GrantsApplicationLayout = ({ children }) => {
  return (
    <div className="container layout">
      <div className="row">
        <div className="col-xl-10 offset-xl-1">
          <Header />
          <div className="row">{children}</div>
          <footer></footer>
        </div>
      </div>
    </div>
  );
};

export const GrantsApplication = () => {
  const props = useGrantRequest();
  const [dict] = useDictContext();

  if (props.proposalSubmitted) {
    return <Application mode={'pi'} />;
  }

  const renderContent = () => {
    if (props.proposalSubmitted) {
      return (
        <SProposalSubmitted>
          <SHeader1>Thank you for your submission to the Milky Way Research Foundation.</SHeader1>
          <p>
            A confirmation email will also be sent to the PI.
            <br />
            If you do not receive a confirmation, please email the Foundation at Questions@MilkyWayRF.org.
          </p>
        </SProposalSubmitted>
      );
    }

    if (dict.stage !== STAGES.APPLICATION_OPENED) {
      return (
        <SProposalSubmitted>
          <SHeader1>Application submission period is over.</SHeader1>
        </SProposalSubmitted>
      );
    }

    return <GrantsApplicationForm {...props} />;
  };

  return <GrantsApplicationLayout>{renderContent()}</GrantsApplicationLayout>;
};

const SProposalSubmitted = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  top: 42%;
  text-align: center;
`;
