import React, { FC, useState } from 'react';
import styled from 'styled-components';
import jsonToFormData from 'json-form-data';
import { Textarea } from '../../Textarea/Textarea';
import { InputFileLocal } from '../../InputFileLocal/InputFileLocal';
import { SButton } from '../../Button/styles';
import { STextError } from '../../../pages/styles';
import { TOnAddComment } from '../types';

type TProps = {
  onAdd: TOnAddComment;
};

export const CommentForm: FC<TProps> = ({ onAdd }) => {
  const [newComment, setNewComment] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const postComment = async () => {
    if (newComment === '') {
      return;
    }

    setIsLoading(true);
    const newCommentFormData = jsonToFormData({
      text: newComment,
      attachments: attachments,
    });

    try {
      await onAdd(newCommentFormData);

      setNewComment('');
      setAttachments([]);
      setErrorText('');
    } catch (error) {
      setErrorText('Error: ' + error.response.data.message);
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Textarea
        value={newComment}
        onChange={(comment: string) => {
          setNewComment(comment);
        }}
        disabled={isLoading}
      />

      <InputFileLocal
        multiple
        disabled={false}
        value={attachments}
        onChange={(f) => {
          setAttachments(f);
        }}
        placeholder="Click to upload files"
      />

      <br />
      <br />
      <SPostButtonWrapper>
        <SButton
          mode="full"
          type="button"
          size="m"
          disabled={isLoading}
          onClick={() => {
            postComment();
          }}
        >
          {isLoading ? 'Adding...' : 'Add comment'}
        </SButton>
      </SPostButtonWrapper>

      {errorText !== '' && <STextError>{errorText}</STextError>}
    </>
  );
};

const SPostButtonWrapper = styled.div``;
