import React from 'react';
import { GrantsRequestInput } from './GrantsRequestInput';
import { SControlRemove, SControlRemoveWrapper, SPersonInfo } from '../styles';
import { SBlock, SHeader2, SHeader3, SLabel } from '../../styles';
import { GrantsRequestSelect } from './GrantsRequestSelect';
import { useDictContext } from '../../../contexts/dictContext';

export const personInitialState = {
  title: '',
  firstName: '',
  lastName: '',
  institution: '',
  email: '',
  address: '',
  city: '',
  countryId: '',
  stateId: '',
  zipCode: '',
  phone: '',
};

export const PersonItem = ({
  onDelete,
  titleBlock,
  index,
  withBorder = true,
  ...commonProps
}) => {
  const [dict] = useDictContext();

  return (
    <SBlock withBorder={withBorder}>
      {titleBlock && (
        <SHeader2>
          {titleBlock} #{index + 1}
        </SHeader2>
      )}

      <SPersonInfo>
        {commonProps.state.position !== undefined && (
          <div>
            <SLabel htmlFor="">Role on Project</SLabel>
            <GrantsRequestInput fieldName={'position'} {...commonProps} />
          </div>
        )}
        <div>
          <SLabel htmlFor="">Title</SLabel>
          <GrantsRequestInput fieldName={'title'} {...commonProps} />
        </div>
        <div>
          <SLabel htmlFor="">First Name</SLabel>
          <GrantsRequestInput fieldName={'firstName'} {...commonProps} />
        </div>
        <div>
          <SLabel htmlFor="">Last Name</SLabel>
          <GrantsRequestInput fieldName={'lastName'} {...commonProps} />
        </div>
        <div>
          <SLabel htmlFor="">Institution</SLabel>
          <GrantsRequestInput fieldName={'institution'} {...commonProps} />
        </div>
      </SPersonInfo>

      <fieldset>
        <SHeader3>Contact Information</SHeader3>
        <div>
          <SLabel htmlFor="">Email</SLabel>
          <GrantsRequestInput fieldName={'email'} {...commonProps} />
        </div>
        <div>
          <SLabel htmlFor="">Address</SLabel>
          <GrantsRequestInput fieldName={'address'} {...commonProps} />
        </div>
        <div>
          <SLabel htmlFor="">City</SLabel>
          <GrantsRequestInput fieldName={'city'} {...commonProps} />
        </div>
        <div>
          <SLabel htmlFor="">State</SLabel>
          <GrantsRequestSelect
            fieldName={'stateId'}
            {...commonProps}
            options={dict.states}
          />
        </div>
        <div>
          <SLabel htmlFor="">Zip Code</SLabel>
          <GrantsRequestInput fieldName={'zipCode'} {...commonProps} />
        </div>
        <div>
          <SLabel htmlFor="">Country</SLabel>
          <GrantsRequestSelect
            fieldName={'countryId'}
            {...commonProps}
            options={dict.countries}
          />
        </div>
        <div>
          <SLabel htmlFor="">Phone</SLabel>
          <GrantsRequestInput fieldName={'phone'} {...commonProps} />
        </div>
      </fieldset>

      <SControlRemoveWrapper>
        {onDelete && <SControlRemove onClick={onDelete}>Delete</SControlRemove>}
      </SControlRemoveWrapper>
    </SBlock>
  );
};
