import React, { InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';

interface IInputFileLocal extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  onChange: (newFiles: FileList) => void;
  single?: boolean;
  required?: boolean;
}

export const HtmlInput = ({ onChange, placeholder, required = false, ...props }: IInputFileLocal) => {
  const inputRef = useRef(null);
  return (
    <SLabel isValid>
      <input
        ref={inputRef}
        {...props}
        type="file"
        onChange={(event) => {
          onChange(event.target.files);

          inputRef.current.value = ''; // reset inner Input's files (event.target.files)
          // TODO: find out why there is a race condition and when we uncomment this, we check old state...
          // setTimeout(() => {
          //   validator();
          // }, 1000);
        }}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          cursor: 'pointer',
          top: 0,
          left: 0,
          opacity: 0,
        }}
      />
      <div>{placeholder}</div>
      {required === false && <SOptional> (Optional)</SOptional>}
    </SLabel>
  );
};

const SLabel = styled.label<{ isValid?: boolean }>`
  position: relative;
  background-color: #fafafa;
  border-radius: 2px;
  text-align: center;
  padding: 18px 130px;

  border: ${({ isValid }) => (isValid ? '1px dashed #d9d9d9' : '1px dashed #F36242')};
`;

const SOptional = styled.div`
  color: red;
  font-size: 14px;
  color: #000000;
  opacity: 0.5;
  pointer-events: none;
`;
