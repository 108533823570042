import React from 'react';
import { SDescription } from '../../styles';
import { SHeader1 } from '../../../styles';
import { personInitialState, PersonItem } from '../../components/PersonItem';
import { ItemMultiplier } from '../../components/ItemMultiplier';

export const keyPersonnelInitialState = {
  position: '',
  ...personInitialState,
};

export const KeyPersonnelSection = ({
  title,
  state,
  setState,
  errors,
  validator,
}) => {
  return (
    <section>
      <SHeader1>{title}</SHeader1>
      <div className="decor-alt-1">
        <SDescription className="decor-alt-1">
          State the key personnel, their proposed role on the project (e.g.,
          Co-PI, Co-Investigator, Collaborator), and their institutional
          affiliation and contact information. Please do not include lab
          managers, postdoctoral researchers, and/or students on this page.
        </SDescription>
      </div>
      <ItemMultiplier
        data={state.keyPersonnel}
        setState={setState}
        validator={validator}
        fieldName={'keyPersonnel'}
        newItemInitialState={keyPersonnelInitialState}
        ItemComponent={PersonItem}
        title="Key Personnel"
        limit={10}
        allowRemoveSingle={true}
        errors={errors.keyPersonnel}
      />
    </section>
  );
};
