import React, { InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import { TReportFile } from '../../../types';

interface IInputFileLocal extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  value: (File | TReportFile)[];
  onChange: (newFiles: (File | TReportFile)[] | FileList) => void;
  single?: boolean;
}

export const HtmlInput = ({ value, single, onChange, placeholder, ...props }: IInputFileLocal) => {
  const inputRef = useRef(null);
  return (
    <SLabel isValid>
      <input
        ref={inputRef}
        {...props}
        type="file"
        onChange={(event) => {
          if (single) {
            onChange(event.target.files);
          } else {
            onChange([...value, ...Array.from(event.target.files)]);
          }

          inputRef.current.value = ''; // reset inner Input's files (event.target.files)
        }}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          cursor: 'pointer',
          top: 0,
          left: 0,
          opacity: 0,
        }}
      />
      {placeholder}
    </SLabel>
  );
};

const SLabel = styled.label<{ isValid?: boolean }>`
  position: relative;
  background-color: #fafafa;
  border-radius: 2px;
  text-align: center;
  padding: 18px 130px;

  border: ${({ isValid }) => (isValid ? '1px dashed #d9d9d9' : '1px dashed #F36242')};
`;
