import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { STAGES } from '../../constants/stages';
import { SHeader1 } from '../styles';
import { useDictContext } from '../../contexts/dictContext';
import { ROLES } from '../../constants/roles';

export const WinnersPage = () => {
  const [winners, setWinners] = useState([]);
  const [dict] = useDictContext();
  const canSeeWinners =
    [ROLES.SITE_ADMIN, ROLES.PROPOSAL_ADMIN, ROLES.SUPER_OBSERVER].includes(dict.user.userType) ||
    ([ROLES.MANAGEMENT_COMMITTEE_1, ROLES.MANAGEMENT_COMMITTEE_2].includes(dict.user.userType) &&
      dict.stage === STAGES.WINNER_SELECTION);

  useEffect(() => {
    axios.get('/api/winners').then((response) => {
      setWinners([...response.data]);
    });
  }, []);

  return (
    <Layout>
      {canSeeWinners ? (
        winners.map((winner, i) => {
          const { principalInvestigator, avgScore } = winner.proposal;

          return (
            <Link
              key={winner.id}
              to={`/applications/${winner.id}`}
              className="link-alt-1 list-apps-item"
              style={{ display: 'block' }}
            >
              <SAppLink>
                <div>
                  <div style={{ position: 'relative' }}>
                    <span
                      style={{
                        position: 'absolute',
                        left: '-31px',
                        top: '3px',
                        color: '#c1bdbd',
                        fontSize: '14px',
                      }}
                    >
                      {i + 1}
                    </span>
                    <span>#{winner.id}. </span>
                    <SAppPI>{`${principalInvestigator.firstName} ${principalInvestigator.lastName}`}</SAppPI>
                    {principalInvestigator.institution}
                  </div>
                  <SScoredBlocks>
                    <SScoredBlock>
                      <SScoredBlockScore>Average: {avgScore}</SScoredBlockScore>
                    </SScoredBlock>
                  </SScoredBlocks>
                </div>
              </SAppLink>
            </Link>
          );
        })
      ) : (
        <div
          style={{
            position: 'fixed',
            left: 0,
            width: '100%',
            top: '42%',
            textAlign: 'center',
          }}
        >
          <SHeader1>Final score is not available now.</SHeader1>
        </div>
      )}
    </Layout>
  );
};

const SAppLink = styled.div`
  position: relative;
  padding: 7px 130px 7px 9px;
  border-radius: 7px;

  &:hover {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
  }
`;

const SAppPI = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
`;

const SScoredBlocks = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
`;

const SScoredBlock = styled.span`
  display: inline-block;
  padding: 4.5px 8px;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-right: 8px;
  min-width: 100px;
`;

const SScoredBlockScore = styled.span`
  text-decoration: none;
`;
