import { financialReportItems } from '../../../constants/financialReportItems';
import { IFinancialReportStructureEditable } from '../types';

// TODO: we should get these data from back-end, this is temporary hack. Descuss with Peter
export const fillEmptyFinancialReportWithZeroesIfNeeded = (data) => {
  if (!data) {
    data = {};
  }

  Object.keys(financialReportItems).forEach((initialField: keyof IFinancialReportStructureEditable) => {
    data[initialField] = data[initialField] || {};
  });

  Object.keys(data).forEach((item) => {
    data[item].approvedBudget = data[item].approvedBudget || 0;
  });

  return data;
};
