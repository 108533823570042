import React, { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { PAGES } from '../../constants/pages';

type TProps = { to: PAGES | string; className?: string };

export const CustomLink: FC<TProps> = ({ to, children, ...props }) => {
  let match = useMatch(to);

  // reload if we try to open the page we are currently on
  return match ? (
    <a href={to} {...props}>
      {children}
    </a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};
