import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { LoginPage } from './pages/Login/Login';
import { ProfilePage } from './pages/Profile/Profile';
import { ApplicationsPage } from './pages/Applications/Applications';
import { GrantsApplication } from './pages/GrantsRequest/GrantsRequest';
import { DictContextProvider, useDictContext } from './contexts/dictContext';
import { ApplicationContextProvider } from './contexts/applicationContext';
import './css/grid.css';
import './css/index.css';
import { useEffectOnce } from 'react-use';
import axios from 'axios';
import { Application } from './pages/Application/Application';
import { ChangeStage } from './pages/ChangeStage/ChangeStage';
import { PAGES } from './constants/pages';
import { canOpenPage } from './utils/accesses';
import { RecordsPage } from './pages/Records/Records';
import { WinnersPage } from './pages/Winners/Winners';
import { ProposalReportingPage } from './pages/ProposalReporting/ProposalReporting';
import { AuthLoginWrapper } from './components/AuthLoginWrapper/AuthLoginWrapper';
import { SummaryPage } from './pages/Summary/Summary';
import { NewManuscript } from './pages/NewManuscript/NewManuscript';
import { Manuscripts } from './pages/Manuscripts/Manuscripts';
import { Manuscript } from './pages/Manuscript/Manuscript';

// init Sentry - can't use process cause we use npm run build locally
if (window.location.origin === 'https://milkywayrf.org') {
  Sentry.init({
    dsn: 'https://eafbf79390a1430798d56d61c430d04b@sentry.dstadvisors.ru/9',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const defaultLocation = {
  id: '',
  title: '',
};

const AuthWrapper: React.FC<{ path: PAGES }> = ({ path, children }) => {
  const [dict, setDict] = useDictContext();
  const navigate = useNavigate();
  const [isResolved, setIsResolved] = useState(false);

  useEffectOnce(() => {
    (async () => {
      try {
        const data = await axios.get('/api/grants-application');
        const respData = { ...data.data };

        const userRequest = await axios.get('/api/user');
        const user = { ...userRequest.data };

        const settingsResp = await axios.get(`/api/settings/`);
        const stage = settingsResp.data.currentStage;

        respData.countries = [
          { ...defaultLocation },
          ...respData.countries.map((country) => {
            return {
              ...country,
              title: country.country,
            };
          }),
        ];

        respData.states = [
          { ...defaultLocation },
          ...respData.states.map((state) => ({
            ...state,
            title: state.state,
          })),
        ];

        setDict({ ...respData, user: user, stage });
        setIsResolved(true);
      } catch (e) {
        console.error({ e });
        navigate('/login');
      }
    })();
  });

  const isUserExists = () => dict.user?.id !== undefined;

  useEffect(() => {
    if (isResolved && (!isUserExists() || !canOpenPage(dict.user.userType, path))) {
      navigate('/login');
    }
  }, [dict, isResolved]);

  if (dict.user?.id !== undefined) {
    return <AuthLoginWrapper>{children}</AuthLoginWrapper>;
  } else {
    return null;
  }
};

ReactDOM.render(
  <BrowserRouter>
    <DictContextProvider>
      <Routes>
        {/* Show empty page not to inform random users that it is actual website */}
        <Route path={PAGES.INDEX}></Route>
        <Route path={PAGES.LOGIN} element={<LoginPage />} />

        <Route
          path={PAGES.PROFILE}
          element={
            <AuthWrapper path={PAGES.PROFILE}>
              <ProfilePage />
            </AuthWrapper>
          }
        />

        <Route
          path={PAGES.MANUSCRIPTS_ADD}
          element={
            <AuthWrapper path={PAGES.MANUSCRIPTS_ADD}>
              <NewManuscript />
            </AuthWrapper>
          }
        ></Route>

        <Route
          path={PAGES.MANUSCRIPTS}
          element={
            <AuthWrapper path={PAGES.MANUSCRIPTS}>
              <Manuscripts />
            </AuthWrapper>
          }
        ></Route>

        <Route
          path={PAGES.MANUSCRIPT}
          element={
            <AuthWrapper path={PAGES.MANUSCRIPT}>
              <Manuscript />
            </AuthWrapper>
          }
        ></Route>

        <Route
          path={PAGES.GRANTS_APPLICATION}
          element={
            <AuthWrapper path={PAGES.GRANTS_APPLICATION}>
              <GrantsApplication />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.GRANTS_APPLICATION_REPORTING}
          element={
            <AuthWrapper path={PAGES.GRANTS_APPLICATION_REPORTING}>
              <ProposalReportingPage mode={'pi'} />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.PROPOSAL_REPORTING}
          element={
            <AuthWrapper path={PAGES.PROPOSAL_REPORTING}>
              <ProposalReportingPage />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.CHANGE_STAGE}
          element={
            <AuthWrapper path={PAGES.CHANGE_STAGE}>
              <ChangeStage />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.APPLICATION}
          element={
            <AuthWrapper path={PAGES.APPLICATION}>
              <ApplicationContextProvider>
                <Application />
              </ApplicationContextProvider>
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.APPLICATIONS}
          element={
            <AuthWrapper path={PAGES.APPLICATIONS}>
              <ApplicationsPage />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.RECORDS}
          element={
            <AuthWrapper path={PAGES.RECORDS}>
              <RecordsPage />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.SUMMARY}
          element={
            <AuthWrapper path={PAGES.SUMMARY}>
              <SummaryPage />
            </AuthWrapper>
          }
        ></Route>
        <Route
          path={PAGES.WINNERS}
          element={
            <AuthWrapper path={PAGES.WINNERS}>
              <WinnersPage />
            </AuthWrapper>
          }
        ></Route>
      </Routes>
    </DictContextProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
