import React from 'react';
import { getPatchBody } from '../utils';
import { useInputWithInnerState } from '../../../hooks/useInputWithInnerState';
import { STextarea } from '../../../components/components/STextarea';
import { ErrorMessage } from '../../../components/components/ErrorMessage';
import { patchProposalReporting } from '../../../api';
import { TApplicationId, TCellType, TChangeGlobalStateCb } from '../../../types';

export const FinancialExpenditureTextarea = ({
  value,
  changeGlobalState,
  payload,
  disabled,
  cellType,
  applicationId,
  forPrint,
}: {
  value: string | number;
  changeGlobalState: TChangeGlobalStateCb;
  payload: any;
  disabled: boolean;
  cellType: TCellType;
  applicationId: TApplicationId;
  forPrint: boolean;
}) => {
  const { onBlur, onChangeInner, innerValue, loading, error, retry, onFocus } = useInputWithInnerState(
    value,
    changeGlobalState,
    payload,
    getPatchBody,
    patchProposalReporting,
    'string',
    applicationId,
  );

  if (loading) {
    return <>Loading...</>;
  }

  if (forPrint) {
    return <div>{innerValue}</div>;
  }

  return (
    <>
      <STextarea
        onFocus={onFocus}
        disabled={disabled}
        value={innerValue}
        onChange={onChangeInner}
        onBlur={onBlur}
        cellType={cellType}
      />
      {error && <ErrorMessage retry={retry}>{error}</ErrorMessage>}
    </>
  );
};
