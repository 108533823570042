import React from 'react';
import { SHeader1, SSection, SText } from '../../styles';
import nl2br from 'react-nl2br';

export const BudgetJustification = ({ title, state }) => {
  return (
    <SSection>
      <SHeader1>{title}</SHeader1>
      {
        <>
          {state.budgetJustificationSubAward && (
            <span>"Sub-award will be made" box was selected.</span>
          )}
          <div className="decor-hr" />
        </>
      }
      <div>{nl2br(state.budgetJustification)}</div>
    </SSection>
  );
};
