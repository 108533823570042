import styled from 'styled-components';
import React, { ChangeEvent, FC } from 'react';
import { TFilterStatus } from '../../pages/Manuscripts/types';

interface TProps<T> {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: T;
  selectedValue: string;
  name: string;
  label: string;
}

export const Radio = <T extends string>({ onChange, value, selectedValue, name, label }: TProps<T>) => {
  return (
    <label>
      <input type="radio" name={name} value={value} checked={selectedValue === value} onChange={onChange} />
      <SLabelText>{label}</SLabelText>
    </label>
  );
};

const SLabelText = styled.span`
  padding-left: 5px;
`;
