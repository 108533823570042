import styled from 'styled-components';

export const SFileLink = styled.a`
  display: inline-block;
  margin: 0 5px 5px 0;
  padding: 3px 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: black;

  &:hover {
    background: #eee;
    color: black;
  }
`;
