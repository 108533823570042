import constants from './constants';
import { SCORES_TEXT } from '../pages/Application/constants/SCORES_TEXT';

export const convertSQlDateToObj = (SQLDate) => {
  const a = SQLDate.split(/[- :]/);
  return new Date(a[0], a[1] - 1, a[2], a[3] || 0, a[4] || 0, a[5] || 0);
};

export const getTwoDigitNumber = (number) => {
  number = (number && number.toString()) || '0';
  // if (number.length === 1 && number !== '0') { number = '0' + number; }
  if (number.length === 1) {
    number = `0${number}`;
  }

  return number;
};

export const getDateObj = (SQLDate) => {
  const a = SQLDate.split(/[- :]/);
  return new Date(a[0], a[1] - 1, a[2], a[3] || 0, a[4] || 0, a[5] || 0);
};

/**
 * @param {(string|Object)} date
 * @returns {string}
 */
export const getTextDate = (date) => {
  if (!date) {
    return '';
  }

  if (/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(date)) {
    date = getDateObj(date);
  } else if (typeof date === 'string') {
    date = new Date(date);
  }

  const month = constants.MONTHS[date.getMonth()];
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

/**
 *
 * @param {number|string} paramNumber
 * @param {Object} [params]
 * @param {string} [params.groupSeparator] - Defaults to one space (',')
 * @param {string} [params.fractionSeparator] - Defaults to one comma ('.')
 * @param {number} [params.fractionLength]
 * @param {boolean} [params.simpleNegative]
 * @return {string}
 */
export const formatNumber = (paramNumber, params = {}) => {
  let number = Number(paramNumber);
  const numAsStr =
    params.fractionLength || params.fractionLength === 0
      ? Math.abs(number).toFixed(params.fractionLength)
      : Math.abs(number).toString();
  const groupSeparator = params.groupSeparator || ',';
  const fractionSeparator = params.fractionSeparator || '.';
  const fractionIndex = numAsStr.indexOf('.');
  const fraction = fractionIndex === -1 ? '' : numAsStr.substring(fractionIndex + 1);
  let integer = fractionIndex === -1 ? numAsStr : numAsStr.substring(0, fractionIndex);
  let result = '';

  if (integer.length <= 3) {
    result = integer + (fractionIndex === -1 ? '' : fractionSeparator + fraction);
  } else {
    while (integer.length >= 3) {
      result = integer.substring(integer.length - 3) + (result.length > 0 ? groupSeparator : '') + result;
      integer = integer.substring(0, integer.length - 3);
    }

    result =
      (integer.length ? integer + groupSeparator : '') +
      result +
      (fractionIndex === -1 ? '' : fractionSeparator + fraction);
  }

  if (params.fractionLength) {
    number = number.toFixed(params.fractionLength);
  }

  if (params.simpleNegative) {
    return number < 0 ? `-${result}` : result;
  }
  return number < 0 ? `(${result})` : result;
};

export const getTextScore = (score) => {
  let textScore = '';
  if (score) {
    textScore = SCORES_TEXT[Number(score)]?.score;
  }
  return textScore;
};
