import { createStateContext } from 'react-use';
import { STAGES } from '../constants/stages';
import { IUser, TApplicationId } from '../types';

interface IDict {
  stage: STAGES | null;
  user: IUser | null;
  proposal: any; // TODO
  countries: any[]; // TODO
  states: any[]; // TODO
  id: TApplicationId;
  proposalSubmitted: boolean;
}

export const [useDictContext, DictContextProvider] = createStateContext<IDict>({
  countries: [],
  states: [],
  user: null,
  stage: null,
  proposal: null,
  id: null,
  proposalSubmitted: undefined,
  // TODO: stage is also populated here at some point, so refactor it as stage is not dict, it should be stored somewhere else
});
